<!--
- Social Media
-- Reference Type Id: 39
-- 2024.06.20
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>
			
			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Post"
								value="post"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Post">
								A social media post
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="This is for citing a status, tweets, updates, or other types of posts on a social media platform (e.g. tweets)." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Page"
								value="page"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Page">
								Page or profile on a social media platform
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="Use this for citing a page on a social media platform. It could be a Note, a Profile or About page." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Forum"
								value="forum"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Forum">
								An online forum post
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="Use this type of reference for citing posts or comments to online forums (e.g., Reddit)." />
							</label>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-input-reference-wizard
				v-if="type === 'forum'"
				label="Forum Thread Title"
				help-text="Enter the title for the forum post exactly as it appears. If there isn't a title, leave this field empty."
				:is-common-use="(referenceFormatTypeId == $enums.Format.MLA9 || referenceFormatTypeId == $enums.Format.Turabian9) ? true : false"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-textarea-reference-wizard
				v-show="type != 'page'"
				label="Post Content"
				help-text="Enter the comment's full content here. Type it exactly as it appears in the post."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="content" />
		
			<form-input-reference-wizard
				v-show="type != 'page'"
				label="Description of attached content"
				help-text="If the post includes attached audiovisual material, describe it briefly (e.g., photo with link)."
				@generate-reference-preview="generateReferencePreview"
				v-model="attachment" />

			<form-textarea-reference-wizard
				v-show="type == 'page'"
				label="Page Title"
				help-text="Type the name of the page you are referencing. If it is a tab on a webpage, give the name of the tab."
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard
				v-show="type == 'page'"
				label="Page Description"
				help-text="Enter a description for the type of page you are referencing (e.g., Profile)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="description" />

			<form-input-reference-wizard
				v-show="type == 'post'"
				label="Name of Platform Posts"
				help-text="Enter the name for posts on the specific platform. Common options are: Status update, Tweet, Story, or Highlight."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="format" />

			<form-input-reference-wizard
				label="Platform Name"
				help-text="Type the name of the social media platform or online forum."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="platform" />

			<form-datepicker
				v-show="type == 'page'"
				id="RefWiz_PublicationDate" 
				label="Publication Date"
				help-text="Include the date the page was last updated if available."
				:is-all-common-use="true"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />
			
			<form-datepicker
				v-show="type != 'page'"
				id="RefWiz_PublicationDate" 
				label="Publication Date"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />

			<form-input-reference-wizard
				label="Time of the Post"
				help-text="Add the time of the post if it helps to distinguish the post from others on the same day (e.g., 11:17 a.m.)."
				@generate-reference-preview="generateReferencePreview"
				v-model="time" />

			<form-input-reference-wizard
				label="URL"
				help-text="Enter the full URL for the post or page you're citing. You can use a url shortener for very long URL's (i.e., Bit.ly)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

			<form-datepicker
				v-show="type != 'forum'"
				help-text="If the work may change in the future, enter the date you accessed the work for your research."
				id="RefWiz_RetrievalDate" 
				label="Retrieval Date"
				:is-today-button="true"
				:paper-format="0"
				@date-set="dateSetRetrieval"
				@date-clear="dateClearRetrieval" />
		</section>
	</div>
</template>

<script>
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeSocialMedia',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['groupAuthor', 'userName'];

			this.addContributorRole({
				addDefault: true,
				type: 'userName',
			});

			this.addContributorRole({
				type: 'groupAuthor',
			});

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},
		dateClearRetrieval(){
			this.retrievalDate = '';
			this.generateReferencePreview();
		},
		dateSetRetrieval(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.retrievalDate = newDate;
				this.generateReferencePreview();
			}
		},

	},
	mounted(){
		if(this.type === ''){
			this.type = 'post';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>