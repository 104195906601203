<!--
- Citation Form Label and Input

-->

<template>
	<div class="form-group form-group-split mb-2">
		<div class="flex-shrink-1">
			<label
				class="col-form-label"
				:for="fullId"
				v-html="label" />
		</div>
		<div class="w-100">
			<input
				autocomplete="off"
				ref="focusElement"
				type="text"
				:class="['form-control', {
					'focus-first': isFocusOnAdd,
					'form-control-primary': true,
				}]"
				:id="fullId"
				:value="value"
				@blur="blurInput"
				@input="typeInput($event.target.value)"
				v-debounce:750ms="debouncedGenerateReference" /> 
		</div>
	</div>
</template>

<script>
import config from '@/config';
import mixinId from '@/mixins/id';

export default {
	name: 'CitationInput',
	mixins: [mixinId],
	props:{
		isFocusOnAdd:{	// used to focus an element when a new name is added
			default: false,
			type: Boolean,
		},
		label: {
			type: String,
		},
		value: {
			type: String,
		},
	},
	computed:{
		fullId(){
			let urlFriendlyLabel = this.label.replace(/\W+/g, '-').toLowerCase() || '';
			return this.safeId + '_' + urlFriendlyLabel;
		},
	},
	methods: {
		blurInput(){
			this.$emit('generate-reference-preview');
		},
		
		debouncedGenerateReference(){
			this.$store.dispatch('referenceRules/generateReferencePreview');
		},//e:debouncedGenerateReference

		typeInput(value){
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: 'formReferenceWizard',
			});

			this.$emit('input', value);
		}//e:typeInput
	},
	created(){
		this.emitter.on('focusFirstCitationField', ()=>{
			if(this.isFocusOnAdd){
				let $focusElCollection = this.$parent.$parent.$el.getElementsByClassName('focus-first');
				$focusElCollection[$focusElCollection.length - 1].focus();
			}
		});
	},
	destroyed(){
		this.emitter.off('focusFirstCitationField');
	},
}
</script>
