
<!--
- TimeSplitReferenceWizard
-- splits a value into HH/MM/SS (like Time does in the CitationForm)
-- this is only used for Runtime so it's kinda hard coded for that when passing to the Engine
-- 2024.04.29
-->

<template>
	<div class="form-group">
		<label
			:for="fullId"
			v-html="label" />

        <div class="help-text mb-1" v-if="helpText" v-html="helpText" />

		<div class="form-group form-group-horizontal mb-2">
            <div class="flex-shrink-1">
                <label class="col-form-label">Hours</label>
            </div>
            <div class="w-25">
                <input
                    type="text"
                    ref="time_hours"
                    autocomplete="off"
                    :class="['form-control', {'form-control-primary': isCommonUseShowHours}]"
                    @blur="blurInput"
                    v-model="valueHours" />
            </div>
        </div>
        <div class="form-group form-group-horizontal mb-2">
            <div class="flex-shrink-1">
                <label class="col-form-label">Minutes</label>
            </div>
            <div class="w-25">
                <input
                    type="text"
                    ref="time_minutes"
                    autocomplete="off"
                    :class="['form-control', {'form-control-primary': isCommonUseShowMinutes}]"
                    @blur="blurInput"
                    v-model="valueMinutes" />
            </div>
        </div>
        <div class="form-group form-group-horizontal mb-2">
            <div class="flex-shrink-1">
                <label class="col-form-label">Seconds</label>
            </div>
            <div class="w-25">
                <input
                    type="text"
                    autocomplete="off"
                    ref="time_seconds"
                    :class="['form-control', {'form-control-primary': isCommonUseShowSeconds}]"
                    @blur="blurInput"
                    v-model="valueSeconds" />
            </div>
        </div>
	</div>
</template>

<script>

export default {
	name: 'TimeSplitReferenceWizard',
	props:{
        engineValue:{
            default: '',
            type: String,
        },
        label: {
			type: String,
		},
        helpText: {
			type: String,
		},
        isCommonUse: {
			default: false,
			type: Boolean
		},
	},
	data() {
		return {
            valueHours: '',
			valueMinutes: '',
			valueSeconds: '',
		}
	},
	computed:{
        fullId(){
			let urlFriendlyLabel = this.label.replace(/\W+/g, '-').toLowerCase() || '';
			return this.safeId + '_' + urlFriendlyLabel;
		},
        isCommonUseShowHours(){
			if(this.valueHours === '') {
				return this.isCommonUse;
			} else {
				return false;
			}			
		},
        isCommonUseShowMinutes(){
            if(this.valueMinutes === '') {
				return this.isCommonUse;
			} else {
				return false;
			}			
        },
        isCommonUseShowSeconds(){
            if(this.valueSeconds === '') {
				return this.isCommonUse;
			} else {
				return false;
			}			
        },
	},
	methods: {
        assembleReturnValue(){
            // put the input values back together so they can get passed to the Engine
            let returnHours;
            let returnMinutes;
            let returnSeconds;

            if(this.valueHours === '' && this.valueMinutes === '' && this.valueSeconds === ''){
                // all items are blank, remove runtime
                this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
                    key: this.engineValue,
                    value: ''
                });

            } else {
                // at least one item is populated to run them all
                if(this.valueHours === ''){
                    returnHours = '00';
                } else {
                    returnHours = this.valueHours.padStart(2, '0');
                }

                if(this.valueMinutes === ''){
                    returnMinutes = '00';
                } else {
                    returnMinutes = this.valueMinutes.padStart(2, '0');
                }

                if(this.valueSeconds === ''){
                    returnSeconds = '00';
                } else {
                    returnSeconds = this.valueSeconds.padStart(2, '0');
                }

                let valueOutput = returnHours + ':' + returnMinutes + ':' + returnSeconds;
                
                this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
                    key: this.engineValue,
                    value: valueOutput
                });

            }//e:if:else

            this.$emit('generate-reference-preview');
        },

        blurInput(){
            this.assembleReturnValue();
        },
	},
    created(){
        // emits from mixing ref type after the vuex store gets the runtime logged
        this.emitter.on('setRuntimeUI', ($opts)=>{
            if(this.$store.state.referenceRules.formData[$opts.key] !== ''){
                let splitValueArray = this.$store.state.referenceRules.formData[$opts.key].split(":");
                
                if(splitValueArray.length === 2){
                    // MM/SS only
                    this.valueHours = '00';
                    this.valueMinutes = splitValueArray[0] || '00';
                    this.valueSeconds = splitValueArray[1]|| '00';

                } else {
                    // Full HH/MM/SS
                    this.valueHours = splitValueArray[0] || '00';
                    this.valueMinutes = splitValueArray[1] || '00';
                    this.valueSeconds = splitValueArray[2]|| '00';

                }//e:if:else
            }//e:if

        });//e:emit:on
    },
    destroyed(){
        this.emitter.off('setRuntimeUI');
    },
}
</script>
