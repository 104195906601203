<!--
- Newspaper
-- Reference Type Id: 29
-- 2024.06.21
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Newspaper"
								value="newspaper"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Newspaper">
								Newspaper article
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="This reference is for citing a single newspaper article. This includes interviews." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Reprinted"
								value="reprinted"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Reprinted">
								Reprinted article
								<span
									class="svg bi-info-circle has-tooltip"
										data-bs-toggle="tooltip"
										data-bs-placement="bottom"
										role="tooltip"
										title="Use this reference type for magazine articles that have been published more than once.  It includes both the original date of publication and the date of the version you are citing." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Special"
								value="special"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Special">
								Special issue or section
								<span
									class="svg bi-info-circle has-tooltip" 
									data-bs-toggle="tooltip" 
									data-bs-placement="bottom" 
									role="tooltip"
									title="Use this reference type if you are citing an entire section or issue of a magazine.  If you are only citing one article in a special issue, select Magazine Article instead." />
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type == 'newspaper'" key="divKindNewspaper">
				<label>What kind of newspaper article are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of newspaper article are you referencing?">
					<option value="article">
						A single published article
						</option>
					<option value="foreign">
						Foreign language or translated article
						</option>
					<option value="editorial">
						An editorial article
						</option>
				</select>
			</div>
			<div class="form-group mb-0 py-2" v-show="type == 'reprinted'" key="divKindReprinted">
				<label>What kind of reprinted article are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of reprinted article are you referencing?">
					<option value="article">
						A single reprinted article
						</option>
					<option value="foreign">
						Foreign language or translated article
						</option>
					<option value="editorial">
						A reprinted editorial
						</option>
				</select>
			</div>
			<div class="form-group mb-0 py-2" v-show="type == 'special'" key="divKindSpecial">
				<label>What part or kind of special issue are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What part or kind of special issue are you referencing?">
					<option value="issue">
						Entire special issue
						</option>
					<option value="section">
						Special section
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				v-show="type !== 'special'"
				label="Article Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="articleTitle" />

			<form-textarea-reference-wizard
				v-show="showTranslatorFields"
				label="Translated Article Title"
				help-text="If the original article title is provide in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedArticleTitle" />
			
			<form-textarea-reference-wizard
				v-show="type === 'special'"
				label="Issue/Section Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="issueTitle" />
			
			<form-textarea-reference-wizard
				v-show="type === 'special'"
				label="Translated Issue/Section Title"
				help-text="If the original title is provide in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedIssueTitle" />
			
			<form-input-reference-wizard
				label="Newspaper Name"
				help-text="Type the title of the newspaper exactly as it appears in the publication. If it includes an ambiguous city name, put the state or country in parentheses after the city, e.g., Anytown (TN) Reporter."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="newspaperTitle" />

			<form-input-reference-wizard
				v-show="showTranslatorFields"
				label="Translated Newspaper Name"
				help-text="If the original newspaper name is provide in another language, type the translated title."
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedNewspaperTitle" />

			<form-datepicker
				id="RefWiz_PublicationDate" 
				label="Publication Date"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />

			<form-input-reference-wizard
				v-show="kind !== 'advance' && kind !== 'inPress' && kind != 'issue'"
				label="Page Numbers"
				help-text="Give the page numbers that include the work (e.g., C1-C2). Discontinuous pages are separated with a comma and a space (e.g., A1, A4-A5)."
				@generate-reference-preview="generateReferencePreview"
				v-model="referencePages" />
			
			<control-toggle-select
				v-show="type !== 'special'" 
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />

			<form-datepicker
				v-show="kind !== 'advance' && type !== 'special'" 
				help-text="Only include if your article is in a changing database like UpToDate."
				id="RefWiz_RetrievalDate" 
				label="Retrieval Date"
				:is-today-button="true"
				:paper-format="0"
				@date-set="dateSetRetrieval"
				@date-clear="dateClearRetrieval" />
		</section>

		<section class="mb-5" v-show="type == 'reprinted'">
			<h3>Original Publication Information</h3>
			
			<form-textarea-reference-wizard
				label="Original Article Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalArticleTitle" />

			<form-input-reference-wizard
				label="Original Newspaper Name"
				help-text="Type the full title of the newspaper, exactly as it appears in the publication."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalNewspaperTitle" />

			<form-input-reference-wizard 
				label="Original Publication Year"
				help-text="List the year the work was originally published."
				@generate-reference-preview="generateReferencePreview"
				v-model="originalPublicationDate" />
			
			<control-toggle-select
				:label="toggleSelect.originalDoiUrl.label"
				:is-common-use="toggleSelect.originalDoiUrl.isCommonUse"
				:options="toggleSelect.originalDoiUrl.options"
				group-name="originalDoiUrl" />

		</section>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import config from '@/config';
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeNewspaper',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];
			this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);
			
			this.addContributorRole({
				addDefault: (this.type === 'special') ? true : false,
				type: 'editor',
			});

			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind
				if(this.type != 'special') {
					this.addContributorRole({
						addDefault: true,
						type: 'author',
					});

					this.addContributorRole({
						type: 'groupAuthor',
					});
				}

				// Translator
				if(this.kind == 'foreign') {
					this.addContributorRole({
						addDefault: true,
						type: 'translator',
					});
					this.usedContributorTypes.push('translator');
					this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
				} else if(this.kind === 'article' || this.kind === 'editorial' || this.type === 'special') {
					this.addContributorRole({
						addDefault: false,
						type: 'translator',
					});
				}

			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)
				
				// Update Used Contributor Types
				this.usedContributorTypes = ['author', 'groupAuthor', 'editor', 'translator'];
				
				this.$nextTick(()=>{
					if(this.kind === 'foreign') {
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					} else {
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);
					}
				});

			}//e:if:isRefRulesEmpty 

			// check all contributors for a translator
			if(_has(this.referenceRules.data, 'contributors')){
				_forEach(this.referenceRules.data.contributors, (contributorBlock)=>{
					if(contributorBlock.type === 'translator'){
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					}
				});
			}
			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors

		dateClearRetrieval(){
			this.retrievalDate = '';
			this.generateReferencePreview();
		},
		dateSetRetrieval(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.retrievalDate = newDate;
				this.generateReferencePreview();
			}
		},

		typeChange(){
			// changing radio button 'type' should reselect the first option in the 'kind' dropdown
			if(this.type === 'special'){
				this.kind = 'issue';
			} else {
				this.kind = 'article';
			}
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted(){
		if(this.kind === ''){
			this.kind = 'article'
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
		if(this.type === ''){
			this.type = 'newspaper'
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>