<!--
- Pamphlets & Brochures
-- Reference Type Id: 31
-- 2023.10.18
-->

<template>
	<div>
		<section class="mb-4">
			<h3>Format Options</h3>
			
			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Brochure"
								value="brochure"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Brochure">
								Brochure
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Pamphlet"
								value="pamphlet"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Pamphlet">
								Pamphlet
							</label>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>
	
		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Pamphlet or Brochure Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-textarea-reference-wizard
				v-show="showTranslatorFields"
				label="Translated Title"
				help-text="If the original book title is provide in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedTitle" />

			<control-publishers-v2
				field-type="reference" />

			<form-datepicker
				id="RefWiz_PublicationDate" 
				label="Publication Date"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />
			
			<control-toggle-select
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />

		</section>

		<section class="mb-5">
			<h3>Original Publication Information</h3>

			<form-input-reference-wizard
				label="Original Publication Year"
				help-text="List the year the work was originally published."
				@generate-reference-preview="generateReferencePreview"
				v-model="originalPublicationDate" />
		</section>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypePamphletsBrochures',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];
			this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);

			// Author
			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});

			// Editor
			this.addContributorRole({
				type: 'editor',
			});
			
			// Group Author
			this.addContributorRole({
				type: 'groupAuthor',
			});

			this.addContributorRole({
				type: 'translator',
			});

			if(!this.isRefRulesEmpty) {
				// Load Based on previously entered data in Step 3 (or an Edit)
				
				// Update Used Contributor Types
				this.usedContributorTypes = ['author', 'groupAuthor', 'editor', 'translator'];
			}//e:if:isRefRulesEmpty

			// check all contributors for a translator
			if(_has(this.referenceRules.data, 'contributors')){
				_forEach(this.referenceRules.data.contributors, (contributorBlock)=>{
					if(contributorBlock.type === 'translator'){
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					}
				});
			}
			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors

		typeChange(){
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted(){
		if(this.type === ''){
			this.type = 'brochure';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>