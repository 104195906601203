<!--
- Lectures
-- Reference Type Id: 25
-- 2023.10.18
-->

<template>
	<div>
		<section class="mb-5">
			<control-contributor 
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard 
				label="Title of the Material"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard 
				label="Format"
				help-text="If you entered a description for the title, don't add a format. Only capitalize the first word and proper nouns."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="format" />

			<form-datepicker 
				id="RefWiz_PublicationDate" 
				label="Presentation Date"
				help-text="Enter as much date information as is available."
				:is-all-common-use="true"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />

			<control-publishers-v2
				label="Institution or Platform"
				button-text="another"
				help-text-prop="Enter the name of the institution where the lecture was given or the platform where it can be retrieved. For non-US institutions, enter the country instead of a state (e.g., London, UK)."
				field-type="reference"
				:is-show-city-state="true"
				:is-single-only="true" /> 

			<form-input-reference-wizard 
				label="URL (Web address)"
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeLectures',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			// Author
			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});

			// Group Author
			this.addContributorRole({
				type: 'groupAuthor',
			});

			if(!this.isRefRulesEmpty) {
				// Load Based on previously entered data in Step 3 (or an Edit)

				// Update Used Contributor Types
				this.usedContributorTypes = ['author','groupAuthor'];

			}

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
}
</script>