<!--
- Anthology
-- Reference Type Id: 7
-- 2024.05.16
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Anthology"
								value="anthology"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Radio_Anthology">
								An anthology
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Use this reference type if you are citing an entire anthology.  There is no distinction between print and digital versions." role="tooltip" />
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Entry"
								value="entry"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Radio_Entry">
								An entry in an anthology
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Use this type if you are only citing a single entry or chapter in an anthology." role="tooltip" />
							</label>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group mb-0 py-2" v-show="type === 'anthology'" key="divKindAnthology">
				<label>What kind of book, e-book, or audiobook are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors()" aria-label="What kind of book, e-book, or audiobook are you referencing?">
					<option value="whole">
						All or part of an anthology
					</option>
					<option value="foreign">
						Foreign language or translated anthology
					</option>
					<option value="republished">
						Republished anthology
					</option>
				</select>
			</div>

			<div class="form-group mb-0 py-2" v-show="type !== 'anthology'" key="divKindEntry">
				<label>What kind of chapter are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors()" aria-label="What kind of chapter are you referencing?">
					<option value="entry">
						An entry in an anthology
					</option>
					<option value="foreign">
						An entry in a foreign language or translated anthology
					</option>
					<option value="republished">
						An entry in a republished anthology
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>
			
			<form-textarea-reference-wizard
				v-show="type === 'entry'"
				label="Entry Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="entryTitle" />
			
			<form-textarea-reference-wizard
				v-show="showTranslatorFields && type === 'entry'"
				label="Translated Entry Title"
				help-text="If the original book title is provide in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedEntryTitle" />

			<form-textarea-reference-wizard
				label="Anthology Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="anthologyTitle" />

			<form-textarea-reference-wizard
				v-show="showTranslatorFields"
				label="Translated Anthology Title"
				help-text="If the original book title is provide in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedAnthologyTitle" />

			<form-input-reference-wizard
				v-show="type === 'entry'"
				label="Entry Page Numbers"
				help-text="Give the page numbers that include the entry (ex. 45-78). Discontinuous pages are separated with comma and space. Ex. 45-78, 93."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="pageNumbers" />

			<form-input-reference-wizard
				label="Publication Year"
				help-text="List the year the work was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard
				label="Edition"
				help-text="For numbered editions, type the ordinal number (1st, 2nd, etc.). For named editions, type the name (e.g., 2nd ed., rev.)."
				@generate-reference-preview="generateReferencePreview"
				v-model="edition" />

			<control-publishers-v2
				field-type="reference" />

			<control-toggle-select
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />
			
		</section>

		<section class="mb-5" v-show="kind === 'republished' || kind == 'foreign' || kind == 'entry'">
			<h3>
				Original Publication Information
			</h3>
			
			<form-input-reference-wizard
				v-if="type === 'entry'"
				label="Entry's Original Publication Year"
				help-text="List the year the entry in the anthology was originally published, if provided."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalPublicationDate" />
			
			<form-input-reference-wizard
				v-else
				label="Original Publication Year"
				help-text="List the year the original work was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalPublicationDate" />
		</section>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeBook',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];
			this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);
			
			// Author	
			this.addContributorRole({
				addDefault: (this.type !== 'anthology'),
				type: 'author',
			});
			
			// Editor
			this.addContributorRole({
				addDefault: true,
				type: 'editor',
			});
		
			// Group Author
			this.addContributorRole({
				type: 'groupAuthor',
			});

			// Translator
			this.addContributorRole({
				addDefault: (this.kind == 'foreign'),
				type: 'translator',
			});
			
			// this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
			if(this.kind === 'foreign') {
				this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
			} else {
				this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);
			}
			
			if(!this.isRefRulesEmpty) {
				// Load Based on previously entered data in Step 3 (or an Edit)
				// Update Used Contributor Types
				this.usedContributorTypes = ['author', 'editor', 'groupAuthor', 'translator'];
				
				this.$nextTick(()=>{
					if(this.kind === 'foreign') {
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					} else {
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);
					}
				});
			}//e:if:isRefRulesEmpty
			
			// check all contributors for a translator
			if(_has(this.referenceRules.data, 'contributors')){
				_forEach(this.referenceRules.data.contributors, (contributorBlock)=>{
					if(contributorBlock.type === 'translator'){
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					}
				});
			}

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors

		typeChange(){
			// changing radio button 'type' should reselect the first option in the 'kind' dropdown
			if(this.type === 'anthology'){
				this.kind = 'whole';
			} else {
				this.kind = 'entry';
			}
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted() {
		if(this.kind === ''){
			this.kind = 'whole';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
		if(this.type === ''){
			this.type = 'anthology';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>
