<!--
- Apparatus
-- Reference Type Id: 8
-- 2024.05.28
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Apparatus"
								value="apparatus"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Apparatus">
								An apparatus or piece of equipment (stand alone without software)
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="bottom" title="Use this if the apparatus or equipment doesn&rsquo;t include its own software." role="tooltip" />
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Combo"
								value="combo"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Combo">
								Apparatus or equipment with software included
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="This reference type is for equipment that includes its own proprietary software along with the physical machine." role="tooltip" />
							</label>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes"
				contributor-label="Apparatus/Equipment Company" />
			</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-input-reference-wizard
				label="Name of the Apparatus or Equipment"
				:help-text="calcTitleHelpText"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard
				label="Model Number"
				help-text="Enter the model number if it is not included in the name above."
				@generate-reference-preview="generateReferencePreview"
				v-model="model" />

			<form-input-reference-wizard
				label="Year of Release"
				help-text="Type the year the apparatus or equipment was released."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />
			
			<form-input-reference-wizard
				label="URL (Web address)"
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

		</section>

	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeApparatus',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['groupAuthor'];
			
			this.addContributorRole({
				addDefault: true,
				type: 'groupAuthor',	// display as Company
			});

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});			
		},//e:calcContributors
		
	},
	mounted(){
		if(this.type === ''){
			this.type = 'apparatus';
		}
	},
}
</script>

