<!--
- Interview
-- Reference Type Id: 23
-- 2024.08.12
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>
			
			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Personal"
								value="personal"
								v-model="type"
								@change="typeChange" /> 
								
							<label class="form-check-label" for="RefWiz_Type_Personal">
								An unpublished interview you did yourself
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Archive"
								value="archive"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Archive">
								An unpublished interview in an archive, database, or library
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type == 'personal'" key="divKindPersonal">
				<label>What kind of personal interview are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of personal interview are you referencing?">
					<option value="named">
						Interview with a named person
					</option>
					<option value="anonymous">
						Interview with an anonymous person (name withheld for confidentiality)
					</option>
				</select>
			</div>
			<div class="form-group mb-0 py-2" v-show="type == 'archive'" key="divKindArchive">
				<label>What kind of archived interview are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of archived interview are you referencing?">
					<option value="named">
						Interview with a named person
					</option>
					<option value="anonymous">
						Interview with an anonymous person (name withheld for confidentiality)
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:is-add-another="calcIsAddAnother"
				:used-contributor-types="usedContributorTypes" />
		
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-datepicker
				@date-clear="dateClearPublication"
				@date-set="dateSetPublication"
				:paper-format="0"
				id="RefWiz_PublicationDate" 
				label="Publication Date" />

			<control-publishers-v2
				v-if="type == 'personal'"
				label="Location of Interview"
				help-text-prop="Enter the city & state where the interview happened if relevant."
				field-type="reference"
				:is-show-city-state="true"
				:is-single-only="true" /> 

			<control-publishers-v2
				v-if="type == 'archive'"
				label="Archive, Database, or Library"
				help-text-prop="Type the full name of the archive, database, or library where the interview can be found (e.g., Library of Congress, Interview Archives)."
				field-type="reference"
				:is-show-city-state="true"
				:is-single-only="true" /> 

			<form-input-reference-wizard
				v-if="type == 'archive'"
				label="Database or Archive Identifier"
				help-text="If the interview has a number identifying it, enter it exactly as it appears (e.g., Interview 74B)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="number" />
		</section>
			
	</div>
</template>

<script>
import _find from 'lodash/find';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeInterview',
	mixins: [mixinReferenceTypeGenesis],
	computed:{
		calcIsAddAnother(){
			if(
				this.$store.state.referenceRules.formData.kind === 'anonymous'
			){
				return false;
			}
			return true;
		},
	},
	methods:{
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];
			this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);

			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind (personal and named are the defaults)
				// i think this only happens when the ref type is mounted() if so then you really only need to set this as a default
				this.addContributorRole({
					addDefault: true,
					type: 'author',
				});

			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)

				// // Update Used Contributor Types
				// this.usedContributorTypes = ['author','anonymous'];

				this.$nextTick(()=>{
					if(this.$store.state.referenceRules.formData.type === 'personal'){
						this.removeEmptyContributorRole({
							type: 'editor',
						});
						if(this.$store.state.referenceRules.formData.kind === 'named'){
							this.addContributorRole({
								addDefault: true,
								type: 'author',
							});
							this.removeEmptyContributorRole({
								type: 'anonymous',
							});
						} else if(this.$store.state.referenceRules.formData.kind === 'anonymous'){
							this.removeEmptyContributorRole({
								type: 'author',
							});
							this.addContributorRole({
								addDefault: true,
								type: 'anonymous',
							});
						}//e:if
					} else if(this.$store.state.referenceRules.formData.type === 'archive'){
						if(this.$store.state.referenceRules.formData.kind === 'named'){
							this.addContributorRole({
								addDefault: true,
								type: 'author',
							});
							this.addContributorRole({
								addDefault: true,
								type: 'editor',
							});
							this.removeEmptyContributorRole({
								type: 'anonymous',
							});

						} else if(this.$store.state.referenceRules.formData.kind === 'anonymous'){
							this.addContributorRole({
								addDefault: true,
								type: 'anonymous',
							});
							this.removeEmptyContributorRole({
								type: 'author',
							});
							this.removeEmptyContributorRole({
								type: 'editor',
							});

						}//e:if
					}//e:if
				});
			}

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		
		},

		typeChange(){
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted(){
		if(this.kind === ''){
			this.kind = 'named';
		}
		if(this.type === ''){
			this.type = 'personal';
		}
		this.$nextTick(()=>{
			this.calcContributors();
		});
	},
}
</script>