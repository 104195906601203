var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.referenceTypeId === 1
        ? _c("journal", {
            key: "refTypeJournal",
            attrs: {
              "is-third-party-look-up": _vm.isThirdPartyLookUp,
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 2
        ? _c("book", {
            key: "refTypeBook",
            attrs: {
              "is-third-party-look-up": _vm.isThirdPartyLookUp,
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 3
        ? _c("website", {
            key: "refTypeWebsite",
            attrs: {
              "is-third-party-look-up": _vm.isThirdPartyLookUp,
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 4
        ? _c("gray-literature", {
            key: "refTypeGrayLiterature",
            attrs: {
              "is-third-party-look-up": _vm.isThirdPartyLookUp,
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 5
        ? _c("advertisement", {
            key: "refTypeAdvertisement",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 6
        ? _c("ancient-classic-work", {
            key: "refTypeAncientClassicWork",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 7
        ? _c("anthology", {
            key: "refTypeAnthology",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 8
        ? _c("apparatus", {
            key: "refTypeApparatus",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 9
        ? _c("artwork", {
            key: "refTypeArtwork",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 10
        ? _c("bills-resolutions", {
            key: "refTypeBillsResolutions",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 11
        ? _c("blog", {
            key: "refTypeBlog",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 12
        ? _c("charts-maps", {
            key: "refTypeChartsMaps",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 13
        ? _c("conference-materials", {
            key: "refTypeConferenceMaterials",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 14
        ? _c("constitutions", {
            key: "refTypeConstitutions",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 15
        ? _c("datasets", {
            key: "refTypeDatasets",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 16
        ? _c("dissertations", {
            key: "refTypeDissertations",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 17
        ? _c("executive-orders", {
            key: "refTypeExecutiveOrders",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 18
        ? _c("federal-testimony", {
            key: "refTypeFederalTestimony",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 19
        ? _c("films", {
            key: "refTypeFilms",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 20
        ? _c("government-reports", {
            key: "refTypeGovernmentReports",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 21
        ? _c("grant", {
            key: "refTypeGrant",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 22
        ? _c("informal-works", {
            key: "refTypeInformalWorks",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 23
        ? _c("interview", {
            key: "refTypeInterview",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 24
        ? _c("international-conventions", {
            key: "refTypeInternationalConventions",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 25
        ? _c("lectures", {
            key: "refTypeLectures",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 26
        ? _c("magazine", {
            key: "refTypeMagazine",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 27
        ? _c("manuscript", {
            key: "refTypeManuscript",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 28
        ? _c("music", {
            key: "refTypeMusic",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 29
        ? _c("newspaper", {
            key: "refTypeNewspaper",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 30
        ? _c("online-comment", {
            key: "refTypeOnlineComment",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 31
        ? _c("pamphlets-brochures", {
            key: "refTypePamphletsBrochures",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 32
        ? _c("patent", {
            key: "refTypePatent",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 33
        ? _c("personal-communication", {
            key: "refTypePersonalCommunication",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 34
        ? _c("podcasts-radio", {
            key: "refTypePodcastsRadio",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 35
        ? _c("press-release", {
            key: "refTypePressRelease",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 36
        ? _c("reference-work", {
            key: "refTypeReferenceWork",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 37
        ? _c("religious-work", {
            key: "refTypeReligiousWork",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 38
        ? _c("reviews", {
            key: "refTypeReviews",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 39
        ? _c("social-media", {
            key: "refTypeSocialMedia",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 40
        ? _c("software", {
            key: "refTypeSoftware",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 41
        ? _c("speech", {
            key: "refTypeSpeech",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 42
        ? _c("statutes", {
            key: "refTypeStatutes",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 43
        ? _c("television", {
            key: "refTypeTelevision",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 44
        ? _c("tests-scales", {
            key: "refTypeTestsScales",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 45
        ? _c("us-federal-court", {
            key: "refTypeUsFederalCourt",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 46
        ? _c("us-state-court", {
            key: "refTypeUsStateCourt",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm.referenceTypeId === 47
        ? _c("videos-ref-type", {
            key: "refTypeVideos",
            attrs: {
              "reference-rules-string": _vm.referenceRulesString,
              "reference-rules-prop": _vm.referenceRulesProp,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }