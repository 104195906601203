var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-3" },
    [
      !_vm.isSingleOnly
        ? _c("div", { staticClass: "form-group mb-1" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c("div", { staticClass: "w-100 mb-2" }, [
                _vm._v("\n\t\t\t\t \n\t\t\t"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-row flex-shrink-1" }, [
                _c("div", { staticClass: "d-flex flex-row-reverse" }, [
                  _vm.publisherIndex !=
                  _vm.$store.state.referenceRules.formData.publishersV2[
                    _vm.fieldType
                  ].length -
                    1
                    ? _c(
                        "a",
                        {
                          attrs: { href: "#", title: "Move Publisher Down" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.sortPublisherBlock(false)
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "svg svg-sort bi-arrow-down",
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.publisherIndex !== 0
                    ? _c(
                        "a",
                        {
                          attrs: { href: "#", title: "Move Publisher Up" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.sortPublisherBlock(true)
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "svg svg-sort bi-arrow-up",
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#", title: "Remove Publisher" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removePublisherBlock.apply(null, arguments)
                        },
                      },
                    },
                    [_c("span", { staticClass: "svg svg-delete bi-x-lg" })]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowName
        ? _c("publishers-v2-input", {
            attrs: {
              label: "Name",
              "is-show-city-state": _vm.isShowCityState,
              "is-focus-on-add": true,
            },
            model: {
              value: _vm.publisher_name,
              callback: function ($$v) {
                _vm.publisher_name = $$v
              },
              expression: "publisher_name",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowCityState
        ? _c(
            "div",
            [
              _c("publishers-v2-input", {
                attrs: { label: "City" },
                model: {
                  value: _vm.publisher_city,
                  callback: function ($$v) {
                    _vm.publisher_city = $$v
                  },
                  expression: "publisher_city",
                },
              }),
              _vm._v(" "),
              _vm.isShowCityState
                ? _c("publishers-v2-input", {
                    attrs: { label: "State" },
                    model: {
                      value: _vm.publisher_state,
                      callback: function ($$v) {
                        _vm.publisher_state = $$v
                      },
                      expression: "publisher_state",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }