var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.referenceFormatTypeId === _vm.$enums.Format.MLA9
      ? _c("div", { staticClass: "alert alert-warning" }, [
          _c("p", { staticClass: "ps-3" }, [
            _vm._v(
              "\n\t\t\tThis reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).\n\t\t"
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Federal",
                  value: "federal",
                },
                domProps: { checked: _vm._q(_vm.type, "federal") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "federal"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_State",
                  value: "state",
                },
                domProps: { checked: _vm._q(_vm.type, "state") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "state"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "federal",
              expression: "type === 'federal'",
            },
          ],
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v("What kind of federal statute are you referencing?"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What kind of federal statute are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "usc" } }, [
                _vm._v(
                  "\n\t\t\t\t\tStatute codified in the United States Code\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "pl" } }, [
                _vm._v("\n\t\t\t\t\tStatute cited from Public Law\n\t\t\t\t"),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Name of the Statute or Act",
            "help-text":
              "Type the full name of the statute exactly as it appears in the work.",
            "is-autosize": true,
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Shortened Title",
            "help-text":
              "If the work's title is long, include a shortened title for the in-text citation.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.shortTitle,
            callback: function ($$v) {
              _vm.shortTitle = $$v
            },
            expression: "shortTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind == "usc",
              expression: "kind == 'usc'",
            },
          ],
          attrs: {
            label: "<em>United States Code</em> Title Number",
            "help-text":
              'Only include the number of title for the <em>United States Code</em> text. Don\'t include the "U.S.C." abbreviation.',
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.volume,
            callback: function ($$v) {
              _vm.volume = $$v
            },
            expression: "volume",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind == "usc",
              expression: "kind == 'usc'",
            },
          ],
          attrs: {
            label: "<em>United States Code</em> Section Numbers",
            "help-text":
              "Enter all the sections included for the statute. Don't include the section symbol (§).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.section,
            callback: function ($$v) {
              _vm.section = $$v
            },
            expression: "section",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind == "pl",
              expression: "kind == 'pl'",
            },
          ],
          attrs: {
            label: "Public Law Number",
            "help-text":
              "Only include the number for the Public Law reference.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.number,
            callback: function ($$v) {
              _vm.number = $$v
            },
            expression: "number",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind == "pl",
              expression: "kind == 'pl'",
            },
          ],
          attrs: {
            label: "<em>United States Statutes at Large</em> Volume Numbers",
            "help-text":
              'Only include the number of the volume. Don\'t include the "Stat." abbreviation.',
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.volume,
            callback: function ($$v) {
              _vm.volume = $$v
            },
            expression: "volume",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind == "pl",
              expression: "kind == 'pl'",
            },
          ],
          attrs: {
            label: "Statute Starting Page Number",
            "help-text":
              "Enter the starting page number for the statute in the <em>United States Statutes at Large</em>.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.pageNumber,
            callback: function ($$v) {
              _vm.pageNumber = $$v
            },
            expression: "pageNumber",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "state",
              expression: "type === 'state'",
            },
          ],
          attrs: {
            label: "Statute Source Title",
            "help-text": _vm.calcHelpTextStatuteSourceTitle,
            "is-autosize": true,
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.sourceTitle,
            callback: function ($$v) {
              _vm.sourceTitle = $$v
            },
            expression: "sourceTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "state",
              expression: "type === 'state'",
            },
          ],
          attrs: {
            label: "Statute Source Location",
            "help-text":
              "Type the article, section, or page information needed to identify the statute in the source.",
            "is-common-use": true,
            "is-insert-section": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.sourceLocation,
            callback: function ($$v) {
              _vm.sourceLocation = $$v
            },
            expression: "sourceLocation",
          },
        }),
        _vm._v(" "),
        _vm.type === "state"
          ? _c("form-input-reference-wizard", {
              attrs: {
                label: "State Name",
                "help-text": "Type the full name of the state.",
                "is-common-use": true,
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
              model: {
                value: _vm.state,
                callback: function ($$v) {
                  _vm.state = $$v
                },
                expression: "state",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Original Year",
            "help-text":
              "Enter the year the statute was published in the source you're using.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Revised Year",
            "help-text":
              "If the statute was revised, enter the year the revised statute was published.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.revisedDate,
            callback: function ($$v) {
              _vm.revisedDate = $$v
            },
            expression: "revisedDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL (Web address)",
            "help-text": "Include the URL for the work if available.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Federal" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tA federal statute\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-html": "true",
            "data-bs-placement": "bottom",
            "data-bs-toggle": "tooltip",
            role: "tooltip",
            title:
              "This is for citing codified statutes reported in the &ldquo;United States Code&rdquo; or recorded with a Public Law number.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_State" } },
      [
        _vm._v("\n\t\t\t\t\t\t\tA state statute\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title: "Use this to cite codified statutes at the state level.",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }