<!--
- Bills & Resolutions
-- Reference Type Id: 10
-- 2024.06.20
-->

<template>
	<div>
		<div v-if="referenceFormatTypeId === $enums.Format.MLA9" class="alert alert-warning">
			<p class="ps-3">
				This reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).
			</p>
		</div>
	
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_EnactedFed"
								value="enactedFed"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Radio_EnactedFed">
								An enacted federal bill or resolution
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_UnenactedFed"
								value="unenactedFed"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Radio_UnenactedFed">
								An unenacted federal bill or resolution
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type === 'enactedFed'">
				<label>What kind of bill or resolution are you citing?</label>
				<select class="form-select" v-model="kind" @change="generateReferencePreview" aria-label="What kind of bill or resolution are you citing?">
					<option value="house">
						House of Representatives bill or resolution
					</option>
					<option value="senate">
						Senate bill or resolution
					</option>
					<option value="senateJoint">
						Joint Senate bill or resolution
					</option>
					<option value="houseJoint">
						Joint House bill or resolution
					</option>
				</select>
			</div>
			<div class="form-group mb-0 py-2" v-show="type === 'unenactedFed'">
				<label>What kind of bill or resolution are you citing?</label>
				<select class="form-select" v-model="kind" @change="generateReferencePreview" aria-label="What kind of bill or resolution are you citing?">
					<option value="house">
						House of Representatives bill or resolution
					</option>
					<option value="senate">
						Senate bill or resolution
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Bill or Resolution Title"
				help-text="Type the full name of the bill or resolution exactly as it appears in the work."
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard
				label="Shortened Title"
				help-text="If the work's title is long, include a shortened title for the in-text citation."
				@generate-reference-preview="generateReferencePreview"
				v-model="shortTitle" />

			<form-input-reference-wizard
				label="Bill or Resolution Number"
				help-text="Only include the number of the bill or resolution. Don&#39;t include &quot;S.&quot; or &quot;H.R.&quot;"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="billNumber" />

			<form-input-reference-wizard
				label="Congressional Record Volume"
				help-text="Type the number of the Congressional Record Volume where the enacted bill/resolution can be found."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="volume" />

			<form-input-reference-wizard
				label="Record Starting Page Number"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="pageNumber" />

			<form-input-reference-wizard
				label="Congressional Session"
				help-text="Type the ordinal number for the Congressional session when this bill/resolution was considered."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="session" />

			<form-input-reference-wizard
				label="Congressional Number Session"
				help-text="If there are multiple sessions for the meeting of Congress, type the ordinal numeral for the part of the session of Congress when the testimony or hearing occurred (e.g., 3rd)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="sessionPart" />

			<form-datepicker
				id="RefWiz_PublicationDate" 
				label="Year"
				:is-common-use="(referenceFormatTypeId == $enums.Format.MLA9) ? true : false"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />

			<form-input-reference-wizard
				label="URL (Web address)"
				help-text="Include the URL for the work if available."
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

		</section>

	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeBillsResolutions',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// this function left intentionally blank
		},//e:calcContributors
		
		typeChange(){
			// changing radio button 'type' should reselect the first option in the 'kind' dropdown
			this.kind = 'house';
			
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted(){
		if(this.kind === ''){
			this.kind = 'house';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
		if(this.type === ''){
			this.type = 'enactedFed';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>

