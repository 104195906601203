<!--
- Executive Orders
-- Reference Type Id: 17
-- 2024.06.20
-->

<template>
	<div>
		<div v-if="referenceFormatTypeId === $enums.Format.MLA9" class="alert alert-warning">
			<p class="ps-3">
				This reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).
			</p>
		</div>

		<section class="mb-5">
			<control-contributor 
				:contributors-prop="contributors"
				:is-add-another="false"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-input-reference-wizard 
				label="Order Title"
				help-text="Type the name of the executive order exactly as it appears."
				:is-common-use="(referenceFormatTypeId == $enums.Format.MLA9) ? true : false"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard 
				label="Executive Order Number"
				help-text="Provide the number for the Executive Order."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="orderNumber" />

			<form-input-reference-wizard 
				label="Starting Page Number"
				help-text="Type the starting page number for the Executive Order in the <em>Code of Federal Regulations</em> text."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="pageNumber" />

			<form-datepicker
				id="RefWiz_PublicationDate" 
				label="Date signed"
				:is-common-use="(referenceFormatTypeId == $enums.Format.MLA9) ? true : false"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />

			<form-input-reference-wizard 
				label="URL (Web address)"
				help-text="Include the URL for the work if available."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />
		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeExecutiveOrders',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['author'];

			this.addContributorRole({
				addDefault: true,
				type: 'author',	// Show president
			});

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
}
</script>