var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group form-group-split mb-2" }, [
    _vm.isShowCityState
      ? _c("div", { staticClass: "flex-shrink-1" }, [
          _c("label", {
            staticClass: "col-form-label",
            attrs: { for: _vm.fullId },
            domProps: { innerHTML: _vm._s(_vm.label) },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "w-100" }, [
      _c("input", {
        directives: [
          {
            name: "debounce",
            rawName: "v-debounce:750ms",
            value: _vm.debouncedGenerateReference,
            expression: "debouncedGenerateReference",
            arg: "750ms",
          },
        ],
        ref: "focusElement",
        class: [
          "form-control",
          {
            "focus-first": _vm.isFocusOnAdd,
            "form-control-primary": _vm.showIsCommonUse,
          },
        ],
        attrs: { autocomplete: "off", type: "text", id: _vm.fullId },
        domProps: { value: _vm.value },
        on: {
          blur: _vm.blurInput,
          input: function ($event) {
            return _vm.typeInput($event.target.value)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }