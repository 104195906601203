<!--
- Dissertations
-- Reference Type Id: 16
-- 2023.10.18
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Published"
								value="published"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Published">
								A published dissertation or thesis
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Raw"
								value="unpublished"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Raw">
								An unpublished dissertation or thesis
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2">
				<label>What kind of dissertation or thesis are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of dissertation or thesis are you referencing?">
					<option value="doctorate">
						A doctoral dissertation
					</option>
					<option value="masters">
						A master's thesis
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor 
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Title of the Work"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard 
				label="Publication Year"
				help-text="Type the year the work was published or completed."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard 
				v-show="type === 'published'"
				label="Database or Archive Name"
				help-text="Enter the name of the database or archive where you retrieved the work.  Type it exactly as it appears."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="database" />

			<form-input-reference-wizard 
				v-show="type === 'published'"
				label="Record Identifier"
				help-text="If the work has a database number or identifier, enter it here."
				@generate-reference-preview="generateReferencePreview"
				v-model="record" />

			<form-input-reference-wizard 
				label="Institution Name"
				help-text="Type the name of the institution that awarded the degree. Enter it exactly as it appears in the work."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="institution" />

			<form-input-reference-wizard 
				label="URL"
				help-text="Enter the url for the work if you retrieved it online."
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

		</section>

	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeDissertations',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['author'];

			// Author
			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});
	
			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
		
	},
	mounted() {
		this.kind = 'doctorate';
		if(this.type === ''){
			this.type = 'published';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>