var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Conference",
                  value: "conference",
                },
                domProps: { checked: _vm._q(_vm.type, "conference") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "conference"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Radio_Conference" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tConference Session or Presentation\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Symposium",
                  value: "symposium",
                },
                domProps: { checked: _vm._q(_vm.type, "symposium") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "symposium"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Radio_Symposium" },
                },
                [_vm._v("\n\t\t\t\t\t\t\tSymposium Contribution\n\t\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Contribution Title",
            "help-text":
              "Type the title of the contribribution. Only capitalize the first word of the title and subtitle, and any proper nouns.",
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "symposium",
              expression: "type === 'symposium'",
            },
          ],
          attrs: {
            label: "Symposium Title",
            "help-text":
              "Enter the name of the symposium in sentence-case, capitalizing only the first word of the title & subtitle and any proper nouns.",
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.symposiumTitle,
            callback: function ($$v) {
              _vm.symposiumTitle = $$v
            },
            expression: "symposiumTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "conference",
              expression: "type === 'conference'",
            },
          ],
          attrs: {
            label: "Type of Contribution",
            "help-text":
              "Enter the type of contribution that was made (e.g., Conference session, Paper presentation, etc.). Only capitalize the first word.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.description,
            callback: function ($$v) {
              _vm.description = $$v
            },
            expression: "description",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Conference Name",
            "help-text": "Type the name of the conference or symposium.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.conference,
            callback: function ($$v) {
              _vm.conference = $$v
            },
            expression: "conference",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Location - City",
            "help-text":
              "Type the name of the city where the conference or symposium took place.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.city,
            callback: function ($$v) {
              _vm.city = $$v
            },
            expression: "city",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Location - State",
            "help-text":
              "Enter the two letter abbreviation for the state where the conference or symposium happened.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.state,
            callback: function ($$v) {
              _vm.state = $$v
            },
            expression: "state",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Location - Country",
            "help-text":
              "Enter the name of the country where the conference or symposium took place. List a country even if it's your own.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.country,
            callback: function ($$v) {
              _vm.country = $$v
            },
            expression: "country",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Conference Start Date",
            "help-text":
              "Enter the date of the conference or symposium. If the event happened over multiple days, add an ending date.",
            "is-add-end-date": true,
            "is-all-common-use": true,
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "end-date-add": function ($event) {
              return _vm.endDateSet(true)
            },
            "date-set": _vm.dateSetStart,
            "date-clear": _vm.dateClearStart,
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isEndDate,
              expression: "isEndDate",
            },
          ],
          attrs: {
            id: "RefWiz_EndDate",
            label: "Conference End Date",
            "is-error": !_vm.isDateValid,
            "is-remove-end-date": true,
            "is-all-common-use": true,
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "end-date-remove": function ($event) {
              return _vm.endDateSet(false)
            },
            "date-set": _vm.dateSetEnd,
            "date-clear": _vm.dateClearEnd,
          },
        }),
        _vm._v(" "),
        _c("control-toggle-select", {
          attrs: {
            label: _vm.toggleSelect.doiUrl.label,
            "is-common-use": _vm.toggleSelect.doiUrl.isCommonUse,
            options: _vm.toggleSelect.doiUrl.options,
            "group-name": "doiUrl",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }