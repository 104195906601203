var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "radio", id: "RefWiz_Type_Post", value: "post" },
                domProps: { checked: _vm._q(_vm.type, "post") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "post"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "radio", id: "RefWiz_Type_Page", value: "page" },
                domProps: { checked: _vm._q(_vm.type, "page") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "page"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Forum",
                  value: "forum",
                },
                domProps: { checked: _vm._q(_vm.type, "forum") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "forum"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(2),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _vm.type === "forum"
          ? _c("form-input-reference-wizard", {
              attrs: {
                label: "Forum Thread Title",
                "help-text":
                  "Enter the title for the forum post exactly as it appears. If there isn't a title, leave this field empty.",
                "is-common-use":
                  _vm.referenceFormatTypeId == _vm.$enums.Format.MLA9 ||
                  _vm.referenceFormatTypeId == _vm.$enums.Format.Turabian9
                    ? true
                    : false,
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type != "page",
              expression: "type != 'page'",
            },
          ],
          attrs: {
            label: "Post Content",
            "help-text":
              "Enter the comment's full content here. Type it exactly as it appears in the post.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.content,
            callback: function ($$v) {
              _vm.content = $$v
            },
            expression: "content",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type != "page",
              expression: "type != 'page'",
            },
          ],
          attrs: {
            label: "Description of attached content",
            "help-text":
              "If the post includes attached audiovisual material, describe it briefly (e.g., photo with link).",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.attachment,
            callback: function ($$v) {
              _vm.attachment = $$v
            },
            expression: "attachment",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "page",
              expression: "type == 'page'",
            },
          ],
          attrs: {
            label: "Page Title",
            "help-text":
              "Type the name of the page you are referencing. If it is a tab on a webpage, give the name of the tab.",
            "is-autosize": true,
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "page",
              expression: "type == 'page'",
            },
          ],
          attrs: {
            label: "Page Description",
            "help-text":
              "Enter a description for the type of page you are referencing (e.g., Profile).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.description,
            callback: function ($$v) {
              _vm.description = $$v
            },
            expression: "description",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "post",
              expression: "type == 'post'",
            },
          ],
          attrs: {
            label: "Name of Platform Posts",
            "help-text":
              "Enter the name for posts on the specific platform. Common options are: Status update, Tweet, Story, or Highlight.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.format,
            callback: function ($$v) {
              _vm.format = $$v
            },
            expression: "format",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Platform Name",
            "help-text":
              "Type the name of the social media platform or online forum.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.platform,
            callback: function ($$v) {
              _vm.platform = $$v
            },
            expression: "platform",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "page",
              expression: "type == 'page'",
            },
          ],
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Publication Date",
            "help-text":
              "Include the date the page was last updated if available.",
            "is-all-common-use": true,
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type != "page",
              expression: "type != 'page'",
            },
          ],
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Publication Date",
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Time of the Post",
            "help-text":
              "Add the time of the post if it helps to distinguish the post from others on the same day (e.g., 11:17 a.m.).",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.time,
            callback: function ($$v) {
              _vm.time = $$v
            },
            expression: "time",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL",
            "help-text":
              "Enter the full URL for the post or page you're citing. You can use a url shortener for very long URL's (i.e., Bit.ly).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type != "forum",
              expression: "type != 'forum'",
            },
          ],
          attrs: {
            "help-text":
              "If the work may change in the future, enter the date you accessed the work for your research.",
            id: "RefWiz_RetrievalDate",
            label: "Retrieval Date",
            "is-today-button": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetRetrieval,
            "date-clear": _vm.dateClearRetrieval,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_Post" } },
      [
        _vm._v("\n\t\t\t\t\t\t\tA social media post\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "This is for citing a status, tweets, updates, or other types of posts on a social media platform (e.g. tweets).",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_Page" } },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tPage or profile on a social media platform\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "Use this for citing a page on a social media platform. It could be a Note, a Profile or About page.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_Forum" } },
      [
        _vm._v("\n\t\t\t\t\t\t\tAn online forum post\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "Use this type of reference for citing posts or comments to online forums (e.g., Reddit).",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }