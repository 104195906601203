var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Periodical",
                  value: "periodical",
                },
                domProps: { checked: _vm._q(_vm.type, "periodical") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "periodical"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_Periodical" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tReview in a journal, newspaper, or magazine\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Website",
                  value: "website",
                },
                domProps: { checked: _vm._q(_vm.type, "website") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "website"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_Website" },
                },
                [_vm._v("\n\t\t\t\t\t\t\tReview on a website\n\t\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "radio", id: "RefWiz_Type_Blog", value: "blog" },
                domProps: { checked: _vm._q(_vm.type, "blog") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "blog"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_Blog" },
                },
                [_vm._v("\n\t\t\t\t\t\t\tReview on a blog\n\t\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("What kind of article are you referencing?")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.kind,
                expression: "kind",
              },
            ],
            staticClass: "form-select",
            attrs: {
              "aria-label": "What kind of article are you referencing?",
            },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.kind = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.calcContributors,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "book" } }, [
              _vm._v("\n\t\t\t\t\tA book\n\t\t\t\t\t"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "film" } }, [
              _vm._v("\n\t\t\t\t\tA film or movie\n\t\t\t\t"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "album" } }, [
              _vm._v("\n\t\t\t\t\tA music album\n\t\t\t\t"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "song" } }, [
              _vm._v("\n\t\t\t\t\tA song\n\t\t\t\t"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "tvSeries" } }, [
              _vm._v("\n\t\t\t\t\tA television series\n\t\t\t\t"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "tvEpisode" } }, [
              _vm._v("\n\t\t\t\t\tA television episode\n\t\t\t\t"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "other" } }, [
              _vm._v("\n\t\t\t\t\tOther\n\t\t\t\t"),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Review Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind === "other",
              expression: "kind === 'other'",
            },
          ],
          attrs: { label: "Type of Reviewed Work", "is-autosize": true },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.workType,
            callback: function ($$v) {
              _vm.workType = $$v
            },
            expression: "workType",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Publication Date",
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: _vm.calcReviewedTitleLabel,
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.reviewedTitle,
            callback: function ($$v) {
              _vm.reviewedTitle = $$v
            },
            expression: "reviewedTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "periodical",
              expression: "type == 'periodical'",
            },
          ],
          attrs: { label: "Periodical Name", "is-common-use": true },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.periodical,
            callback: function ($$v) {
              _vm.periodical = $$v
            },
            expression: "periodical",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "periodical",
              expression: "type == 'periodical'",
            },
          ],
          attrs: { label: "Periodical Volume", "is-common-use": true },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.volume,
            callback: function ($$v) {
              _vm.volume = $$v
            },
            expression: "volume",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "periodical",
              expression: "type == 'periodical'",
            },
          ],
          attrs: { label: "Periodical Issue", "is-common-use": true },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.issue,
            callback: function ($$v) {
              _vm.issue = $$v
            },
            expression: "issue",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "periodical",
              expression: "type == 'periodical'",
            },
          ],
          attrs: {
            label: "Review Page Numbers",
            "help-text":
              "Give the page numbers that include the entry (ex. 45-78). Discontinuous pages are separated with comma and space. Ex. 45-78, 93.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.pageNumbers,
            callback: function ($$v) {
              _vm.pageNumbers = $$v
            },
            expression: "pageNumbers",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "website",
              expression: "type == 'website'",
            },
          ],
          attrs: { label: "Website Name", "is-common-use": true },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.website,
            callback: function ($$v) {
              _vm.website = $$v
            },
            expression: "website",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "blog",
              expression: "type == 'blog'",
            },
          ],
          attrs: { label: "Blog Name", "is-common-use": true },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.blog,
            callback: function ($$v) {
              _vm.blog = $$v
            },
            expression: "blog",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "periodical",
              expression: "type == 'periodical'",
            },
          ],
          attrs: { label: "DOI for the Review", "is-common-use": true },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.doi,
            callback: function ($$v) {
              _vm.doi = $$v
            },
            expression: "doi",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL for the Review",
            "help-text": "Type or paste the entire URL if applicable.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }