<!--
- Book
-- Reference Type Id: 2
-- 2024.06.13
-->

<template>
	<div>
		<reference-type-switcher
			v-if="isThirdPartyLookUp"
			original-reference-type-id="2" />

		<section class="mb-5">
			<h3>Format Options</h3>
			
			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>

				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Book"
								value="book"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Radio_Book">
								Book, e-book, or audiobook
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Choose this reference type for print books, digital or e-books, and audiobook recordings.  APA 7th Edition formats all of them the same way." role="tooltip" />
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_BookChapter"
								value="bookChapter"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Radio_BookChapter">
								Chapter in edited book
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="This reference type is for a chapter in a book where each chapter is by a different author." role="tooltip" />
							</label>
						</div>
					</div>
				</div>
			</div>
			
			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type === 'book'" key="divKindBook">
				<label>What kind of book, e-book, or audiobook are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of book, e-book, or audiobook are you referencing?">
					<option value="whole">
						All or part of a book
					</option>
					<option value="translated">
						Foreign language or translated book
					</option>
					<option value="republished">
						Republished book
					</option>
					<option value="multivolume">
						Volume in a series
					</option>
				</select>
			</div>

			<div class="form-group mb-0 py-2" v-show="type === 'bookChapter'" key="divKindBookChapter">
				<label>What kind of chapter are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of chapter are you referencing?">
					<option value="whole">
						All or part of a book chapter
					</option>
					<option value="translated">
						Foreign language or translated chapter
					</option>
					<option value="book">
						Republished chapter, originally from a book
					</option>
					<option value="journal">
						Republished chapter, originally from a journal
					</option>
					<option value="multivolume">
						Chapter from a volume in a series
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:used-contributor-types="usedContributorTypes"
				:is-book-chapter="type === 'bookChapter'" />
		</section>

		<section class="mb-5">
			<h3>Book Publication Information</h3>
			
			<form-textarea-reference-wizard
				v-show="type === 'bookChapter'"
				label="Chapter Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="chapterTitle" />
			
			<form-textarea-reference-wizard
				v-show="showTranslatorFields && type === 'bookChapter' && kind != 'multivolume'"
				label="Translated Chapter Title"
				help-text="If the original chapter title is provided in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedChapterTitle" />

			<form-textarea-reference-wizard
				v-show="kind != 'multivolume'"
				:label="calcBookTitleLabel"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-bracket-help="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="bookTitle" />

			<form-textarea-reference-wizard
				v-show="showTranslatorFields && kind != 'multivolume'"
				label="Translated Book Title"
				help-text="If the original book title is provide in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedTitle" />

			<form-textarea-reference-wizard
				v-show="kind == 'multivolume'"
				label="Volume Title"
				help-text="Type the title of the single volume (book) from the multi-volume series. Don't include a volume number."
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="volumeTitle" />
			
			<form-textarea-reference-wizard
				v-show="showTranslatorFields && kind === 'multivolume'"
				label="Translated Volume Title"
				help-text="If the original volume title is provided in another language, type the translated volume title."
				:is-autosize="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedVolumeTitle" />

			<form-textarea-reference-wizard
				v-show="kind == 'multivolume'"
				label="Series Title"
				help-text="Type the title of the series if it is included on each of the separate volumes.  If you're unsure, omit the series title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="seriesTitle" />

			<form-input-reference-wizard
				v-show="type === 'bookChapter'"
				label="Chapter Page Numbers"
				help-text="Give the page numbers that include the reference text (ex. 45-78). Discontinuous pages are separated with comma and space. Ex. 45-78, 93."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="referencePages" />

			<form-input-reference-wizard
				label="Publication Year"
				help-text="List the year the work was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard
				label="Edition"
				help-text="For numbered editions, type the ordinal number (1st, 2nd, etc.). For named editions, type the name (e.g., 2nd ed., rev.)."
				@generate-reference-preview="generateReferencePreview"
				v-model="edition" />

			<form-input-reference-wizard
				v-show="kind == 'multivolume'"
				label="Volume Number"
				help-text="Use numerals for the volume number."
				@generate-reference-preview="generateReferencePreview"
				v-model="volume" />

			<form-input-reference-wizard
				label="Format"
				help-text="Only include a format if the content of the e-book or audiobook is different from the original book."
				@generate-reference-preview="generateReferencePreview"
				v-model="format" />

			<control-publishers-v2
				field-type="reference" />

			<control-toggle-select
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:label="toggleSelect.doiUrl.label"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />
			
		</section>

		<section class="mb-5">
			<h3 v-show="
					kind === 'translated' ||
					kind === 'journal' ||
					kind === 'republished' ||
					(type === 'bookChapter' && kind === 'book') ||
					(type === 'bookChapter' && kind === 'multivolume') ||
					(showTranslatorFields && kind === 'whole')">
				Original Publication Information
			</h3>
			
			<form-textarea-reference-wizard
				v-show="kind === 'republished'"
				label="Original Book Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalBookTitle" />

			<form-textarea-reference-wizard
				v-show="kind === 'journal'"
				label="Original Article Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalArticleTitle" />

			<form-input-reference-wizard
				v-show="kind == 'journal'"
				label="Original Journal Name"
				help-text="Type the name of the journal exactly as it appears in the work."
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalJournalTitle" />			
				
			<form-input-reference-wizard
				v-show="kind == 'journal'"
				label="Original Volume Number"
				help-text="Use numbers for the volume number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalVolume" />

			<form-input-reference-wizard
				v-show="kind == 'journal'"
				label="Original Issue Number"
				help-text="Use numbers for the issue number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalIssue" />

			<form-input-reference-wizard
				v-show="type === 'bookChapter' && (kind == 'journal' || kind == 'republished')"
				label="Original Page Numbers"
				help-text="Give the page numbers that include the reference text (ex. 45-78). Discontinuous pages are separated with comma and space. Ex. 45-78, 93."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalReferencePages" />

			<form-input-reference-wizard
				v-show="kind == 'translated' || kind == 'journal' || kind == 'republished' || (showTranslatorFields && kind == 'whole') || (type === 'bookChapter' && kind === 'book')"
				label="Original Publication Year"
				help-text="List the year the original work was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalPublicationDate" />

			<form-input-reference-wizard
				v-show="type === 'bookChapter' && kind == 'republished'"
				label="Original Book Edition"
				help-text="For numbered editions, type the ordinal number (1st, 2nd, etc.). For named editions, type the name without &quot;edition.&quot;"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalEdition" />

			<control-publishers-v2
				v-show="type === 'bookChapter' && kind == 'republished'"
				field-type="original" />
				
			<control-toggle-select
				v-show="type === 'bookChapter' && (kind == 'republished' || kind == 'journal' || kind == 'multivolume')"
				:is-common-use="toggleSelect.originalDoiUrl.isCommonUse"
				:label="toggleSelect.originalDoiUrl.label"
				:options="toggleSelect.originalDoiUrl.options"
				group-name="originalDoiUrl" />
				
		</section>

	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';
import ReferenceTypeSwitcher from '@/components/reference-rules-genesis/ReferenceTypeSwitcher';

export default {
	name: 'RefTypeBook',
	mixins: [mixinReferenceTypeGenesis],
	computed:{
		calcBookTitleLabel(){
			if(this.$store.state.referenceRules.isTranslator){
				return 'Original Book Title'
			}
			return 'Book Title';
		},
	},
	methods: {
		// uses this.kind to calculate this.contributors
		calcContributors(){
			// console.log('book > calc contribs');
			
			this.usedContributorTypes = [];
			this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);

			// Author
			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});

			// Group Author
			this.addContributorRole({
				type: 'groupAuthor',
			});

			// Illustrator
			this.addContributorRole({
				type: 'illustrator',
			});

			// Editor
			this.addContributorRole({
				addDefault: (this.kind == 'multivolume' || this.type === 'bookChapter') ? true : false,
				type: 'editor',
			});
		
			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind
				
				// Narrator
				if(this.kind === 'whole' || this.kind === 'translated'){
					this.addContributorRole({
						type: 'narrator',
					});
				}

				// Translator
				if(this.kind === 'translated') {
					this.addContributorRole({
						addDefault: true,
						type: 'translator',
					});
					this.usedContributorTypes.push('translator');
					this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
				} else if(this.kind !== 'multivolume') {
					this.addContributorRole({
						addDefault: false,
						type: 'translator',
					});
					this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);
				}

				this.sortContributorTypes();

			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)
				// Update Used Contributor Types
				this.usedContributorTypes = ['editor', 'groupAuthor', 'illustrator', 'narrator', 'translator' ];

				this.$nextTick(()=>{

					// Original Editor
					if(this.type === 'bookChapter' && (this.kind === 'book' || this.kind === 'journal')){
						this.usedContributorTypes.push('author');
						this.usedContributorTypes.push('chapterAuthor');

						this.addContributorRole({
							addDefault: true,
							type: 'chapterAuthor',
						});
						this.addContributorRole({
							type: 'originalEditor',
						});

						this.removeEmptyContributorRole({
							type: 'author',
						});

					} else {
						this.addContributorRole({
							addDefault: true,
							type: 'author',
						});
						this.removeEmptyContributorRole({
							type: 'chapterAuthor',
						});
						this.removeEmptyContributorRole({
							type: 'originalEditor',
						});

					}
					
					if(this.kind === 'translated') {
						this.addContributorRole({
							addDefault: true,
							type: 'translator',
						});
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);

					} else {
						this.removeEmptyContributorRole({
							type: 'translator',
						});

					}

					this.sortContributorTypes();

				});//e:nextTick
				
			}//e:if:isRefRulesEmpty

			// check all contributors for a translator
			if(_has(this.referenceRules.data, 'contributors')){
				_forEach(this.referenceRules.data.contributors, (contributorBlock)=>{
					if(contributorBlock.type === 'chapterTranslator' || contributorBlock.type === 'translator'){
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					}
				});

			}

			// Book Chapters also have chatper translator options (wrapped in next tick because isTypeBook needs to fully set before i push into it)
			this.$nextTick(()=>{
				if(this.type === 'bookChapter'){
					this.usedContributorTypes.push('chapterTranslator');
				}
				this.generateReferencePreview();
			});//e:nextTick

		},//e:calcContributors

		typeChange(){
			// changing radio button 'type' should reselect the first option in the 'kind' dropdown
			this.kind = 'whole';

			this.$nextTick(()=>{
				this.calcContributors();
			});
		},

	},
	mounted() {
		if(this.kind === ''){
			this.kind = 'whole';
		}
		if(this.type === ''){
			this.type = 'book';
		}

		this.$nextTick(()=>{
			this.calcContributors();
		});
	},
	components:{
		ReferenceTypeSwitcher,
	},
}
</script>
