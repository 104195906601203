var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Title of the Work",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Description",
            "help-text":
              "Add a description for the kind of work you're using. If the work has a description for a title, don't add a description here.",
            "is-autosize": true,
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.description,
            callback: function ($$v) {
              _vm.description = $$v
            },
            expression: "description",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Publication Year",
            "help-text":
              "Don't include a publication year for dynamic maps (e.g., Google Maps).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", {
          attrs: { label: "Publisher or Producer", "field-type": "reference" },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL (Web address)",
            "help-text":
              "Include a link to the equipment's website if applicable.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            "help-text":
              "If the work can change, enter the date you accessed the work for your research (e.g., interactive maps).",
            id: "RefWiz_RetrievalDate",
            label: "Retrieval Date",
            "is-today-button": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetRetrieval,
            "date-clear": _vm.dateClearRetrieval,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }