<!--
- Datasets
-- Reference Type Id: 15
-- 2024.01.31
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Published"
								value="published"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Published">
								A published dataset
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true" title="This is for data sets that have been published or archived for other&rsquo;s use." role="tooltip" />
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Raw"
								value="raw"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Raw">
								Unpublished raw data
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Use this reference type for data sets that are only available directly or have yet to be formally published. This can include open-ended data sets that change over time." role="tooltip" />
							</label>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Title of the Dataset"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard
				label="Version Information"
				help-text="If your work includes a version number or other identifying information, type it here."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="version" />

			<form-input-reference-wizard
				v-show="type === 'published'"
				label="Publication Year"
				help-text="Enter the year the data was published or archived."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard
				v-show="type === 'raw'"
				label="Collection Year"
				help-text="Enter the year the data was collected."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<control-publishers-v2
				v-show="type === 'published'"
				field-type="reference" /> 

			<control-publishers-v2
				v-show="type === 'raw'"
				button-text="data source"
				label="Data Source"
				field-type="reference" /> 

			<control-toggle-select
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />

			<form-datepicker
				v-show="type === 'raw'"
				help-text="If the data may be amended or changed in the future, enter the date you accessed the work."
				id="RefWiz_RetrievalDate" 
				label="Retrieval Date"
				:is-today-button="true"
				:paper-format="0"
				@date-set="dateSetRetrieval"
				@date-clear="dateClearRetrieval" />

		</section>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeDatasets',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			// Author
			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});

			// Group Author
			this.addContributorRole({
				type: 'groupAuthor',
			});

			if(this.isRefRulesEmpty) {
				// Load Based on previously entered data in Step 3 (or an Edit)
				
				// Update Used Contributor Types
				this.usedContributorTypes = ['author','groupAuthor'];

			}//e:if:isRefRulesEmpty

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors

		dateClearRetrieval(){
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'retrievalDate',
				value: ''
			});
			this.generateReferencePreview();
		},
		
		dateSetRetrieval(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'retrievalDate',
					value: newDate
				});
				this.generateReferencePreview();
			}
		},
	},
	mounted() {
		if(this.type == ''){
			this.type = 'published';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>