var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "radio", id: "RefWiz_Type_Film", value: "film" },
                domProps: { checked: _vm._q(_vm.type, "film") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "film"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_Film" },
                },
                [_vm._v("\n\t\t\t\t\t\t\tDomestic film\n\t\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Foreign",
                  value: "foreign",
                },
                domProps: { checked: _vm._q(_vm.type, "foreign") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "foreign"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_Foreign" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tFilm in another language\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Film Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "foreign",
              expression: "type === 'foreign'",
            },
          ],
          attrs: {
            label: "Translated Film Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.translatedTitle,
            callback: function ($$v) {
              _vm.translatedTitle = $$v
            },
            expression: "translatedTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: { label: "Release Year", "is-common-use": true },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Special Edition Title",
            "help-text":
              "Only include a special edition title if it is different from the original film/movie (e.g., Extended edition).",
            "is-autosize": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.format,
            callback: function ($$v) {
              _vm.format = $$v
            },
            expression: "format",
          },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", {
          attrs: {
            "button-text": "company",
            label: "Production Company",
            "field-type": "reference",
            "is-show-city-state": false,
            "show-help-text": false,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Platform",
            "help-text":
              "If you are watching the film on a service, type the name of the service where you watched it.",
            "is-common-use":
              _vm.referenceFormatTypeId == _vm.$enums.Format.MLA9
                ? true
                : false,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.platform,
            callback: function ($$v) {
              _vm.platform = $$v
            },
            expression: "platform",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL",
            "help-text":
              "Only include a URL if the film can only be accessed online. You can use a URL shortener if it is too long (e.g., bit.ly).",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }