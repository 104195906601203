<!--
- Form Label and Input
-- Contributor Name Maker inputs (ex: First Name)
-- 2024.02.21
-->

<template>
	<div class="form-group form-group-split mb-2">
		<div class="flex-shrink-1">
			<label
				:for="fullId"
				v-html="calcLabel" />
		</div>

		<div class="w-100">
			<input
				autocomplete="off"
				ref="focusElement"
				type="text"
				:class="['form-control', {
					'focus-first': isFocusOnAdd,
					'form-control-primary': showIsCommonUse,
					'is-suggested': isShowSuggestion,
				}]"
				:id="fullId"
				:pendo-grab-text="pendoGrabText"
				:value="value"
				@blur="blurInput"
				@input="typeInput($event.target.value)"
				v-debounce:750ms="debouncedGenerateReference" /> 

			<div class="suggested-feedback" v-if="isShowSuggestionGroupAuthor">
				Tip: Group Authors are for organizational or corporate authors - not a group of individual authors.
			</div>

			<div class="invalid-feedback d-block mt-1" v-if="isContributorBlockErrorState">
				Our search didn&#39;t return a page author, but most sites have a group author. We suggest entering the name of the organization responsible for the page (e.g.,&nbsp;Sasquatch Watcher&#39;s Association).
			</div>
			
		</div><!--col-sm-9-->
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import CheckSuggestedField from '@/helpers/suggest-field';
import mixinId from '@/mixins/id';

export default {
	name: 'FormInputContributor',
	mixins: [mixinId],
	props:{
		isCommonUse: {	// used for Genesis ref types (blue outline)
			default: false,
			type: Boolean
		},
		isFocusOnAdd:{	// used to focus an element when a new name is added
			default: false,
			type: Boolean,
		},
		isContributorBlockErrorState:{
			default: false,
			type: Boolean,
		},
		isSuggestGroupAuthor: {
			default: false,
			type: Boolean
		},
		label: {
			type: String,
		},
		pendoGrabText:{
			default: '',
			type: String,
		},
		value: {
			type: String,
		},
	},
	computed:{
		calcLabel(){
			// add &ZeroWidthSpace; here to prevent browsers from autofilling 'First' labels
			return this.label.slice(0, 1) + '&ZeroWidthSpace;' + this.label.slice(1);
		},
		fullId(){
			let urlFriendlyLabel = this.label.replace(/\W+/g, '-').toLowerCase() || '';
			return this.safeId + '_' + urlFriendlyLabel;
		},
		showIsCommonUse(){
			// only show the common use outline if the field is blank
			if(this.value === '') {
				return this.isCommonUse;
			} else {
				return false;
			}			
		},
	},
	data(){
		return {
			isShowSuggestion: false,
			isShowSuggestionGroupAuthor: false,
		}
	},
	methods:{
		blurInput(){
			this.$store.dispatch('referenceRules/generateReferencePreview');
		},
		debouncedGenerateReference(value){
			// no validation needed just get right to to it
			this.$store.dispatch('referenceRules/generateReferencePreview');
		},//e:debouncedGenerateReference

		typeInput(value){
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: 'formReferenceWizard',
			});
			
			if(this.isSuggestGroupAuthor){
				this.isShowSuggestionGroupAuthor = CheckSuggestedField.groupAuthorInput(value);
			}

			this.$emit('input', value);
			this.$store.dispatch('referenceRules/generateReferencePreview');
		}//e:typeInput
	},
	created(){
		this.emitter.on('focusFirstContributorField', ()=>{
			if(this.isFocusOnAdd){
				let $focusElCollection = this.$parent.$parent.$el.getElementsByClassName('focus-first');
				$focusElCollection[$focusElCollection.length - 1].focus();
			}
		});
	},
	destroyed(){
		this.emitter.off('focusFirstContributorField');
	},
}
</script>
