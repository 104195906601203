var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-3" }, [
    _vm.$store.state.referenceRules.referenceTypeId === 23 &&
    _vm.$store.state.referenceRules.formData.kind === "anonymous"
      ? _c("div", { key: "divInterviewAnonymous", staticClass: "form-group" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("\n\t\t\t\tDescription\n\t\t\t")]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control form-control-primary",
              attrs: { autocomplete: "off", type: "text" },
              on: {
                blur: _vm.blurAnonymousInput,
                input: function ($event) {
                  return _vm.typeAnonymousInput($event.target.value)
                },
              },
            }),
          ]),
        ])
      : _c(
          "div",
          { key: "divShowFields" },
          [
            _c("div", { staticClass: "d-flex" }, [
              _vm.showPrimaryContributor
                ? _c("div", { key: "divPrimary", staticClass: "w-100 mb-1" }, [
                    _c("label", { staticClass: "text-brand-500 mb-0" }, [
                      _vm._v("Primary Contributor Name"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "help-text mb-1" }, [
                      _vm._v(
                        "\n\t\t\t\t\tPrimary contributors are listed at the beginning of a reference.\n\t\t\t\t"
                      ),
                    ]),
                  ])
                : _c("div", { key: "divElse", staticClass: "w-100 mb-2" }, [
                    _c("label", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.contributorLabel)),
                    ]),
                  ]),
              _vm._v(" "),
              _vm.isAddAnother
                ? _c("div", { staticClass: "icon-row flex-shrink-1" }, [
                    _c("div", { staticClass: "d-flex flex-row-reverse" }, [
                      _vm.contributorIndex !=
                      _vm.$store.state.referenceRules.formData.contributors
                        .length -
                        1
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "#",
                                title: "Move Contributor Down",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.sortContributorBlock(false)
                                },
                              },
                            },
                            [
                              _c("span", {
                                staticClass: "svg svg-sort bi-arrow-down",
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.contributorIndex !== 0
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "#",
                                title: "Move Contributor Up",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.sortContributorBlock(true)
                                },
                              },
                            },
                            [
                              _c("span", {
                                staticClass: "svg svg-sort bi-arrow-up",
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#", title: "Remove Contributor" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeContributorBlock.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_c("span", { staticClass: "svg svg-delete bi-x-lg" })]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "help-text" }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.calcHelpText) + "\n\t\t"),
            ]),
            _vm._v(" "),
            _vm.$store.state.referenceRules.referenceTypeId !== 23 ||
            _vm.$store.state.referenceRules.formData.type !== "personal" ||
            _vm.$store.state.referenceRules.formData.kind !== "named"
              ? _c("div", { staticClass: "form-group form-group-split mb-2" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-100" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contributor_type,
                            expression: "contributor_type",
                          },
                        ],
                        staticClass: "form-select",
                        attrs: { "aria-label": "Role" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.contributor_type = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.typeChange,
                          ],
                        },
                      },
                      _vm._l(
                        _vm.displayedContributorTypes,
                        function (option, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: option.value } },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(option.display) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("form-input-contributor", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.contributor_type === "custom" ||
                    _vm.contributor_type === "reviewComposer",
                  expression:
                    "contributor_type === 'custom' || contributor_type === 'reviewComposer'",
                },
              ],
              attrs: {
                label: "Role",
                "is-focus-on-add": true,
                "is-common-use": true,
              },
              model: {
                value: _vm.contributor_name,
                callback: function ($$v) {
                  _vm.contributor_name = $$v
                },
                expression: "contributor_name",
              },
            }),
            _vm._v(" "),
            _c("form-input-contributor", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.contributor_type === "userName" ||
                    _vm.contributor_type === "individualCommentor",
                  expression:
                    "contributor_type === 'userName' || contributor_type === 'individualCommentor'",
                },
              ],
              attrs: {
                label: "Name",
                "is-focus-on-add": true,
                "is-common-use": true,
              },
              model: {
                value: _vm.contributor_name,
                callback: function ($$v) {
                  _vm.contributor_name = $$v
                },
                expression: "contributor_name",
              },
            }),
            _vm._v(" "),
            _c("form-input-contributor", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isGroupType,
                  expression: "isGroupType",
                },
              ],
              attrs: {
                label: "Name",
                "is-focus-on-add": true,
                "is-contributor-block-error-state":
                  _vm.contributor_is3pluErrorState,
                "is-common-use": true,
                "is-suggest-group-author": true,
              },
              model: {
                value: _vm.contributor_groupName,
                callback: function ($$v) {
                  _vm.contributor_groupName = $$v
                },
                expression: "contributor_groupName",
              },
            }),
            _vm._v(" "),
            _c("form-input-contributor", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isGroupType,
                  expression: "isGroupType",
                },
              ],
              attrs: { label: "Abbrev." },
              model: {
                value: _vm.contributor_abbreviation,
                callback: function ($$v) {
                  _vm.contributor_abbreviation = $$v
                },
                expression: "contributor_abbreviation",
              },
            }),
            _vm._v(" "),
            _c("form-input-contributor", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFullNameFields,
                  expression: "isFullNameFields",
                },
              ],
              attrs: {
                label: "First",
                "is-focus-on-add": true,
                "is-common-use": true,
              },
              model: {
                value: _vm.contributor_firstName,
                callback: function ($$v) {
                  _vm.contributor_firstName = $$v
                },
                expression: "contributor_firstName",
              },
            }),
            _vm._v(" "),
            _c("form-input-contributor", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFullNameFields,
                  expression: "isFullNameFields",
                },
              ],
              attrs: { label: "Middle" },
              model: {
                value: _vm.contributor_middleName,
                callback: function ($$v) {
                  _vm.contributor_middleName = $$v
                },
                expression: "contributor_middleName",
              },
            }),
            _vm._v(" "),
            _c("form-input-contributor", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFullNameFields,
                  expression: "isFullNameFields",
                },
              ],
              attrs: { label: "Last", "is-common-use": true },
              model: {
                value: _vm.contributor_lastName,
                callback: function ($$v) {
                  _vm.contributor_lastName = $$v
                },
                expression: "contributor_lastName",
              },
            }),
            _vm._v(" "),
            _c("form-input-contributor", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFullNameFields,
                  expression: "isFullNameFields",
                },
              ],
              attrs: { label: "Suffix" },
              model: {
                value: _vm.contributor_suffix,
                callback: function ($$v) {
                  _vm.contributor_suffix = $$v
                },
                expression: "contributor_suffix",
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-shrink-1" }, [
      _c("label", { staticClass: "col-form-label" }, [_vm._v("Role")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }