<!--
- Journal
-- Reference Type Id: 1
-- 2024.06.20
-->

<template>
	<div>
		<reference-type-switcher
			v-if="isThirdPartyLookUp"
			original-reference-type-id="1" />

		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Journal"
								value="journal"
								v-model="type"
								@change="typeChange" /> 
								
							<label class="form-check-label" for="RefWiz_Type_Journal">
								Journal article
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="This is for a single article that is published in a journal. It includes both print and digital copies of journal articles." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Database"
								value="database"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Database">
								An article in a database
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									title="Use this reference type for articles that can only be found in a database (e.g., UpToDate, Cochrane Database, etc.)."
									role="tooltip" />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Reprinted"
								value="reprinted"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Reprinted">
								Republished journal article
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="This reference type is for journal articles that have been reprinted or republished more than once.  They include both the original date of publication and the date of the version you are citing." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Special"
								value="special"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Special">
								Special issue or section
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									title="You can use this reference type if you are citing an entire section or issue of a journal.  If you are only citing one article in a special issue, select Journal Article instead." role="tooltip" />
							</label>
						</div>
					</div>
				</div>
			</div>
			
			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type == 'journal'" key="divKindJournal">
				<label>What kind of article are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of article are you referencing?">
					<option value="article">
						A single published article
					</option>
					<option value="foreign">
						Foreign language or translated article
					</option>
					<option value="advance">
						Advance online publication
					</option>
					<option value="inPress">
						In-press article
					</option>
					<option value="editorial">
						Editorial
					</option>
					<option value="retracted">
						A single retracted article
					</option>
				</select>
			</div>
			<div class="form-group mb-0 py-2" v-show="type == 'database'" key="divKindDatabase">
				<label>What kind of database article are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of database article are you referencing?">
					<option value="article">
						A single article
					</option>
					<option value="foreign">
						Foreign language or translated article
					</option>
				</select>
			</div>
			<div class="form-group mb-0 py-2" v-show="type == 'reprinted'" key="divKindReprinted">
				<label>What kind of republished article are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of republished article are you referencing?">
					<option value="article">
						A single article
					</option>
					<option value="foreign">
						Foreign language or translated article
					</option>
				</select>
			</div>
			<div class="form-group mb-0 py-2" v-show="type == 'special'" key="divKindSpecial">
				<label>What part or kind of special issue are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What part or kind of special issue are you referencing?">
					<option value="issue">
						Entire special issue
					</option>
					<option value="section">
						Special section
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				v-show="type !== 'special'"
				label="Article Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="articleTitle" />

			<form-textarea-reference-wizard
				v-show="showTranslatorFields && type !== 'special'"
				label="Translated Article Title"
				help-text="If the original article title is provide in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedArticleTitle" />
			
			<form-textarea-reference-wizard
				v-show="type === 'special' && kind === 'issue'"
				label="Issue Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="issueTitle" />

			<form-textarea-reference-wizard
				v-show="type === 'special' && kind === 'section'"
				label="Section Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="sectionTitle" />

			<form-textarea-reference-wizard
				v-show="showTranslatorFields && type === 'special'"
				label="Translated Issue/Section Title"
				help-text="If the original title is provide in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedIssueTitle" />
			
			<form-textarea-reference-wizard
				v-show="type != 'database'"
				label="Journal Name"
				help-text="Type the full title of the journal, exactly as it appears in the publication."
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="journalTitle" />
			
			<form-input-reference-wizard
				v-show="type == 'database'"
				label="Database Name"
				help-text="Type the full title of the database, exactly as it appears on the publication."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="databaseTitle" />

			<form-input-reference-wizard
				v-show="kind !== 'inPress'"
				label="Publication Year"
				help-text="List the year the work was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard
				v-show="kind !== 'advance' && kind !== 'inPress' && type != 'database'"
				label="Volume Number"
				help-text="Use numbers for the volume number."
				:is-common-use="(referenceFormatTypeId == $enums.Format.APA7 || referenceFormatTypeId == $enums.Format.MLA9) ? true : false"
				@generate-reference-preview="generateReferencePreview"
				v-model="volume" />
					
			<form-input-reference-wizard
				v-show="kind !== 'advance' && kind !== 'inPress' && type != 'database'"
				label="Issue Number"
				help-text="Use numbers for the issue number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="issue" />
			
			<control-toggle-select
				v-show="kind !== 'advance' && kind !== 'inPress' && kind != 'issue' && type != 'database'"
				:is-common-use="toggleSelect.pagesArticle.isCommonUse"
				:label="toggleSelect.pagesArticle.label"
				:options="toggleSelect.pagesArticle.options"
				group-name="pagesArticle" />
			
			<control-toggle-select
				v-show="type !== 'special'" 
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:label="toggleSelect.doiUrl.label"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />

			<form-datepicker
				v-show="kind !== 'advance' && type !== 'special'" 
				help-text="Only include if your article is in a changing database like UpToDate."
				id="RefWiz_RetrievalDate" 
				label="Retrieval Date"
				:is-today-button="true"
				:paper-format="0"
				@date-set="dateSetRetrieval"
				@date-clear="dateClearRetrieval" />
		</section>

		<section class="mb-5" v-show="kind == 'retracted'">
			<h3>Retraction Information</h3>
			
			<form-datepicker
				help-text="Only include if your article is in a changing database like UpToDate."
				id="RefWiz_RetractionDate" 
				label="Retraction Date"
				:paper-format="0"
				:is-common-use="true"
				@date-set="dateSetRetraction"
				@date-clear="dateClearRetraction" />

			<form-input-reference-wizard
				label="Retraction Volume Number"
				help-text="Use numbers for the volume number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="retractionVolume" />

			<form-input-reference-wizard
				label="Retraction Issue Number"
				help-text="Use numbers for the issue number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="retractionIssue" />

			<control-toggle-select
				:label="toggleSelect.retractionPagesArticle.label"
				:is-common-use="toggleSelect.retractionPagesArticle.isCommonUse"
				:options="toggleSelect.retractionPagesArticle.options"
				group-name="retractionPagesArticle" />

			<control-toggle-select
				:label="toggleSelect.retractionDoiUrl.label"
				:is-common-use="toggleSelect.retractionDoiUrl.isCommonUse"
				:options="toggleSelect.retractionDoiUrl.options"
				group-name="retractionDoiUrl" />
		</section>

		<section class="mb-5" v-show="type == 'reprinted' || kind == 'foreign'">
			<h3>Original Publication Information</h3>
			
			<form-textarea-reference-wizard
				v-show="type == 'reprinted'"
				label="Original Article Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalArticleTitle" />

			<form-textarea-reference-wizard
				v-show="type == 'reprinted'"
				label="Original Journal Name"
				help-text="Type the full title of the journal, exactly as it appears in the publication."
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalJournalTitle" />

			<form-input-reference-wizard
				label="Original Publication Year"
				help-text="List the year the work was originally published."
				@generate-reference-preview="generateReferencePreview"
				v-model="originalPublicationDate" />

			<form-input-reference-wizard 
				v-show="type == 'reprinted'"
				label="Original Volume Number"
				help-text="Use numbers for the volume number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalVolume" />

			<form-input-reference-wizard
				v-show="type == 'reprinted'"
				label="Original Issue Number"
				help-text="Use numbers for the issue number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalIssue" />

			<control-toggle-select
				v-show="type == 'reprinted'"
				:label="toggleSelect.originalPagesArticle.label"
				:is-common-use="toggleSelect.originalPagesArticle.isCommonUse"
				:options="toggleSelect.originalPagesArticle.options"
				group-name="originalPagesArticle" />

			<control-toggle-select
				v-show="type == 'reprinted'"
				:label="toggleSelect.originalDoiUrl.label"
				:is-common-use="toggleSelect.originalDoiUrl.isCommonUse"
				:options="toggleSelect.originalDoiUrl.options"
				group-name="originalDoiUrl" />

		</section>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';
import ReferenceTypeSwitcher from '@/components/reference-rules-genesis/ReferenceTypeSwitcher';

export default {
	name: 'RefTypeJournal',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];
			this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);

			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});

			this.addContributorRole({
				type: 'groupAuthor',
			});

			this.addContributorRole({
				addDefault: (this.type === 'special') ? true : false, // special type gets a default added - non special just gets a role added
				type: 'editor',
			});

			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind

				// Translator
				if(this.kind == 'foreign') {
					this.addContributorRole({
						addDefault: true,
						type: 'translator',
					});
					this.usedContributorTypes.push('translator');
					this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
				} else if(this.kind === 'article' || this.kind === 'editorial' || this.type === 'special') {
					this.addContributorRole({
						type: 'translator',
					});
				}
			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)
				
				// Update Used Contributor Types
				this.usedContributorTypes = ['author','groupAuthor','editor', 'translator'];
			
				this.$nextTick(()=>{
					if(this.kind === 'foreign') {
						this.addContributorRole({
							addDefault: true,
							type: 'translator',
						});
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					} else {
						this.removeEmptyContributorRole({
							type: 'translator',
						});
					}
				});
			}//e:if:isRefRulesEmpty
			
			// check all contributors for a translator
			if(_has(this.referenceRules.data, 'contributors')){
				_forEach(this.referenceRules.data.contributors, (contributorBlock)=>{
					if(contributorBlock.type === 'translator'){
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					}
				});
			}

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});

		},//e:calcContributors

		dateClearRetraction(){
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'retractionDate',
				value: ''
			});
			this.generateReferencePreview();
		},
		dateClearRetrieval(){
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'retrievalDate',
				value: ''
			});
			this.generateReferencePreview();
		},
		dateSetRetraction(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'retractionDate',
					value: newDate
				});
				this.generateReferencePreview();
			}
		},
		dateSetRetrieval(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'retrievalDate',
					value: newDate
				});
				this.generateReferencePreview();
			}
		},

		typeChange(){
			// changing radio button 'type' should reselect the first option in the 'kind' dropdown
			if(this.type === 'special'){
				this.kind = 'issue';
			} else {
				this.kind = 'article';
			}

			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted(){
		if(this.kind === ''){
			this.kind = 'article';
		}
		if(this.type === ''){
			this.type = 'journal';
		}
		this.$nextTick(()=>{
			this.calcContributors();
		});
	},
	components:{
		ReferenceTypeSwitcher,
	},
}
</script>