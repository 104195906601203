<!--
- Tests & Scales
-- Reference Type Id: 44
-- 2023.10.11
-->

<template>
	<div>
	
		<section class="mb-5">
			<h3>Format Options</h3>
			
			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Manual"
								value="manual"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Manual">
								Manual for a test, scale, or inventory
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Use this if a manual is available for the test, scale, or inventory. The manual can be a physical or digital copy." role="tooltip" />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Direct"
								value="direct"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Direct">
								A test, scale, or inventory directly (if manual isn&#39;t available)
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="If you only have access to the test, scale, or inventory directly, use this type." role="tooltip" />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Record"
								value="record"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Record">
								A record in a test database
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Use this type if you need to cite a single record within a database or inventory." role="tooltip" />
							</label>
						</div>
					</div>
				</div>

				
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
				
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				v-show="type != 'record'"
				label="Title of the Test, Scale, or Inventory"
				help-text="Enter the title using Title Case capitalization.  Include any abbreviations in parentheses as needed."
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />
			
			<form-textarea-reference-wizard
				v-show="type == 'record'"
				label="Database Record Name"
				help-text="Enter the title of the database record using Title Case capitalization. Include any database abbreviations in parentheses as needed."
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard
				v-show="type == 'record'"
				label="Database Record Number"
				help-text="Type the number for the database record without parentheses."
				@generate-reference-preview="generateReferencePreview"
				v-model="number" />
			
			<form-input-reference-wizard
				label="Publication Year"
				help-text="List the year the work was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<control-publishers-v2
				v-show="type != 'record'"
				field-type="reference" /> 

			<control-publishers-v2
				v-show="type == 'record'"
				button-text="database"
				label="Database Name"
				help-text="Enter the name of the database."
				field-type="reference" /> 

			<form-input-reference-wizard
				label="URL"
				help-text="Type or paste the entire URL. You can use a URL shortener if it is too long (e.g., bit.ly)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeTestsScales',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['author', 'editor', 'groupAuthor'];

			this.addContributorRole({
				addDefault: (this.type == 'direct') ? false : true,
				type: 'author',
			});

			this.addContributorRole({
				type: 'editor',
			});

			this.addContributorRole({
				addDefault: (this.type == 'direct') ? true: false,
				type: 'groupAuthor',
			});
			
			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
	mounted(){
		if(this.type === ''){
			this.type = 'manual';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>