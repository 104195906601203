<!--
- CitationBlock
-- this is only for US State Court Reference Types but its easier to just copy how i had it for publishers
-->

<template>
	<div class="mb-3">
        <div class="form-group mb-1">
			<div class="d-flex">
				<div class="w-100 mb-2">
					&nbsp;
				</div>
				<div class="icon-row flex-shrink-1">
					<div class="d-flex flex-row-reverse">
						<a href="#" @click.prevent="sortCitationBlock(false)" v-if="citationIndex != ($store.state.referenceRules.formData.citation.length - 1)" title="Move Parallel Citation Down">
							<span class="svg svg-sort bi-arrow-down" />
						</a>
						<a href="#" @click.prevent="sortCitationBlock(true)" v-if="citationIndex !== 0" title="Move Parallel Citation Up">
							<span class="svg svg-sort bi-arrow-up" />
						</a>
						<a href="#" @click.prevent="removeCitationBlock" title="Remove Parallel Citation">
							<span class="svg svg-delete bi-x-lg" />
						</a>
					</div>
				</div>
				
			</div><!--d-flex-->
		</div><!--form-group-->

        <citation-input
            label="Volume"
            :is-focus-on-add="true"
            v-model="citation_volume" />
        
        <citation-input
            label="Series"
            v-model="citation_series" />

        <citation-input
            label="Page"
            v-model="citation_pageNumber" />

	</div>
</template>

<script>
import CitationInput from '@/components/reference-rules-genesis/_CitationInput';

export default {
	name: 'CitationBlock',
	props:{
        citationIndex: {
			type: Number
		},
	},
	data() {
		return {
		}
	},
	computed:{
        citation_pageNumber:{
			get(){
				return this.$store.state.referenceRules.formData.citation[this.citationIndex].pageNumber;
			},
			set(value){
                this.$store.commit('referenceRules/UPDATE_CITATION_AT_INDEX', {
					citationIndex: this.citationIndex,
					key: 'pageNumber',
					value,
				});
			}
		},
        citation_series:{
			get(){
				return this.$store.state.referenceRules.formData.citation[this.citationIndex].series;
			},
			set(value){
                this.$store.commit('referenceRules/UPDATE_CITATION_AT_INDEX', {
					citationIndex: this.citationIndex,
					key: 'series',
					value,
				});
			}
		},
        citation_volume:{
			get(){
				return this.$store.state.referenceRules.formData.citation[this.citationIndex].volume;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_CITATION_AT_INDEX', {
					citationIndex: this.citationIndex,
					key: 'volume',
					value,
				});
			}
		},
	},
	methods: {
        removeCitationBlock(){
            this.$store.commit('referenceRules/REMOVE_CITATION_AT_INDEX',{
				citationIndex: this.citationIndex,
			});
			this.$store.dispatch('referenceRules/generateReferencePreview');
        },
        sortCitationBlock(isMoveUp){
            let oldIndex = (isMoveUp) ? (this.citationIndex - 1) : (this.citationIndex + 1);
			this.$store.commit('referenceRules/SORT_CITATION',{
				citationIndex: this.citationIndex,
				oldIndex,
			});
			this.$store.dispatch('referenceRules/generateReferencePreview');
        },
	},
	created() {
	},
	mounted() {
	},
	components: {
        CitationInput
	}
}
</script>
