var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Published",
                  value: "published",
                },
                domProps: { checked: _vm._q(_vm.type, "published") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "published"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Radio_Published" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tA published dissertation or thesis\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Raw",
                  value: "unpublished",
                },
                domProps: { checked: _vm._q(_vm.type, "unpublished") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "unpublished"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Radio_Raw" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tAn unpublished dissertation or thesis\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [
          _vm._v("What kind of dissertation or thesis are you referencing?"),
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.kind,
                expression: "kind",
              },
            ],
            staticClass: "form-select",
            attrs: {
              "aria-label":
                "What kind of dissertation or thesis are you referencing?",
            },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.kind = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.calcContributors,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "doctorate" } }, [
              _vm._v("\n\t\t\t\t\tA doctoral dissertation\n\t\t\t\t"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "masters" } }, [
              _vm._v("\n\t\t\t\t\tA master's thesis\n\t\t\t\t"),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Title of the Work",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Publication Year",
            "help-text": "Type the year the work was published or completed.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "published",
              expression: "type === 'published'",
            },
          ],
          attrs: {
            label: "Database or Archive Name",
            "help-text":
              "Enter the name of the database or archive where you retrieved the work.  Type it exactly as it appears.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.database,
            callback: function ($$v) {
              _vm.database = $$v
            },
            expression: "database",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "published",
              expression: "type === 'published'",
            },
          ],
          attrs: {
            label: "Record Identifier",
            "help-text":
              "If the work has a database number or identifier, enter it here.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.record,
            callback: function ($$v) {
              _vm.record = $$v
            },
            expression: "record",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Institution Name",
            "help-text":
              "Type the name of the institution that awarded the degree. Enter it exactly as it appears in the work.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.institution,
            callback: function ($$v) {
              _vm.institution = $$v
            },
            expression: "institution",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL",
            "help-text":
              "Enter the url for the work if you retrieved it online.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }