var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.referenceFormatTypeId === _vm.$enums.Format.MLA9
      ? _c("div", { staticClass: "alert alert-warning" }, [
          _c("p", { staticClass: "ps-3" }, [
            _vm._v(
              "\n\t\t\tThis reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).\n\t\t"
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Case Name",
            "help-text":
              'Type the full name of the case as it appears. It should be formatted as "Name v. Name"',
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("\n\t\t\t\tCase Location\n\t\t\t")]),
            _vm._v(" "),
            _c("div", {
              staticClass: "help-text mb-1",
              domProps: { innerHTML: _vm._s(_vm.calcHelpTextCaseLocation) },
            }),
            _vm._v(" "),
            _vm._l(
              _vm.$store.state.referenceRules.formData.citation,
              function (citationData, index) {
                return _c("citation-block", {
                  key: citationData.id,
                  attrs: { "citation-index": index },
                })
              }
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-grid" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-hover btn-hover-success",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addParallelCitation.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "svg bi-plus-lg" }),
                  _vm._v(" Add Parallel Citation\n\t\t\t\t"),
                ]
              ),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Deciding Court",
            "help-text":
              'If the case was not heard by the state\'s&nbsp;highest court (Supreme Court), list the court that heard the case here. Use the appropriate abbreviations as suggested by "The Bluebook."',
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.court,
            callback: function ($$v) {
              _vm.court = $$v
            },
            expression: "court",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Year",
            "help-text": "List the year the decision was passed.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL (Web address)",
            "help-text": "Include the URL for the work if available.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }