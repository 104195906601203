var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Convention or Treaty Title",
            "help-text":
              "Enter the full name of the convention or treaty exactly as it appears.",
            "is-autosize": true,
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Parties / Countries Subject to the Treaty",
            "help-text":
              "Unless the treaty title includes the subjected parties, list the parties or countries subject to the treaty below, separated by a hyphen (e.g., Denmark-Italy-France). List the countries in the order they appear in the treaty.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.parties,
            callback: function ($$v) {
              _vm.parties = $$v
            },
            expression: "parties",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Signing or Approval Date",
            "help-text":
              "Enter the date the convention or treaty was signed or approved.",
            "is-all-common-use": true,
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Published Location Source",
            "help-text":
              'Type the abbreviation for the published source of the treaty (e.g., "UNTS" for the United Nations Treaty Series).',
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.source,
            callback: function ($$v) {
              _vm.source = $$v
            },
            expression: "source",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Published Location Numbers",
            "help-text":
              'If applicable, enter the numbers for the volume, parts, or section of the publication where the treaty is published (e.g., "14, pt. 2").',
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.location,
            callback: function ($$v) {
              _vm.location = $$v
            },
            expression: "location",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL",
            "help-text": "Type the full url, if applicable.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }