<!--
- Contributor
-- 2024.08.05
-->

<template>
	<div>
		<div v-if="
			$store.state.referenceRules.referenceTypeId === 23 && 
			$store.state.referenceRules.formData.kind === 'named'
			">
			<h3>Person Interviewed</h3>
		</div>

		<div v-else-if="
			$store.state.referenceRules.referenceTypeId === 23 && 
			$store.state.referenceRules.formData.kind === 'anonymous'
			">
			<h3>Description of Person(s) Interviewed</h3>
			<p class="help-text mb-2">
				Describe the interview (e.g., Interview with a nurse).
			</p>
			<div class="help-text mb-2 text-danger">
				An explanation for why names are withheld should be added in a separate note or in the preface of your paper.
			</div>
		</div>
		
		<div v-else>
			<h3>Contributors</h3>

			<p class="help-text" v-if="referenceFormatTypeId === $enums.Format.MLA9" key="pHelpMLA9">
				Any contributor type can be the primary contributor for an MLA 9 reference. Re-order contributors below to change the contributor type listed at the beginning of the reference.
			</p>
			<p class="help-text" v-else key="pHelpElse">
				List the contributors in the order they are listed in the work. For multiple contributors, create a contributor entry for each.
			</p>
		</div>

		<contributor-block
			v-for="(contributor,index) in $store.state.referenceRules.formData.contributors"
			:all-contributor-types="listOfContributorTypes"
			:contributor-label="contributorLabel"
			:contributor-index="index"
			:is-add-another="isAddAnother"
			:is-book-chapter="isBookChapter"
			:key="contributor.id"
			:used-contributor-types="usedContributorTypes"
			@remove-contributor-block="removeContributorBlock" />
		
		<div class="d-grid" v-if="isAddAnother">
			<button class="btn btn-hover btn-hover-success" @click.prevent="addContributorBlock" type="button">
				<span class="svg bi-plus-lg" /> Add contributor
			</button>
		</div>
	</div>
</template>

<script>
import config from '@/config';
import ContributorBlock from './_ContributorBlock';
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';
import { v4 as uuidv4 } from 'uuid';

export default {
	name: 'ContributorComponent',
	props:{
		contributorLabel:{
			default: 'Contributor Name',
			type: String,
		},
		isAddAnother:{
			default: true,
			type: Boolean,			
		},
		isBookChapter:{
			default: false,
			type: Boolean,
		},
		usedContributorTypes: {
			type: Array,
		}
	},
	computed:{
		listOfContributorTypes(){
			let _returnList = [];

			// loop trough used contributor types
			this.usedContributorTypes.forEach((contributorValue)=>{
				// console.log('contributorValue');
				// console.log(contributorValue);

				let typeDisplay = '';
				let helpText = 'If the contributor is known by a single name, type it in the Last name field. Do not include credentials or degrees.';

				// alot of types get passed as Author but the display should change based on ref type
				switch(contributorValue){
					// 
					case 'anonymous':
						typeDisplay = 'anonymous';
						break;
					case 'author':
						if(this.$store.state.referenceRules.referenceTypeId === 9 || this.$store.state.referenceRules.referenceTypeId === 28){ // Artwork or Music
							typeDisplay = 'Artist';
						} else if(this.$store.state.referenceRules.referenceTypeId === 13){	// Conference & Symposium Materials 
							typeDisplay = 'Author / Presenter';
						} else if(this.$store.state.referenceRules.referenceTypeId === 17){ // Executive Orders MLA9
							typeDisplay = 'President';
						} else if(this.$store.state.referenceRules.referenceTypeId === 19){ // Films
							typeDisplay = 'Director';
						} else if(this.$store.state.referenceRules.referenceTypeId === 21){ // Grant
							typeDisplay = 'Principal Investigator';
						} else if(
							this.$store.state.referenceRules.referenceTypeId === 23 && // Interview with specific cases
							this.$store.state.referenceRules.formData.kind === 'named'
						){	
							typeDisplay = 'Person Interviewed';
						} else if(this.$store.state.referenceRules.referenceTypeId === 25){ // Lectures
							typeDisplay = 'Presenter';
						} else if(this.$store.state.referenceRules.referenceTypeId === 33){	// Personal Communication 
							typeDisplay = 'Personal Source';
						} else if(
							this.$store.state.referenceRules.referenceTypeId === 36 &&	// Reference Work
							this.$store.state.referenceRules.formData.type === 'entry'
						){
							typeDisplay = 'Author of the entry';
						} else if(
							this.$store.state.referenceRules.referenceTypeId === 36 &&	// Reference Work
							this.$store.state.referenceRules.formData.type === 'whole'
						){
							typeDisplay = 'Author of the whole work';
						} else if(this.$store.state.referenceRules.referenceTypeId === 38){ // Reviews
							typeDisplay = 'Reviewer';
						} else if(this.$store.state.referenceRules.referenceTypeId === 41){ // Speech
							typeDisplay = 'Speaker';
						} else if(this.$store.state.referenceRules.referenceTypeId === 47){ // Videos
							typeDisplay = 'Individual';
						} else {
							typeDisplay = 'Author';
						}
						break;
						
					case 'chapterAuthor':
						typeDisplay = 'Chapter Author';
						break;
						
					case 'chapterTranslator':
						typeDisplay = 'Translator (Chapter)';
						break;
					case 'chair':
						typeDisplay = 'Symposium Chairperson';
						break;
					case 'custom':
						typeDisplay = 'Custom (No Director)';
						break;
					case 'director':	// this is for a TV show (not a film)
						typeDisplay = 'Director';
						break;
					case 'editor':
						if(
							this.$store.state.referenceRules.referenceTypeId === 36 &&	// Reference Work
							this.$store.state.referenceRules.formData.type === 'entry'
						){
							typeDisplay = 'Editor for the entry';
						} else if(
							this.$store.state.referenceRules.referenceTypeId === 36 &&	// Reference Work
							this.$store.state.referenceRules.formData.type === 'whole'
						){
							typeDisplay = 'Editor for the whole work';
						} else if(
							this.$store.state.referenceRules.referenceTypeId === 23 && // Interview with specific cases
							this.$store.state.referenceRules.formData.kind === 'named'
						){
							typeDisplay = 'Interviewer';	
						} else {
							typeDisplay = 'Editor';
						}
						break;
					case 'executiveDirector':
						typeDisplay = 'Executive Director';
						break;
					case 'executiveDirectorProducer':
						typeDisplay = 'Executive Director & Producer';
						break;
					case 'executiveProducer':
						typeDisplay = 'Executive Producer';
						break;
					case 'groupAuthor':
						if(this.$store.state.referenceRules.referenceTypeId === 8){
							// Apparatus
							typeDisplay = 'Company';
						} else if(this.$store.state.referenceRules.referenceTypeId === 9 || this.$store.state.referenceRules.referenceTypeId === 28){
							// Artwork or Music
							typeDisplay = 'Group Artist';
						} else if(this.$store.state.referenceRules.referenceTypeId === 20){
							// Government Reports
							if(this.$store.state.referenceRules.formData.type === 'report') {
								typeDisplay = 'Committee Name';	// reports
								helpText = 'Type the name of the Congressional Committee responsible for the report (e.g., Committee for Sasquatch Awareness).';
							} else {
								typeDisplay = 'Department';		// regulation
								helpText = 'Type the name of the Department responsible for the regulation.';
							}
						} else if(this.$store.state.referenceRules.referenceTypeId === 21){
							// Grant
							typeDisplay = 'Group Investigator';
						} else if(this.$store.state.referenceRules.referenceTypeId === 25){
							// Lectures
							typeDisplay = 'Group Presenter (organization)';
						} else if(this.$store.state.referenceRules.referenceTypeId === 30){
							// Online Comment
							typeDisplay = 'Organizational Commenter w/ username';
						} else if(
							this.$store.state.referenceRules.referenceTypeId === 36 &&	// Reference Work
							this.$store.state.referenceRules.formData.type === 'entry'
						){
							typeDisplay = 'Group Author (Organization) for the entry';
						} else if(
							this.$store.state.referenceRules.referenceTypeId === 36 &&	// Reference Work
							this.$store.state.referenceRules.formData.type === 'whole'
						){
							typeDisplay = 'Group Author (Organization) for the whole work';
						} else if(this.$store.state.referenceRules.referenceTypeId === 47){
							// Videos
							typeDisplay = 'Group or Organization';
							helpText = 'Type the organization name exactly as it appears in the work.';
						} else {
							typeDisplay = 'Group Author (Organization)';
							helpText = 'Type the organization name exactly as it appears in the work.';
						}
						break;
					case 'groupPerformer':
						typeDisplay = 'Group Performer';
						break;
					case 'host':
						if(
							this.$store.state.referenceRules.referenceTypeId === 34 &&	// Podcasts & Radio
							this.$store.state.referenceRules.formData.type === 'interview'
						) {
							typeDisplay = 'Host or Interviewer';
						} else {
							typeDisplay = 'Host';
						}
						
						break;
					case 'illustrator':
						typeDisplay = 'Illustrator';
						break;
					case 'interview':
						typeDisplay = 'Interviewee';
						break;
					case 'narrator':
						typeDisplay = 'Narrator';
						break;
					case 'originalAuthor':
						typeDisplay = 'Author of the Original Work';
						break;
					case 'originalEditor':
						typeDisplay = 'Original Editor';
						break;
					case 'performer':
						typeDisplay = 'Performer';
						break;
					case 'reviewArtist':
						typeDisplay = 'Artist or Composer of Reviewed Work';
						break;
					case 'reviewAuthor':
						typeDisplay = 'Author of Reviewed Work';
						break;
					case 'reviewComposer':
						typeDisplay = 'Custom Role for Reviewed Work';
						break;
					case 'reviewDirector':
						typeDisplay = 'Director of Reviewed Work';
						break;
					case 'reviewEditor':
						typeDisplay = 'Editor of Reviewed Work';
						break;
					case 'reviewGroupArtist':
						typeDisplay = 'Group Artist for Reviewed Work';
						break;
					case 'reviewWriter':
						typeDisplay = 'Writer of Reviewed Work';
						break;
					case 'reviewWriterDirector':
						typeDisplay = 'Writer & Director of Reviewed Work';
						break;
					case 'testimony':
						typeDisplay = 'Witness';
						break;
					case 'translator':
						typeDisplay = 'Translator';	// change this to "Book Translator" if it's a Whole Book
						break;
					case 'userName':
						if(this.$store.state.referenceRules.referenceTypeId === 30){
							// Online Comment
							typeDisplay = 'Individual Commenter w/ username';
						} else if(
							this.$store.state.referenceRules.referenceTypeId === 36 &&	// Reference Work
							this.$store.state.referenceRules.formData.type === 'entry'
						){
							typeDisplay = 'Username for the author of the entry';
						} else if(
							this.$store.state.referenceRules.referenceTypeId === 36 &&	// Reference Work
							this.$store.state.referenceRules.formData.type === 'whole'
						){
							typeDisplay = 'Username for the author of the whole work';
						} else {
							typeDisplay = 'Username (screen name)';
						}
						break;
					case 'writer':
						typeDisplay = 'Writer';
						break;
					case 'writerDirector':
						typeDisplay = 'Writer & Director';
						break;
					default:
						if(!config.isLive){
							alert('contributor type missing');
						}
						break;
				}
				
				_returnList.push({
					display: typeDisplay,
					helpText: helpText,
					value: contributorValue
				});
			});//e:forEach

			return _returnList;
		},
		referenceFormatTypeId(){
			return HelperGetReferenceFormatId();
		},
	},
	methods:{
		addContributorBlock(){
			this.$store.commit('referenceRules/PUSH_TO_CONTRIBUTORS', {
				abbreviation:'',
				firstName:'',
				groupName:'',
				lastName:'',
				middleName:'',
				name: '',
				prefix: '',
				suffix:'',
				type: this.usedContributorTypes[0], // will always default to the first used contributor type
				id: uuidv4().toUpperCase(),
			});
			
			// pass the next contributor item
			this.$nextTick(()=>{
				this.emitter.emit('focusFirstContributorField', this.$store.state.referenceRules.formData.contributors.length - 1);
			});
		},

		removeContributorBlock(contributorIndex){
			this.$store.commit('referenceRules/REMOVE_CONTRIBUTOR_AT_INDEX',{
				contributorIndex,
			});

			this.$store.dispatch('referenceRules/generateReferencePreview');
		},
	},
	components: {
		ContributorBlock
	}
}
</script>
