<!--
- Blog
-- Reference Type Id: 11
-- 2024.02.09
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Entry"
								value="entry"
								v-model="type"
								@change="typeChange" /> 
								
							<label class="form-check-label" for="RefWiz_Type_Entry">
								A blog entry
								<!-- <span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="This is for a single article that is published in a journal. It includes both print and digital copies of journal articles." /> -->
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Blog"
								value="blog"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Blog">
								An entire blog
								<!-- <span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									title="Use this reference type for articles that can only be found in a database (e.g., UpToDate, Cochrane Database, etc.)."
									role="tooltip" /> -->
							</label>
						</div>
					</div>
				</div>

			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>
	
		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				v-show="type === 'entry'"
				label="Blog Entry Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="entryTitle" />

			<form-textarea-reference-wizard
				label="Blog Name"
				help-text="Type the name of the blog  exactly as it appears on the website."
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="blogTitle" />

			<form-datepicker
				v-show="type === 'entry'"
				id="RefWiz_PublicationDate" 
				label="Publication Date"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />

			<control-publishers-v2
				label="Blog Publisher"
				help-text-prop="If the blog is published by a larger organization, add the name of the publisher exactly as it appears (e.g., New York Times)."
				field-type="reference"
				:is-show-city-state="false"
				:is-single-only="true" />

		</section>

		<section class="mb-5">
			<h3>Digital Information</h3>

			<form-input-reference-wizard
				label="URL (Web address)"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

			<form-datepicker
				help-text="If the work may change in the future, enter the date you accessed the work for your research."
				id="RefWiz_RetrievalDate" 
				label="Retrieval Date"
				:is-today-button="true"
				:paper-format="0"
				@date-set="dateSetRetrieval"
				@date-clear="dateClearRetrieval" />
		</section>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeBlog',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			// Author
			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});

			// Group Author
			this.addContributorRole({
				type: 'groupAuthor',
			});

			// User Name
			this.addContributorRole({
				type: 'userName',
			});
			

			if(!this.isRefRulesEmpty) {
				// Load Based on previously entered data in Step 3 (or an Edit)
				
				// Update Used Contributor Types
				this.usedContributorTypes = ['author','groupAuthor','userName'];
				
			}//e:if:isRefRulesEmpty

			this.sortContributorTypes();

		},//e:calcContributors

		dateClearRetrieval(){
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'retrievalDate',
				value: ''
			});
			this.generateReferencePreview();
		},
		dateSetRetrieval(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'retrievalDate',
					value: newDate
				});
				this.generateReferencePreview();
			}
		},
		typeChange(){
			// changing radio button 'type' should reselect the first option in the 'kind' dropdown
			// if(this.type === 'special'){
			// 	this.kind = 'issue';
			// } else {
			// 	this.kind = 'article';
			// }

			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted(){
		if(this.type === ''){
			this.type = 'entry';
		}
		this.$nextTick(()=>{
			this.calcContributors();
		});
	},
}
</script>