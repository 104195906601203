<!--
- Television
-- Reference Type Id: 43
-- 2024.06.14
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Episode"
								value="episode"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Episode">
								Single episode
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Series"
								value="series"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Series">
								An entire television series
							</label>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Series Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-textarea-reference-wizard
				v-if="type === 'episode'"
				label="Episode Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="episodeTitle" />
			
			<form-input-reference-wizard
				v-show="type === 'series'"
				label="Years the Series Aired"
				help-text="Type the years that the series first aired. If the show is still being produced, the second year should be &quot;present&quot;."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-datepicker
				v-show="type === 'episode'"
				id="RefWiz_PublicationDate" 
				label="Episode Date"
				help-text="Include the date the episode first aired."
				:is-all-common-use="true"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />

			<form-input-reference-wizard
				v-show="type === 'episode'"
				label="Season Number"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="seasonNumber" />

			<form-input-reference-wizard
				v-show="type === 'episode'"
				label="Episode Number"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="episodeNumber" />

			<form-input-reference-wizard
				label="Where did the show originally air?"
				help-text="Enter the name of the channel or platform where the show originally aired (e.g., PBS, Netflix)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="channel" />

			<control-publishers-v2
				:is-show-city-state="false"
				label="Production Company"
				field-type="reference" /> 

			<form-input-reference-wizard
				label="Streaming Service"
				help-text="If you watched the show on a streaming service different than its original channel, enter it here (e.g., Hulu)."
				@generate-reference-preview="generateReferencePreview"
				v-model="platform" />
		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeTelevision',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			this.addContributorRole({
				type: 'custom',
			});

			this.addContributorRole({
				type: 'executiveDirector',
			});

			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind
				this.addContributorRole({
					type: 'executiveDirectorProducer',
				});

				if(this.type === 'episode'){
					this.addContributorRole({
						addDefault: true,
						type: 'director',
					});

					this.addContributorRole({
						type: 'executiveProducer',
					});

					this.addContributorRole({
						addDefault: true,
						type: 'writer',
					});

					this.addContributorRole({
						type: 'writerDirector',
					});
				} else if(this.type === 'series'){

					this.addContributorRole({
						addDefault: true,
						type: 'executiveProducer',
					});
				}

			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)
				// Update Used Contributor Types
				this.usedContributorTypes = ['custom','executiveDirector','director', 'executiveProducer', 'writer', 'writerDirector', 'executiveDirectorProducer'];
				
				this.$nextTick(()=>{
					if(this.kind === 'translated') {
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					} else {
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);
					}
				});
			}//e:if:isRefRulesEmpty

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
	mounted(){
		if(this.type === ''){
			this.type = 'episode';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>