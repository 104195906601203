var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", [_vm._v(_vm._s(_vm.label))]),
    _vm._v(" "),
    _vm.selectedOptionHelpText
      ? _c("div", {
          staticClass: "help-text mb-1",
          domProps: { innerHTML: _vm._s(_vm.selectedOptionHelpText) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "input-group" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedOption,
                expression: "selectedOption",
              },
            ],
            staticClass: "form-select",
            attrs: { "aria-label": _vm.label },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedOption = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          _vm._l(_vm.calcOptions, function (option, optionKey) {
            return _c(
              "option",
              { key: optionKey, domProps: { value: optionKey } },
              [_vm._v("\n\t\t\t\t\t\t" + _vm._s(option.display) + "\n\t\t\t\t")]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedOptionValue,
            expression: "selectedOptionValue",
          },
          {
            name: "debounce",
            rawName: "v-debounce:750ms",
            value: _vm.debouncedGenerateReference,
            expression: "debouncedGenerateReference",
            arg: "750ms",
          },
        ],
        class: [
          "form-control",
          { "form-control-primary": _vm.isCommonUseShow },
        ],
        attrs: { autocomplete: "off", type: "text", "aria-label": _vm.label },
        domProps: { value: _vm.selectedOptionValue },
        on: {
          blur: _vm.blurInput,
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.selectedOptionValue = $event.target.value
            },
            function ($event) {
              return _vm.typeInput($event.target.value)
            },
          ],
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }