var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isThirdPartyLookUp
        ? _c("reference-type-switcher", {
            attrs: { "original-reference-type-id": "1" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "mb-5" }, [
        _c("h3", [_vm._v("Format Options")]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group mb-0 py-2" }, [
          _c("label", [_vm._v("Choose a type")]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.type,
                      expression: "type",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "radio",
                    id: "RefWiz_Type_Journal",
                    value: "journal",
                  },
                  domProps: { checked: _vm._q(_vm.type, "journal") },
                  on: {
                    change: [
                      function ($event) {
                        _vm.type = "journal"
                      },
                      _vm.typeChange,
                    ],
                  },
                }),
                _vm._v(" "),
                _vm._m(0),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.type,
                      expression: "type",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "radio",
                    id: "RefWiz_Type_Database",
                    value: "database",
                  },
                  domProps: { checked: _vm._q(_vm.type, "database") },
                  on: {
                    change: [
                      function ($event) {
                        _vm.type = "database"
                      },
                      _vm.typeChange,
                    ],
                  },
                }),
                _vm._v(" "),
                _vm._m(1),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.type,
                      expression: "type",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "radio",
                    id: "RefWiz_Type_Reprinted",
                    value: "reprinted",
                  },
                  domProps: { checked: _vm._q(_vm.type, "reprinted") },
                  on: {
                    change: [
                      function ($event) {
                        _vm.type = "reprinted"
                      },
                      _vm.typeChange,
                    ],
                  },
                }),
                _vm._v(" "),
                _vm._m(2),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.type,
                      expression: "type",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "radio",
                    id: "RefWiz_Type_Special",
                    value: "special",
                  },
                  domProps: { checked: _vm._q(_vm.type, "special") },
                  on: {
                    change: [
                      function ($event) {
                        _vm.type = "special"
                      },
                      _vm.typeChange,
                    ],
                  },
                }),
                _vm._v(" "),
                _vm._m(3),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "journal",
                expression: "type == 'journal'",
              },
            ],
            key: "divKindJournal",
            staticClass: "form-group mb-0 py-2",
          },
          [
            _c("label", [_vm._v("What kind of article are you referencing?")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.kind,
                    expression: "kind",
                  },
                ],
                staticClass: "form-select",
                attrs: {
                  "aria-label": "What kind of article are you referencing?",
                },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.kind = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.calcContributors,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "article" } }, [
                  _vm._v("\n\t\t\t\t\tA single published article\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "foreign" } }, [
                  _vm._v(
                    "\n\t\t\t\t\tForeign language or translated article\n\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "advance" } }, [
                  _vm._v("\n\t\t\t\t\tAdvance online publication\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "inPress" } }, [
                  _vm._v("\n\t\t\t\t\tIn-press article\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "editorial" } }, [
                  _vm._v("\n\t\t\t\t\tEditorial\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "retracted" } }, [
                  _vm._v("\n\t\t\t\t\tA single retracted article\n\t\t\t\t"),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "database",
                expression: "type == 'database'",
              },
            ],
            key: "divKindDatabase",
            staticClass: "form-group mb-0 py-2",
          },
          [
            _c("label", [
              _vm._v("What kind of database article are you referencing?"),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.kind,
                    expression: "kind",
                  },
                ],
                staticClass: "form-select",
                attrs: {
                  "aria-label":
                    "What kind of database article are you referencing?",
                },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.kind = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.calcContributors,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "article" } }, [
                  _vm._v("\n\t\t\t\t\tA single article\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "foreign" } }, [
                  _vm._v(
                    "\n\t\t\t\t\tForeign language or translated article\n\t\t\t\t"
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "reprinted",
                expression: "type == 'reprinted'",
              },
            ],
            key: "divKindReprinted",
            staticClass: "form-group mb-0 py-2",
          },
          [
            _c("label", [
              _vm._v("What kind of republished article are you referencing?"),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.kind,
                    expression: "kind",
                  },
                ],
                staticClass: "form-select",
                attrs: {
                  "aria-label":
                    "What kind of republished article are you referencing?",
                },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.kind = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.calcContributors,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "article" } }, [
                  _vm._v("\n\t\t\t\t\tA single article\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "foreign" } }, [
                  _vm._v(
                    "\n\t\t\t\t\tForeign language or translated article\n\t\t\t\t"
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "special",
                expression: "type == 'special'",
              },
            ],
            key: "divKindSpecial",
            staticClass: "form-group mb-0 py-2",
          },
          [
            _c("label", [
              _vm._v("What part or kind of special issue are you referencing?"),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.kind,
                    expression: "kind",
                  },
                ],
                staticClass: "form-select",
                attrs: {
                  "aria-label":
                    "What part or kind of special issue are you referencing?",
                },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.kind = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.calcContributors,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "issue" } }, [
                  _vm._v("\n\t\t\t\t\tEntire special issue\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "section" } }, [
                  _vm._v("\n\t\t\t\t\tSpecial section\n\t\t\t\t"),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "mb-5" },
        [
          _c("control-contributor", {
            attrs: {
              "contributors-prop": _vm.contributors,
              "used-contributor-types": _vm.usedContributorTypes,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "mb-5" },
        [
          _c("h3", [_vm._v("Publication Information")]),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type !== "special",
                expression: "type !== 'special'",
              },
            ],
            attrs: {
              label: "Article Title",
              "help-text": _vm.calcTitleHelpText,
              "is-autosize": true,
              "is-common-use": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.articleTitle,
              callback: function ($$v) {
                _vm.articleTitle = $$v
              },
              expression: "articleTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showTranslatorFields && _vm.type !== "special",
                expression: "showTranslatorFields && type !== 'special'",
              },
            ],
            attrs: {
              label: "Translated Article Title",
              "help-text":
                "If the original article title is provide in another language, type the translated title.",
              "is-autosize": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.translatedArticleTitle,
              callback: function ($$v) {
                _vm.translatedArticleTitle = $$v
              },
              expression: "translatedArticleTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type === "special" && _vm.kind === "issue",
                expression: "type === 'special' && kind === 'issue'",
              },
            ],
            attrs: {
              label: "Issue Title",
              "help-text": _vm.calcTitleHelpText,
              "is-autosize": true,
              "is-common-use": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.issueTitle,
              callback: function ($$v) {
                _vm.issueTitle = $$v
              },
              expression: "issueTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type === "special" && _vm.kind === "section",
                expression: "type === 'special' && kind === 'section'",
              },
            ],
            attrs: {
              label: "Section Title",
              "help-text": _vm.calcTitleHelpText,
              "is-autosize": true,
              "is-common-use": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.sectionTitle,
              callback: function ($$v) {
                _vm.sectionTitle = $$v
              },
              expression: "sectionTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showTranslatorFields && _vm.type === "special",
                expression: "showTranslatorFields && type === 'special'",
              },
            ],
            attrs: {
              label: "Translated Issue/Section Title",
              "help-text":
                "If the original title is provide in another language, type the translated title.",
              "is-autosize": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.translatedIssueTitle,
              callback: function ($$v) {
                _vm.translatedIssueTitle = $$v
              },
              expression: "translatedIssueTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type != "database",
                expression: "type != 'database'",
              },
            ],
            attrs: {
              label: "Journal Name",
              "help-text":
                "Type the full title of the journal, exactly as it appears in the publication.",
              "is-autosize": true,
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.journalTitle,
              callback: function ($$v) {
                _vm.journalTitle = $$v
              },
              expression: "journalTitle",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "database",
                expression: "type == 'database'",
              },
            ],
            attrs: {
              label: "Database Name",
              "help-text":
                "Type the full title of the database, exactly as it appears on the publication.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.databaseTitle,
              callback: function ($$v) {
                _vm.databaseTitle = $$v
              },
              expression: "databaseTitle",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.kind !== "inPress",
                expression: "kind !== 'inPress'",
              },
            ],
            attrs: {
              label: "Publication Year",
              "help-text": "List the year the work was published.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.publicationDate,
              callback: function ($$v) {
                _vm.publicationDate = $$v
              },
              expression: "publicationDate",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.kind !== "advance" &&
                  _vm.kind !== "inPress" &&
                  _vm.type != "database",
                expression:
                  "kind !== 'advance' && kind !== 'inPress' && type != 'database'",
              },
            ],
            attrs: {
              label: "Volume Number",
              "help-text": "Use numbers for the volume number.",
              "is-common-use":
                _vm.referenceFormatTypeId == _vm.$enums.Format.APA7 ||
                _vm.referenceFormatTypeId == _vm.$enums.Format.MLA9
                  ? true
                  : false,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.volume,
              callback: function ($$v) {
                _vm.volume = $$v
              },
              expression: "volume",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.kind !== "advance" &&
                  _vm.kind !== "inPress" &&
                  _vm.type != "database",
                expression:
                  "kind !== 'advance' && kind !== 'inPress' && type != 'database'",
              },
            ],
            attrs: {
              label: "Issue Number",
              "help-text": "Use numbers for the issue number.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.issue,
              callback: function ($$v) {
                _vm.issue = $$v
              },
              expression: "issue",
            },
          }),
          _vm._v(" "),
          _c("control-toggle-select", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.kind !== "advance" &&
                  _vm.kind !== "inPress" &&
                  _vm.kind != "issue" &&
                  _vm.type != "database",
                expression:
                  "kind !== 'advance' && kind !== 'inPress' && kind != 'issue' && type != 'database'",
              },
            ],
            attrs: {
              "is-common-use": _vm.toggleSelect.pagesArticle.isCommonUse,
              label: _vm.toggleSelect.pagesArticle.label,
              options: _vm.toggleSelect.pagesArticle.options,
              "group-name": "pagesArticle",
            },
          }),
          _vm._v(" "),
          _c("control-toggle-select", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type !== "special",
                expression: "type !== 'special'",
              },
            ],
            attrs: {
              "is-common-use": _vm.toggleSelect.doiUrl.isCommonUse,
              label: _vm.toggleSelect.doiUrl.label,
              options: _vm.toggleSelect.doiUrl.options,
              "group-name": "doiUrl",
            },
          }),
          _vm._v(" "),
          _c("form-datepicker", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.kind !== "advance" && _vm.type !== "special",
                expression: "kind !== 'advance' && type !== 'special'",
              },
            ],
            attrs: {
              "help-text":
                "Only include if your article is in a changing database like UpToDate.",
              id: "RefWiz_RetrievalDate",
              label: "Retrieval Date",
              "is-today-button": true,
              "paper-format": 0,
            },
            on: {
              "date-set": _vm.dateSetRetrieval,
              "date-clear": _vm.dateClearRetrieval,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind == "retracted",
              expression: "kind == 'retracted'",
            },
          ],
          staticClass: "mb-5",
        },
        [
          _c("h3", [_vm._v("Retraction Information")]),
          _vm._v(" "),
          _c("form-datepicker", {
            attrs: {
              "help-text":
                "Only include if your article is in a changing database like UpToDate.",
              id: "RefWiz_RetractionDate",
              label: "Retraction Date",
              "paper-format": 0,
              "is-common-use": true,
            },
            on: {
              "date-set": _vm.dateSetRetraction,
              "date-clear": _vm.dateClearRetraction,
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            attrs: {
              label: "Retraction Volume Number",
              "help-text": "Use numbers for the volume number.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.retractionVolume,
              callback: function ($$v) {
                _vm.retractionVolume = $$v
              },
              expression: "retractionVolume",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            attrs: {
              label: "Retraction Issue Number",
              "help-text": "Use numbers for the issue number.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.retractionIssue,
              callback: function ($$v) {
                _vm.retractionIssue = $$v
              },
              expression: "retractionIssue",
            },
          }),
          _vm._v(" "),
          _c("control-toggle-select", {
            attrs: {
              label: _vm.toggleSelect.retractionPagesArticle.label,
              "is-common-use":
                _vm.toggleSelect.retractionPagesArticle.isCommonUse,
              options: _vm.toggleSelect.retractionPagesArticle.options,
              "group-name": "retractionPagesArticle",
            },
          }),
          _vm._v(" "),
          _c("control-toggle-select", {
            attrs: {
              label: _vm.toggleSelect.retractionDoiUrl.label,
              "is-common-use": _vm.toggleSelect.retractionDoiUrl.isCommonUse,
              options: _vm.toggleSelect.retractionDoiUrl.options,
              "group-name": "retractionDoiUrl",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "reprinted" || _vm.kind == "foreign",
              expression: "type == 'reprinted' || kind == 'foreign'",
            },
          ],
          staticClass: "mb-5",
        },
        [
          _c("h3", [_vm._v("Original Publication Information")]),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "reprinted",
                expression: "type == 'reprinted'",
              },
            ],
            attrs: {
              label: "Original Article Title",
              "help-text": _vm.calcTitleHelpText,
              "is-autosize": true,
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalArticleTitle,
              callback: function ($$v) {
                _vm.originalArticleTitle = $$v
              },
              expression: "originalArticleTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "reprinted",
                expression: "type == 'reprinted'",
              },
            ],
            attrs: {
              label: "Original Journal Name",
              "help-text":
                "Type the full title of the journal, exactly as it appears in the publication.",
              "is-autosize": true,
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalJournalTitle,
              callback: function ($$v) {
                _vm.originalJournalTitle = $$v
              },
              expression: "originalJournalTitle",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            attrs: {
              label: "Original Publication Year",
              "help-text": "List the year the work was originally published.",
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalPublicationDate,
              callback: function ($$v) {
                _vm.originalPublicationDate = $$v
              },
              expression: "originalPublicationDate",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "reprinted",
                expression: "type == 'reprinted'",
              },
            ],
            attrs: {
              label: "Original Volume Number",
              "help-text": "Use numbers for the volume number.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalVolume,
              callback: function ($$v) {
                _vm.originalVolume = $$v
              },
              expression: "originalVolume",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "reprinted",
                expression: "type == 'reprinted'",
              },
            ],
            attrs: {
              label: "Original Issue Number",
              "help-text": "Use numbers for the issue number.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalIssue,
              callback: function ($$v) {
                _vm.originalIssue = $$v
              },
              expression: "originalIssue",
            },
          }),
          _vm._v(" "),
          _c("control-toggle-select", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "reprinted",
                expression: "type == 'reprinted'",
              },
            ],
            attrs: {
              label: _vm.toggleSelect.originalPagesArticle.label,
              "is-common-use":
                _vm.toggleSelect.originalPagesArticle.isCommonUse,
              options: _vm.toggleSelect.originalPagesArticle.options,
              "group-name": "originalPagesArticle",
            },
          }),
          _vm._v(" "),
          _c("control-toggle-select", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "reprinted",
                expression: "type == 'reprinted'",
              },
            ],
            attrs: {
              label: _vm.toggleSelect.originalDoiUrl.label,
              "is-common-use": _vm.toggleSelect.originalDoiUrl.isCommonUse,
              options: _vm.toggleSelect.originalDoiUrl.options,
              "group-name": "originalDoiUrl",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Journal" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tJournal article\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "This is for a single article that is published in a journal. It includes both print and digital copies of journal articles.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Database" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tAn article in a database\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "Use this reference type for articles that can only be found in a database (e.g., UpToDate, Cochrane Database, etc.).",
            role: "tooltip",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Reprinted" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tRepublished journal article\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "This reference type is for journal articles that have been reprinted or republished more than once.  They include both the original date of publication and the date of the version you are citing.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Special" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tSpecial issue or section\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "You can use this reference type if you are citing an entire section or issue of a journal.  If you are only citing one article in a special issue, select Journal Article instead.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }