var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isThirdPartyLookUp
        ? _c("reference-type-switcher", {
            attrs: { "original-reference-type-id": "2" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "mb-5" }, [
        _c("h3", [_vm._v("Format Options")]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group mb-0 py-2" }, [
          _c("label", [_vm._v("Choose a type")]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.type,
                      expression: "type",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "radio",
                    id: "RefWiz_Radio_Book",
                    value: "book",
                  },
                  domProps: { checked: _vm._q(_vm.type, "book") },
                  on: {
                    change: [
                      function ($event) {
                        _vm.type = "book"
                      },
                      _vm.typeChange,
                    ],
                  },
                }),
                _vm._v(" "),
                _vm._m(0),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.type,
                      expression: "type",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "radio",
                    id: "RefWiz_Radio_BookChapter",
                    value: "bookChapter",
                  },
                  domProps: { checked: _vm._q(_vm.type, "bookChapter") },
                  on: {
                    change: [
                      function ($event) {
                        _vm.type = "bookChapter"
                      },
                      _vm.typeChange,
                    ],
                  },
                }),
                _vm._v(" "),
                _vm._m(1),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type === "book",
                expression: "type === 'book'",
              },
            ],
            key: "divKindBook",
            staticClass: "form-group mb-0 py-2",
          },
          [
            _c("label", [
              _vm._v(
                "What kind of book, e-book, or audiobook are you referencing?"
              ),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.kind,
                    expression: "kind",
                  },
                ],
                staticClass: "form-select",
                attrs: {
                  "aria-label":
                    "What kind of book, e-book, or audiobook are you referencing?",
                },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.kind = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.calcContributors,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "whole" } }, [
                  _vm._v("\n\t\t\t\t\tAll or part of a book\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "translated" } }, [
                  _vm._v(
                    "\n\t\t\t\t\tForeign language or translated book\n\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "republished" } }, [
                  _vm._v("\n\t\t\t\t\tRepublished book\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "multivolume" } }, [
                  _vm._v("\n\t\t\t\t\tVolume in a series\n\t\t\t\t"),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type === "bookChapter",
                expression: "type === 'bookChapter'",
              },
            ],
            key: "divKindBookChapter",
            staticClass: "form-group mb-0 py-2",
          },
          [
            _c("label", [_vm._v("What kind of chapter are you referencing?")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.kind,
                    expression: "kind",
                  },
                ],
                staticClass: "form-select",
                attrs: {
                  "aria-label": "What kind of chapter are you referencing?",
                },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.kind = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.calcContributors,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "whole" } }, [
                  _vm._v("\n\t\t\t\t\tAll or part of a book chapter\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "translated" } }, [
                  _vm._v(
                    "\n\t\t\t\t\tForeign language or translated chapter\n\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "book" } }, [
                  _vm._v(
                    "\n\t\t\t\t\tRepublished chapter, originally from a book\n\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "journal" } }, [
                  _vm._v(
                    "\n\t\t\t\t\tRepublished chapter, originally from a journal\n\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "multivolume" } }, [
                  _vm._v(
                    "\n\t\t\t\t\tChapter from a volume in a series\n\t\t\t\t"
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "mb-5" },
        [
          _c("control-contributor", {
            attrs: {
              "used-contributor-types": _vm.usedContributorTypes,
              "is-book-chapter": _vm.type === "bookChapter",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "mb-5" },
        [
          _c("h3", [_vm._v("Book Publication Information")]),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type === "bookChapter",
                expression: "type === 'bookChapter'",
              },
            ],
            attrs: {
              label: "Chapter Title",
              "help-text": _vm.calcTitleHelpText,
              "is-autosize": true,
              "is-common-use": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.chapterTitle,
              callback: function ($$v) {
                _vm.chapterTitle = $$v
              },
              expression: "chapterTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.showTranslatorFields &&
                  _vm.type === "bookChapter" &&
                  _vm.kind != "multivolume",
                expression:
                  "showTranslatorFields && type === 'bookChapter' && kind != 'multivolume'",
              },
            ],
            attrs: {
              label: "Translated Chapter Title",
              "help-text":
                "If the original chapter title is provided in another language, type the translated title.",
              "is-autosize": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.translatedChapterTitle,
              callback: function ($$v) {
                _vm.translatedChapterTitle = $$v
              },
              expression: "translatedChapterTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.kind != "multivolume",
                expression: "kind != 'multivolume'",
              },
            ],
            attrs: {
              label: _vm.calcBookTitleLabel,
              "help-text": _vm.calcTitleHelpText,
              "is-autosize": true,
              "is-common-use": true,
              "is-suggest-bracket-help": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.bookTitle,
              callback: function ($$v) {
                _vm.bookTitle = $$v
              },
              expression: "bookTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showTranslatorFields && _vm.kind != "multivolume",
                expression: "showTranslatorFields && kind != 'multivolume'",
              },
            ],
            attrs: {
              label: "Translated Book Title",
              "help-text":
                "If the original book title is provide in another language, type the translated title.",
              "is-autosize": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.translatedTitle,
              callback: function ($$v) {
                _vm.translatedTitle = $$v
              },
              expression: "translatedTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.kind == "multivolume",
                expression: "kind == 'multivolume'",
              },
            ],
            attrs: {
              label: "Volume Title",
              "help-text":
                "Type the title of the single volume (book) from the multi-volume series. Don't include a volume number.",
              "is-autosize": true,
              "is-common-use": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.volumeTitle,
              callback: function ($$v) {
                _vm.volumeTitle = $$v
              },
              expression: "volumeTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showTranslatorFields && _vm.kind === "multivolume",
                expression: "showTranslatorFields && kind === 'multivolume'",
              },
            ],
            attrs: {
              label: "Translated Volume Title",
              "help-text":
                "If the original volume title is provided in another language, type the translated volume title.",
              "is-autosize": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.translatedVolumeTitle,
              callback: function ($$v) {
                _vm.translatedVolumeTitle = $$v
              },
              expression: "translatedVolumeTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.kind == "multivolume",
                expression: "kind == 'multivolume'",
              },
            ],
            attrs: {
              label: "Series Title",
              "help-text":
                "Type the title of the series if it is included on each of the separate volumes.  If you're unsure, omit the series title.",
              "is-autosize": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.seriesTitle,
              callback: function ($$v) {
                _vm.seriesTitle = $$v
              },
              expression: "seriesTitle",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type === "bookChapter",
                expression: "type === 'bookChapter'",
              },
            ],
            attrs: {
              label: "Chapter Page Numbers",
              "help-text":
                "Give the page numbers that include the reference text (ex. 45-78). Discontinuous pages are separated with comma and space. Ex. 45-78, 93.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.referencePages,
              callback: function ($$v) {
                _vm.referencePages = $$v
              },
              expression: "referencePages",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            attrs: {
              label: "Publication Year",
              "help-text": "List the year the work was published.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.publicationDate,
              callback: function ($$v) {
                _vm.publicationDate = $$v
              },
              expression: "publicationDate",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            attrs: {
              label: "Edition",
              "help-text":
                "For numbered editions, type the ordinal number (1st, 2nd, etc.). For named editions, type the name (e.g., 2nd ed., rev.).",
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.edition,
              callback: function ($$v) {
                _vm.edition = $$v
              },
              expression: "edition",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.kind == "multivolume",
                expression: "kind == 'multivolume'",
              },
            ],
            attrs: {
              label: "Volume Number",
              "help-text": "Use numerals for the volume number.",
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.volume,
              callback: function ($$v) {
                _vm.volume = $$v
              },
              expression: "volume",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            attrs: {
              label: "Format",
              "help-text":
                "Only include a format if the content of the e-book or audiobook is different from the original book.",
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.format,
              callback: function ($$v) {
                _vm.format = $$v
              },
              expression: "format",
            },
          }),
          _vm._v(" "),
          _c("control-publishers-v2", { attrs: { "field-type": "reference" } }),
          _vm._v(" "),
          _c("control-toggle-select", {
            attrs: {
              "is-common-use": _vm.toggleSelect.doiUrl.isCommonUse,
              label: _vm.toggleSelect.doiUrl.label,
              options: _vm.toggleSelect.doiUrl.options,
              "group-name": "doiUrl",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "mb-5" },
        [
          _c(
            "h3",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.kind === "translated" ||
                    _vm.kind === "journal" ||
                    _vm.kind === "republished" ||
                    (_vm.type === "bookChapter" && _vm.kind === "book") ||
                    (_vm.type === "bookChapter" &&
                      _vm.kind === "multivolume") ||
                    (_vm.showTranslatorFields && _vm.kind === "whole"),
                  expression:
                    "\n\t\t\t\tkind === 'translated' ||\n\t\t\t\tkind === 'journal' ||\n\t\t\t\tkind === 'republished' ||\n\t\t\t\t(type === 'bookChapter' && kind === 'book') ||\n\t\t\t\t(type === 'bookChapter' && kind === 'multivolume') ||\n\t\t\t\t(showTranslatorFields && kind === 'whole')",
                },
              ],
            },
            [_vm._v("\n\t\t\tOriginal Publication Information\n\t\t")]
          ),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.kind === "republished",
                expression: "kind === 'republished'",
              },
            ],
            attrs: {
              label: "Original Book Title",
              "help-text": _vm.calcTitleHelpText,
              "is-autosize": true,
              "is-common-use": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalBookTitle,
              callback: function ($$v) {
                _vm.originalBookTitle = $$v
              },
              expression: "originalBookTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.kind === "journal",
                expression: "kind === 'journal'",
              },
            ],
            attrs: {
              label: "Original Article Title",
              "help-text": _vm.calcTitleHelpText,
              "is-autosize": true,
              "is-common-use": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalArticleTitle,
              callback: function ($$v) {
                _vm.originalArticleTitle = $$v
              },
              expression: "originalArticleTitle",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.kind == "journal",
                expression: "kind == 'journal'",
              },
            ],
            attrs: {
              label: "Original Journal Name",
              "help-text":
                "Type the name of the journal exactly as it appears in the work.",
              "is-common-use": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalJournalTitle,
              callback: function ($$v) {
                _vm.originalJournalTitle = $$v
              },
              expression: "originalJournalTitle",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.kind == "journal",
                expression: "kind == 'journal'",
              },
            ],
            attrs: {
              label: "Original Volume Number",
              "help-text": "Use numbers for the volume number.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalVolume,
              callback: function ($$v) {
                _vm.originalVolume = $$v
              },
              expression: "originalVolume",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.kind == "journal",
                expression: "kind == 'journal'",
              },
            ],
            attrs: {
              label: "Original Issue Number",
              "help-text": "Use numbers for the issue number.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalIssue,
              callback: function ($$v) {
                _vm.originalIssue = $$v
              },
              expression: "originalIssue",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.type === "bookChapter" &&
                  (_vm.kind == "journal" || _vm.kind == "republished"),
                expression:
                  "type === 'bookChapter' && (kind == 'journal' || kind == 'republished')",
              },
            ],
            attrs: {
              label: "Original Page Numbers",
              "help-text":
                "Give the page numbers that include the reference text (ex. 45-78). Discontinuous pages are separated with comma and space. Ex. 45-78, 93.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalReferencePages,
              callback: function ($$v) {
                _vm.originalReferencePages = $$v
              },
              expression: "originalReferencePages",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.kind == "translated" ||
                  _vm.kind == "journal" ||
                  _vm.kind == "republished" ||
                  (_vm.showTranslatorFields && _vm.kind == "whole") ||
                  (_vm.type === "bookChapter" && _vm.kind === "book"),
                expression:
                  "kind == 'translated' || kind == 'journal' || kind == 'republished' || (showTranslatorFields && kind == 'whole') || (type === 'bookChapter' && kind === 'book')",
              },
            ],
            attrs: {
              label: "Original Publication Year",
              "help-text": "List the year the original work was published.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalPublicationDate,
              callback: function ($$v) {
                _vm.originalPublicationDate = $$v
              },
              expression: "originalPublicationDate",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type === "bookChapter" && _vm.kind == "republished",
                expression: "type === 'bookChapter' && kind == 'republished'",
              },
            ],
            attrs: {
              label: "Original Book Edition",
              "help-text":
                'For numbered editions, type the ordinal number (1st, 2nd, etc.). For named editions, type the name without "edition."',
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.originalEdition,
              callback: function ($$v) {
                _vm.originalEdition = $$v
              },
              expression: "originalEdition",
            },
          }),
          _vm._v(" "),
          _c("control-publishers-v2", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type === "bookChapter" && _vm.kind == "republished",
                expression: "type === 'bookChapter' && kind == 'republished'",
              },
            ],
            attrs: { "field-type": "original" },
          }),
          _vm._v(" "),
          _c("control-toggle-select", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.type === "bookChapter" &&
                  (_vm.kind == "republished" ||
                    _vm.kind == "journal" ||
                    _vm.kind == "multivolume"),
                expression:
                  "type === 'bookChapter' && (kind == 'republished' || kind == 'journal' || kind == 'multivolume')",
              },
            ],
            attrs: {
              "is-common-use": _vm.toggleSelect.originalDoiUrl.isCommonUse,
              label: _vm.toggleSelect.originalDoiUrl.label,
              options: _vm.toggleSelect.originalDoiUrl.options,
              "group-name": "originalDoiUrl",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Radio_Book" } },
      [
        _vm._v("\n\t\t\t\t\t\t\tBook, e-book, or audiobook\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "Choose this reference type for print books, digital or e-books, and audiobook recordings.  APA 7th Edition formats all of them the same way.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Radio_BookChapter" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tChapter in edited book\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "This reference type is for a chapter in a book where each chapter is by a different author.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }