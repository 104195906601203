var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mb-5" },
    [
      _c("h3", [_vm._v("Change the reference type")]),
      _vm._v(" "),
      _c("div", { staticClass: "help-text mb-1" }, [
        _vm._v(
          "\n\t\tSwitch the type of reference PERRLA will create with the below information.\n\t"
        ),
      ]),
      _vm._v(" "),
      _c("form-select", {
        attrs: { "select-object": _vm.referenceTypeSelectList },
        model: {
          value: _vm.refererenceTypeId,
          callback: function ($$v) {
            _vm.refererenceTypeId = $$v
          },
          expression: "refererenceTypeId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }