var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.referenceFormatTypeId === _vm.$enums.Format.MLA9
      ? _c("div", { staticClass: "alert alert-warning" }, [
          _c("p", { staticClass: "ps-3" }, [
            _vm._v(
              "\n\t\t\tThis reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).\n\t\t"
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "radio", id: "RefWiz_Type_Us", value: "us" },
                domProps: { checked: _vm._q(_vm.type, "us") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "us"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_Us" },
                },
                [_vm._v("\n\t\t\t\t\t\t\tThe U.S. Constitution\n\t\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_State",
                  value: "state",
                },
                domProps: { checked: _vm._q(_vm.type, "state") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "state"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_State" },
                },
                [_vm._v("\n\t\t\t\t\t\t\tA state Constitution\n\t\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Charter",
                  value: "charter",
                },
                domProps: { checked: _vm._q(_vm.type, "charter") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "charter"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_Charter" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tAn organizational charter\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "us",
              expression: "type == 'us'",
            },
          ],
          key: "divKindUs",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v("What part of the U.S. Constitution are you citing?"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What part of the U.S. Constitution are you citing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "article" } }, [
                _vm._v(
                  "\n\t\t\t\t\tArticle of the U.S. Constitution\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "amendment" } }, [
                _vm._v(
                  "\n\t\t\t\t\tAmendment to the U.S. Constitution\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "repealed" } }, [
                _vm._v(
                  "\n\t\t\t\t\tRepealed amendment to the U.S. Constitution\n\t\t\t\t"
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "state",
              expression: "type == 'state'",
            },
          ],
          key: "divKindNonUs",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v("What part of a state Constitution are you citing?"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What part of a state Constitution are you citing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "article" } }, [
                _vm._v("\n\t\t\t\t\tArticle of a state constitution\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "amendment" } }, [
                _vm._v(
                  "\n\t\t\t\t\tAmendment to a state constitution\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "repealed" } }, [
                _vm._v(
                  "\n\t\t\t\t\tRepealed Amendment to a state constitutioin\n\t\t\t\t"
                ),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind == "article",
              expression: "kind == 'article'",
            },
          ],
          attrs: {
            label: "Article Number(s)",
            "help-text":
              "Type the number of the article(s) using Roman numerals.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.article,
            callback: function ($$v) {
              _vm.article = $$v
            },
            expression: "article",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Section Number(s)",
            "help-text":
              "Enter the section number(s) your are referencing in Arabic numerals (2-4).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.section,
            callback: function ($$v) {
              _vm.section = $$v
            },
            expression: "section",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind == "amendment" || _vm.kind == "repealed",
              expression: "kind == 'amendment' || kind == 'repealed'",
            },
          ],
          attrs: {
            label: "Amendment Number(s)",
            "help-text":
              "Type the number of the amendment(s) in Roman numerals.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.amendment,
            callback: function ($$v) {
              _vm.amendment = $$v
            },
            expression: "amendment",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind == "repealed",
              expression: "kind == 'repealed'",
            },
          ],
          attrs: {
            label: "Year of the Amendment's repeal",
            "help-text": "Enter the year the Amendment was repealed.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.repealDate,
            callback: function ($$v) {
              _vm.repealDate = $$v
            },
            expression: "repealDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "state",
              expression: "type == 'state'",
            },
          ],
          attrs: {
            label: "State's Abbreviation",
            "help-text":
              "Type the legal abbreviation for the state (e.g., Tenn.).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.state,
            callback: function ($$v) {
              _vm.state = $$v
            },
            expression: "state",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "charter",
              expression: "type == 'charter'",
            },
          ],
          attrs: {
            label: "Charter Title",
            "help-text":
              "Type the title of the organization's charter. You can use appropriate abbreviations.",
            "is-autosize": true,
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "charter",
              expression: "type == 'charter'",
            },
          ],
          attrs: {
            label: "Article Number(s)",
            "help-text":
              "Enter the article number(s) you are referencing in Arabic numerals.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.charterArticle,
            callback: function ($$v) {
              _vm.charterArticle = $$v
            },
            expression: "charterArticle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "charter",
              expression: "type == 'charter'",
            },
          ],
          attrs: {
            label: "Paragraph Number(s)",
            "help-text":
              "Type the paragraph number(s) you are referencing in Arabic numerals. Omit if you are citing an entire article.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.paragraph,
            callback: function ($$v) {
              _vm.paragraph = $$v
            },
            expression: "paragraph",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }