var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["form-group", { "mb-4": _vm.isSingleOnly }] },
    [
      _c("label", { domProps: { innerHTML: _vm._s(_vm.label) } }),
      _vm._v(" "),
      _vm.calcHelpText
        ? _c("div", {
            staticClass: "help-text mb-1",
            domProps: { innerHTML: _vm._s(_vm.calcHelpText) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(
        _vm.$store.state.referenceRules.formData.publishersV2[_vm.fieldType],
        function (publisherData, index) {
          return _c("publishers-v2-block", {
            key: publisherData.id,
            attrs: {
              "field-type": _vm.fieldType,
              "is-show-city-state": _vm.isShowCityState,
              "is-single-only": _vm.isSingleOnly,
              "publisher-index": index,
            },
          })
        }
      ),
      _vm._v(" "),
      !_vm.isSingleOnly
        ? _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-hover btn-hover-success",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addPublisherBlock.apply(null, arguments)
                  },
                },
              },
              [
                _c("span", { staticClass: "svg bi-plus-lg" }),
                _vm._v(" Add " + _vm._s(_vm.buttonText) + "\n\t\t"),
              ]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }