var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.referenceFormatTypeId === _vm.$enums.Format.MLA9
      ? _c("div", { staticClass: "alert alert-warning" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Supreme",
                  value: "supreme",
                },
                domProps: { checked: _vm._q(_vm.type, "supreme") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "supreme"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Circuit",
                  value: "circuit",
                },
                domProps: { checked: _vm._q(_vm.type, "circuit") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "circuit"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(2),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_District",
                  value: "district",
                },
                domProps: { checked: _vm._q(_vm.type, "district") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "district"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(3),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Appealed",
                  value: "appealed",
                },
                domProps: { checked: _vm._q(_vm.type, "appealed") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "appealed"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(4),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "appealed",
              expression: "type == 'appealed'",
            },
          ],
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v(
              "What was the result of the U.S. District Court case you are referencing?"
            ),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What was the result of the U.S. District Court case you are referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "affirmed" } }, [
                _vm._v(
                  "\n\t\t\t\t\tDecision was affirmed in the appeal\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "overturned" } }, [
                _vm._v(
                  "\n\t\t\t\t\tDecision was overturned in the appeal\n\t\t\t\t"
                ),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Case Name",
            "help-text":
              'Type the full name of the case as it appears. It should be formatted as "Name v. Name"',
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "supreme",
              expression: "type === 'supreme'",
            },
          ],
          attrs: {
            label: "<em>United States Reporter</em> Volume",
            "help-text":
              "Type the volume of the <em>United States Reporter</em> where the case was published.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.volume,
            callback: function ($$v) {
              _vm.volume = $$v
            },
            expression: "volume",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type === "circuit",
                expression: "type === 'circuit'",
              },
            ],
            staticClass: "form-group py-2",
          },
          [
            _vm._m(5),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.series,
                    expression: "series",
                  },
                ],
                staticClass: "form-select",
                attrs: { "aria-label": "Federal Reporter Series" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.series = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.calcContributors,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "first" } }, [
                  _vm._v("\n\t\t\t\t\tFirst Series (1880-1924)\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "second" } }, [
                  _vm._v("\n\t\t\t\t\tSecond Series (1924-1993)\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "third" } }, [
                  _vm._v("\n\t\t\t\t\tThird Series (1993-2021)\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "fourth" } }, [
                  _vm._v("\n\t\t\t\t\tFourth Series (2022-present)\n\t\t\t\t"),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "circuit",
              expression: "type === 'circuit'",
            },
          ],
          attrs: {
            label: "<em>Federal Reporter</em> Volume",
            "help-text":
              "Type the volume of the <em>Federal Reporter</em> where the case was published.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.volume,
            callback: function ($$v) {
              _vm.volume = $$v
            },
            expression: "volume",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type === "appealed" || _vm.type === "district",
                expression: "type === 'appealed' || type === 'district'",
              },
            ],
            staticClass: "form-group py-2",
          },
          [
            _vm._m(6),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.series,
                    expression: "series",
                  },
                ],
                staticClass: "form-select",
                attrs: { "aria-label": "Federal Supplement Series" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.series = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.calcContributors,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "first" } }, [
                  _vm._v("\n\t\t\t\t\tFirst Series (1933-1998)\n\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "second" } }, [
                  _vm._v("\n\t\t\t\t\tSecond Series (1998-2014)\n\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "third" } }, [
                  _vm._v("\n\t\t\t\t\tThird Series (1993-present)\n\t\t\t\t\t"),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "appealed" || _vm.type === "district",
              expression: "type === 'appealed' || type === 'district'",
            },
          ],
          attrs: {
            label: "<em>Federal Supplement</em> Volume",
            "help-text":
              "Type the volume of the <em>Federal Supplement</em> where the case was published.",
            "is-common-use":
              _vm.referenceFormatTypeId == _vm.$enums.Format.APA7 ||
              _vm.referenceFormatTypeId == _vm.$enums.Format.Turabian9
                ? true
                : false,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.volume,
            callback: function ($$v) {
              _vm.volume = $$v
            },
            expression: "volume",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Case Starting Page Number",
            "help-text": "Type the page number where the case starts.",
            "is-common-use":
              _vm.referenceFormatTypeId == _vm.$enums.Format.APA7 ||
              _vm.referenceFormatTypeId == _vm.$enums.Format.Turabian9
                ? true
                : false,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.pageNumber,
            callback: function ($$v) {
              _vm.pageNumber = $$v
            },
            expression: "pageNumber",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "circuit",
              expression: "type === 'circuit'",
            },
          ],
          attrs: {
            label: "Circuit Number",
            "help-text":
              "Type the number of the Circuit court that heard the case.  Use the ordinal number (e.g., 9th).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.circuit,
            callback: function ($$v) {
              _vm.circuit = $$v
            },
            expression: "circuit",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "appealed" || _vm.type === "district",
              expression: "type === 'appealed' || type === 'district'",
            },
          ],
          attrs: {
            label: "Court District Name",
            "help-text": _vm.calcHelpTextCourtDistrictName,
            "is-common-use":
              _vm.referenceFormatTypeId == _vm.$enums.Format.APA7 ||
              _vm.referenceFormatTypeId == _vm.$enums.Format.Turabian9
                ? true
                : false,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.district,
            callback: function ($$v) {
              _vm.district = $$v
            },
            expression: "district",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Year",
            "help-text": "List the year the decision was passed.",
            "is-common-use":
              _vm.referenceFormatTypeId == _vm.$enums.Format.APA7 ||
              _vm.referenceFormatTypeId == _vm.$enums.Format.Turabian9
                ? true
                : false,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL (Web address)",
            "help-text": "Include the URL for the work if available.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.type === "appealed",
            expression: "type === 'appealed'",
          },
        ],
        staticClass: "mb-5",
      },
      [
        _c("h3", [_vm._v("Appeal Information")]),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "<em>Federal Reporter</em> Volume",
            "help-text":
              "Type the volume of the <em>Federal Reporter</em> where the appeal was published.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.appealVolume,
            callback: function ($$v) {
              _vm.appealVolume = $$v
            },
            expression: "appealVolume",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "form-group py-2" }, [
          _vm._m(7),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.appealSeries,
                  expression: "appealSeries",
                },
              ],
              staticClass: "form-select",
              attrs: { "aria-label": "Federal Reporter Series" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.appealSeries = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "first" } }, [
                _vm._v("\n\t\t\t\t\tFirst Series (1880-1924)\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "second" } }, [
                _vm._v("\n\t\t\t\t\tSecond Series (1924-1993)\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "third" } }, [
                _vm._v("\n\t\t\t\t\tThird Series (1993-2021)\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "fourth" } }, [
                _vm._v("\n\t\t\t\t\tFourth Series (2022-present)\n\t\t\t\t"),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Appeal Starting Page Number",
            "help-text":
              "Type the page number where the appeal starts in the <em>Federal Reporter</em>.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.appealPageNumber,
            callback: function ($$v) {
              _vm.appealPageNumber = $$v
            },
            expression: "appealPageNumber",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Year of the Appeal",
            "help-text": "List the year of the appealed court case.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.appealDate,
            callback: function ($$v) {
              _vm.appealDate = $$v
            },
            expression: "appealDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Appealed Circuit",
            "help-text":
              "Type the number of the Circuit court that heard the appeal.  Use the ordinal number (e.g., 9th).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.appealCircuit,
            callback: function ($$v) {
              _vm.appealCircuit = $$v
            },
            expression: "appealCircuit",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ps-3" }, [
      _c("strong", [
        _vm._v(
          "This reference type is for citing the formal, canonical version of a legal work."
        ),
      ]),
      _vm._v(
        " If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).\n\t\t"
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Supreme" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tSupreme Court case\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-html": "true",
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              'Use this reference type for cases that were decided by the U.S. Supreme Court. &nbsp;These cases are found in the "United States Records."',
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Circuit" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tU.S. Circuit Court case\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-html": "true",
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              'Use this reference for U.S. Circuit Court cases found in the "Federal Reporter."',
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_District" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tU.S. District Court case\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-html": "true",
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              'Use this reference for un-appealed U.S. Circuit Court Cases found in the "Federal Supplement."',
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Appealed" },
      },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tU.S. District Court case with an appeal\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-html": "true",
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              'Use this reference for U.S. Circuit Court Cases appealed to the District Court. These cases are found in the "Federal Supplement."',
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("em", [_vm._v("Federal Reporter")]),
      _vm._v(" Series"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("em", [_vm._v("Federal Supplement")]),
      _vm._v(" Series"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("em", [_vm._v("Federal Reporter")]),
      _vm._v(" Series"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }