var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.$store.state.referenceRules.referenceTypeId === 23 &&
      _vm.$store.state.referenceRules.formData.kind === "named"
        ? _c("div", [_c("h3", [_vm._v("Person Interviewed")])])
        : _vm.$store.state.referenceRules.referenceTypeId === 23 &&
          _vm.$store.state.referenceRules.formData.kind === "anonymous"
        ? _c("div", [
            _c("h3", [_vm._v("Description of Person(s) Interviewed")]),
            _vm._v(" "),
            _c("p", { staticClass: "help-text mb-2" }, [
              _vm._v(
                "\n\t\t\tDescribe the interview (e.g., Interview with a nurse).\n\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "help-text mb-2 text-danger" }, [
              _vm._v(
                "\n\t\t\tAn explanation for why names are withheld should be added in a separate note or in the preface of your paper.\n\t\t"
              ),
            ]),
          ])
        : _c("div", [
            _c("h3", [_vm._v("Contributors")]),
            _vm._v(" "),
            _vm.referenceFormatTypeId === _vm.$enums.Format.MLA9
              ? _c("p", { key: "pHelpMLA9", staticClass: "help-text" }, [
                  _vm._v(
                    "\n\t\t\tAny contributor type can be the primary contributor for an MLA 9 reference. Re-order contributors below to change the contributor type listed at the beginning of the reference.\n\t\t"
                  ),
                ])
              : _c("p", { key: "pHelpElse", staticClass: "help-text" }, [
                  _vm._v(
                    "\n\t\t\tList the contributors in the order they are listed in the work. For multiple contributors, create a contributor entry for each.\n\t\t"
                  ),
                ]),
          ]),
      _vm._v(" "),
      _vm._l(
        _vm.$store.state.referenceRules.formData.contributors,
        function (contributor, index) {
          return _c("contributor-block", {
            key: contributor.id,
            attrs: {
              "all-contributor-types": _vm.listOfContributorTypes,
              "contributor-label": _vm.contributorLabel,
              "contributor-index": index,
              "is-add-another": _vm.isAddAnother,
              "is-book-chapter": _vm.isBookChapter,
              "used-contributor-types": _vm.usedContributorTypes,
            },
            on: { "remove-contributor-block": _vm.removeContributorBlock },
          })
        }
      ),
      _vm._v(" "),
      _vm.isAddAnother
        ? _c("div", { staticClass: "d-grid" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-hover btn-hover-success",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addContributorBlock.apply(null, arguments)
                  },
                },
              },
              [
                _c("span", { staticClass: "svg bi-plus-lg" }),
                _vm._v(" Add contributor\n\t\t"),
              ]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }