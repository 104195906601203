var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c(
      "label",
      { class: [{ "font-italic": _vm.isInvalid }], attrs: { for: _vm.fullId } },
      [_vm._v("\n\t\t" + _vm._s(_vm.label) + "\n\t")]
    ),
    _vm._v(" "),
    _vm.helpText
      ? _c("div", {
          staticClass: "help-text mb-1",
          domProps: { innerHTML: _vm._s(_vm.helpText) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("textarea", {
      directives: [
        {
          name: "debounce",
          rawName: "v-debounce:750ms",
          value: _vm.debouncedGenerateReference,
          expression: "debouncedGenerateReference",
          arg: "750ms",
        },
      ],
      ref: "focusElement",
      class: [
        "form-control",
        {
          "is-invalid": _vm.isInvalid,
          "form-control-warning":
            _vm.isShowSuggestionTitleCase || _vm.isShowSuggestionAllCaps,
          "form-control-primary": _vm.isCommonUseShow,
        },
      ],
      attrs: {
        id: _vm.fullId,
        "pendo-grab-text": _vm.pendoGrabText,
        placeholder: _vm.placeholder,
        rows: _vm.calcRows,
      },
      domProps: { value: _vm.value },
      on: {
        blur: _vm.blurInput,
        input: function ($event) {
          return _vm.typeInput($event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _vm.isInvalid
      ? _c("div", { staticClass: "invalid-feedback" }, [
          _vm._v("\n\t\t" + _vm._s(_vm.invalidErrorMessage) + "\n\t"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isShowSuggestionBracket
      ? _c(
          "div",
          {
            key: "suggestedFeedbackBracket",
            staticClass: "suggested-feedback",
          },
          [
            _vm._v(
              "\n\t\tIt looks like you're typing a description for the title. Include the format of the work in your description, not in the Format field.\n\t"
            ),
          ]
        )
      : _vm.isShowSuggestionTitleCase
      ? _c(
          "div",
          {
            key: "suggestedFeedbackTitleCase",
            staticClass: "suggested-feedback",
          },
          [
            _vm._v("\n\t\tOnly capitalize the "),
            _c("strong", [_vm._v("first word of the title & subtitle")]),
            _vm._v(", and "),
            _c("strong", [_vm._v("proper nouns")]),
            _vm._v(" for titles.\n\t"),
          ]
        )
      : _vm.isShowSuggestionAllCaps
      ? _c(
          "div",
          {
            key: "suggestedFeedbackAllCaps",
            staticClass: "suggested-feedback",
          },
          [
            _c("strong", [
              _vm._v("Only use all capital letters for acronyms."),
            ]),
            _vm._v(
              " Words in all capital letters will be left in all capitals when formatting the Title in your reference\n\t"
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }