<!--
- Patent
-- Reference Type Id: 32
-- 2024.06.13
-->

<template>
	<div>
		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Patent Name"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard
				label="Patent Number"
				help-text="Type the patent number exactly as it appears in the patent."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="number" />

			<form-datepicker
				label="Date Patent Filed"
				help-text="Type the date that the patent was filed. Enter as much information as is available."
				@date-clear="dateClearFiled"
				@date-set="dateSetFiled"
				:is-common-use="true"
				:paper-format="0"
				id="RefWiz_FiledDate" />

			<form-datepicker
				label="Year Patent Issued"
				help-text="List the year the patent was issued by the U.S. Patent Office."
				@date-clear="dateClearPublication"
				@date-set="dateSetPublication"
				:is-common-use="true"
				:paper-format="0"
				id="RefWiz_PublicationDate" />

			<form-input-reference-wizard
				label="URL"
				help-text="Enter a url for the patent if available."
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

		</section>
	</div>
</template>

<script>
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypePatent',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['author', 'groupAuthor'];

			// author
			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});

			// groupAuthor
			this.addContributorRole({
				type: 'groupAuthor',
			});

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors

		dateClearFiled(){
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'filedDate',
				value: ''
			});
			this.generateReferencePreview();
		},

		dateSetFiled(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'filedDate',
					value: newDate
				});
				this.generateReferencePreview();
			}
		},
	},
}
</script>