<!--
- US Federal Court
-- Reference Type Id: 45
-- 2024.06.20
-->

<template>
	<div>
		<div v-if="referenceFormatTypeId === $enums.Format.MLA9" class="alert alert-warning">
			<p class="ps-3">
				<strong>This reference type is for citing the formal, canonical version of a legal work.</strong> If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).
			</p>
		</div>

		<section class="mb-5">
			<h3>Format Options</h3>
			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Supreme"
								value="supreme"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Supreme">
								Supreme Court case
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-html="true"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="Use this reference type for cases that were decided by the U.S. Supreme Court. &nbsp;These cases are found in the &quot;United States Records.&quot;" />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Circuit"
								value="circuit"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Circuit">
								U.S. Circuit Court case
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-html="true"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="Use this reference for U.S. Circuit Court cases found in the &quot;Federal Reporter.&quot;" />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_District"
								value="district"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_District">
								U.S. District Court case
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-html="true"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="Use this reference for un-appealed U.S. Circuit Court Cases found in the &quot;Federal Supplement.&quot;" />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Appealed"
								value="appealed"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Appealed">
								U.S. District Court case with an appeal
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-html="true"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="Use this reference for U.S. Circuit Court Cases appealed to the District Court. These cases are found in the &quot;Federal Supplement.&quot;" />
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type == 'appealed'">
				<label>What was the result of the U.S. District Court case you are referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What was the result of the U.S. District Court case you are referencing?">
					<option value="affirmed">
						Decision was affirmed in the appeal
					</option>
					<option value="overturned">
						Decision was overturned in the appeal
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-input-reference-wizard
				label="Case Name"
				help-text="Type the full name of the case as it appears. It should be formatted as &quot;Name v. Name&quot;"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard
				v-show="type === 'supreme'"
				label="<em>United States Reporter</em> Volume"
				help-text="Type the volume of the <em>United States Reporter</em> where the case was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="volume" />

			<div class="form-group py-2" v-show="type === 'circuit'">
				<label><em>Federal Reporter</em> Series</label>
				<select class="form-select" v-model="series" @change="calcContributors" aria-label="Federal Reporter Series">
					<option value="first">
						First Series (1880-1924)
					</option>
					<option value="second">
						Second Series (1924-1993)
					</option>
					<option value="third">
						Third Series (1993-2021)
					</option>
					<option value="fourth">
						Fourth Series (2022-present)
					</option>
				</select>
			</div>

			<form-input-reference-wizard
				v-show="type === 'circuit'"
				label="<em>Federal Reporter</em> Volume"
				help-text="Type the volume of the <em>Federal Reporter</em> where the case was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="volume" />

			<div class="form-group py-2" v-show="type === 'appealed' || type === 'district'">
				<label><em>Federal Supplement</em> Series</label>
				<select class="form-select" v-model="series" @change="calcContributors" aria-label="Federal Supplement Series">
					<option value="first">
						First Series (1933-1998)
						</option>
					<option value="second">
						Second Series (1998-2014)
						</option>
					<option value="third">
						Third Series (1993-present)
						</option>
				</select>
			</div>

			<form-input-reference-wizard
				v-show="type === 'appealed' || type === 'district'"
				label="<em>Federal Supplement</em> Volume"
				help-text="Type the volume of the <em>Federal Supplement</em> where the case was published."
				:is-common-use="(referenceFormatTypeId == $enums.Format.APA7 || referenceFormatTypeId == $enums.Format.Turabian9) ? true : false"
				@generate-reference-preview="generateReferencePreview"
				v-model="volume" />

			<form-input-reference-wizard
				label="Case Starting Page Number"
				help-text="Type the page number where the case starts."
				:is-common-use="(referenceFormatTypeId == $enums.Format.APA7 || referenceFormatTypeId == $enums.Format.Turabian9) ? true : false"
				@generate-reference-preview="generateReferencePreview"
				v-model="pageNumber" />

			<form-input-reference-wizard
				v-show="type === 'circuit'"
				label="Circuit Number"
				help-text="Type the number of the Circuit court that heard the case.  Use the ordinal number (e.g., 9th)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="circuit" />

			<form-input-reference-wizard
				v-show="type === 'appealed' || type === 'district'"
				label="Court District Name"
				:help-text="calcHelpTextCourtDistrictName"
				:is-common-use="(referenceFormatTypeId == $enums.Format.APA7 || referenceFormatTypeId == $enums.Format.Turabian9) ? true : false"
				@generate-reference-preview="generateReferencePreview"
				v-model="district" />

			<form-input-reference-wizard
				label="Year"
				help-text="List the year the decision was passed."
				:is-common-use="(referenceFormatTypeId == $enums.Format.APA7 || referenceFormatTypeId == $enums.Format.Turabian9) ? true : false"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />
			
			<form-input-reference-wizard
				label="URL (Web address)"
				help-text="Include the URL for the work if available."
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

		</section>

		<section class="mb-5" v-show="type === 'appealed'">
			<h3>Appeal Information</h3>

			<form-input-reference-wizard
				label="<em>Federal Reporter</em> Volume"
				help-text="Type the volume of the <em>Federal Reporter</em> where the appeal was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="appealVolume" />

			<div class="form-group py-2">
				<label><em>Federal Reporter</em> Series</label>
				<select class="form-select" v-model="appealSeries" @change="calcContributors" aria-label="Federal Reporter Series">
					<option value="first">
						First Series (1880-1924)
					</option>
					<option value="second">
						Second Series (1924-1993)
					</option>
					<option value="third">
						Third Series (1993-2021)
					</option>
					<option value="fourth">
						Fourth Series (2022-present)
					</option>
				</select>
			</div>

			<form-input-reference-wizard 
				label="Appeal Starting Page Number"
				help-text="Type the page number where the appeal starts in the <em>Federal Reporter</em>."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="appealPageNumber" />

			<form-input-reference-wizard
				label="Year of the Appeal"
				help-text="List the year of the appealed court case."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="appealDate" />

			<form-input-reference-wizard
				label="Appealed Circuit"
				help-text="Type the number of the Circuit court that heard the appeal.  Use the ordinal number (e.g., 9th)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="appealCircuit" />

		</section>
	</div>
</template>

<script>
import config from "@/config";
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeUsFederalCourt',
	mixins: [mixinReferenceTypeGenesis],
	computed:{
		calcHelpTextCourtDistrictName(){
			if(this.referenceFormatTypeId === config.enums.Format.MLA9){
				return "Type the full name of the district court.";
			} else {
				return "Type the number of the District Court that heard the case (e.g., D. Tenn.). Be sure to use the appropriate abbreviations.";
			}

		}
	},
	methods: {
		calcContributors(){
			this.$nextTick(()=>{
				this.generateReferencePreview();
			});
		},

		typeChange(){
			this.kind = '';

			switch(this.type){
				case 'appealed':
					this.appealSeries = 'first';
					this.kind = 'affirmed';
					this.series = 'first';
					break;
				case 'circuit':
					this.series = 'first';
					break;
				case 'district':
					this.series = 'first';
					break;
				case 'supreme':
					this.series = '';
					break;
			}
			
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted(){
		if(this.type === ''){
			this.type = 'supreme';

			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>