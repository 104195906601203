var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.referenceFormatTypeId === _vm.$enums.Format.MLA9
      ? _c("div", { staticClass: "alert alert-warning" }, [
          _c("p", { staticClass: "ps-3" }, [
            _vm._v(
              "\n\t\t\tThis reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).\n\t\t"
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "is-add-another": false,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Order Title",
            "help-text":
              "Type the name of the executive order exactly as it appears.",
            "is-common-use":
              _vm.referenceFormatTypeId == _vm.$enums.Format.MLA9
                ? true
                : false,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Executive Order Number",
            "help-text": "Provide the number for the Executive Order.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.orderNumber,
            callback: function ($$v) {
              _vm.orderNumber = $$v
            },
            expression: "orderNumber",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Starting Page Number",
            "help-text":
              "Type the starting page number for the Executive Order in the <em>Code of Federal Regulations</em> text.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.pageNumber,
            callback: function ($$v) {
              _vm.pageNumber = $$v
            },
            expression: "pageNumber",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Date signed",
            "is-common-use":
              _vm.referenceFormatTypeId == _vm.$enums.Format.MLA9
                ? true
                : false,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL (Web address)",
            "help-text": "Include the URL for the work if available.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }