var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Streaming",
                  value: "streaming",
                },
                domProps: { checked: _vm._q(_vm.type, "streaming") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "streaming"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Webinar",
                  value: "webinar",
                },
                domProps: { checked: _vm._q(_vm.type, "webinar") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "webinar"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "streaming",
              expression: "type === 'streaming'",
            },
          ],
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v("What kind of streaming video are you referencing?"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What kind of streaming video are you referencing",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "posted" } }, [
                _vm._v(
                  "\n\t\t\t\t\tA video posted on a streaming site (e.g., video on YouTube)\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "hosted" } }, [
                _vm._v(
                  "\n\t\t\t\t\tA video on its own website (e.g., video on TED website)\n\t\t\t\t"
                ),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Video Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type !== "webinar",
              expression: "type !== 'webinar'",
            },
          ],
          attrs: {
            label: "Video Description",
            "help-text":
              "Briefly describe the type of video (e.g., music video, documentary).",
            "is-autosize": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.description,
            callback: function ($$v) {
              _vm.description = $$v
            },
            expression: "description",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Publication Date",
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind === "posted",
              expression: "kind === 'posted'",
            },
          ],
          attrs: {
            label: "Platform",
            "help-text-prop":
              "The name of the site where the video was published or posted.",
            "field-type": "reference",
            "is-show-city-state": false,
            "is-single-only": true,
          },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind != "posted",
              expression: "kind != 'posted'",
            },
          ],
          attrs: {
            label: "Website Name",
            "help-text-prop":
              "The name of the site where the video was published or posted.",
            "field-type": "reference",
            "is-show-city-state": false,
            "is-single-only": true,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL (Web address)",
            "help-text": "Enter the full URL (web address) for the video.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
        _vm._v(" "),
        _c("form-time-split-reference-wizard", {
          attrs: {
            "engine-value": "runtime",
            label: "Runtime",
            "help-text": "Enter the full length of the video.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Radio_Streaming" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tA streaming (online) video\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "A video viewed through a video platform like YouTube, Vimeo, etc.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Radio_Webinar" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tA recorded webinar\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-html": "true",
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "This is for viewing a webinar that can be accessed by others on a website. If the webinar recording isn&lsquo;t available, cite it as a Personal Communication.",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }