var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Album",
                  value: "album",
                },
                domProps: { checked: _vm._q(_vm.type, "album") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "album"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "radio", id: "RefWiz_Type_Song", value: "song" },
                domProps: { checked: _vm._q(_vm.type, "song") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "song"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("How was the piece performed?")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.kind,
                expression: "kind",
              },
            ],
            staticClass: "form-select",
            attrs: { "aria-label": "How was the piece performed?" },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.kind = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.calcContributors,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "original" } }, [
              _vm._v("\n\t\t\t\t\tRecorded by the original artist\n\t\t\t\t"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "cover" } }, [
              _vm._v(
                "\n\t\t\t\t\tPerformed by someone else; not the original artist (e.g. Classical music, covers)\n\t\t\t\t"
              ),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Album or Work Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.albumTitle,
            callback: function ($$v) {
              _vm.albumTitle = $$v
            },
            expression: "albumTitle",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "song",
              expression: "type === 'song'",
            },
          ],
          attrs: {
            label: "Song Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.songTitle,
            callback: function ($$v) {
              _vm.songTitle = $$v
            },
            expression: "songTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Copyright Year",
            "help-text":
              "This is the year the album was copyrighted and/or released.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Date or Year Recorded",
            "help-text":
              "If the date or year the work was recorded is available and different from the copyright date, include it here (e.g., April 1, 2020).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.recordDate,
            callback: function ($$v) {
              _vm.recordDate = $$v
            },
            expression: "recordDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind === "cover",
              expression: "kind === 'cover'",
            },
          ],
          attrs: {
            label: "Original Publication Year",
            "help-text": "Include the year the work was originally published.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.originalPublicationDate,
            callback: function ($$v) {
              _vm.originalPublicationDate = $$v
            },
            expression: "originalPublicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Special Edition",
            "help-text":
              "Only include an special edition title if it is different from the original album (e.g., Deluxe edition).",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.format,
            callback: function ($$v) {
              _vm.format = $$v
            },
            expression: "format",
          },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", {
          attrs: {
            label: "Label/Publisher Name",
            "field-type": "reference",
            "is-show-city-state": false,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL or Platform",
            "help-text":
              "Only include the URL or the platform for the work (e.g., Spotify) if it can be accessed online.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Music Medium",
            "help-text":
              "Type the medium for how you accessed the music (e.g., CD, MP3).",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.medium,
            callback: function ($$v) {
              _vm.medium = $$v
            },
            expression: "medium",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_Album" } },
      [
        _vm._v("\n\t\t\t\t\t\t\tAn album or stand-alone piece\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "This is for citing a piece of music in its entirety.  It may be an album with multiple songs or a single piece released by itself.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_Song" } },
      [
        _vm._v("\n\t\t\t\t\t\t\tA song or track on an album\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "If you are citing a piece that is only a part of a larger album, use this reference type.",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }