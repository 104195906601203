var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    (_vm.$platformId === _vm.$enums.Platform.ADD_IN &&
      (_vm.referenceFormatTypeId === _vm.$enums.Format.MLA9 ||
        _vm.referenceFormatTypeId === _vm.$enums.Format.Turabian9)) ||
    (_vm.$platformId === _vm.$enums.Platform.ONLINE &&
      _vm.$route.meta.module === _vm.$enums.Module.REFERENCE_LIST) ||
    (_vm.$route.meta.module === _vm.$enums.Module.PAPER_EDIT &&
      _vm.referenceFormatTypeId === _vm.$enums.Format.MLA9) ||
    (_vm.$route.meta.module === _vm.$enums.Module.PAPER_EDIT &&
      _vm.referenceFormatTypeId === _vm.$enums.Format.Turabian9)
      ? _c("div", { key: "divReferenceList" }, [
          _vm.$route.meta.module === _vm.$enums.Module.REFERENCE_LIST
            ? _c(
                "div",
                {
                  key: "divAlertReferenceList",
                  staticClass: "alert alert-danger",
                },
                [
                  _vm._m(0),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "APA 7 does not include a specific format for advertisements. Instead, advertisements are formatted according to their parent source (e.g., use Magazine for an ad in a magazine)."
                    ),
                  ]),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "In MLA 9, advertisements should be formatted as part of their parent medium whenever possible (e.g., use Magazine for an ad in a magazine). This format is ONLY for advertisements that stand on their own."
                    ),
                  ]),
                ]
              )
            : _vm.$route.meta.module === _vm.$enums.Module.PAPER_EDIT &&
              _vm.referenceFormatTypeId === _vm.$enums.Format.MLA9
            ? _c(
                "div",
                {
                  key: "divAlertPaperEditMLA9",
                  staticClass: "alert alert-warning",
                },
                [
                  _c("p", [
                    _vm._v(
                      "In MLA 9, advertisements should be formatted as part of their parent medium whenever possible (e.g., use Magazine for an ad in a magazine). This format is ONLY for advertisements that stand on their own."
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("section", { staticClass: "mb-5" }, [
            _c("h3", [_vm._v("Format Options")]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mb-0 py-2" }, [
              _c("label", [_vm._v("Choose a type")]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "form-check" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.type,
                          expression: "type",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        id: "RefWiz_Type_Physical",
                        value: "physical",
                      },
                      domProps: { checked: _vm._q(_vm.type, "physical") },
                      on: {
                        change: function ($event) {
                          _vm.type = "physical"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "RefWiz_Type_Physical" },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\tA physical ad (e.g., a billboard, poster)\n\t\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "form-check" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.type,
                          expression: "type",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        id: "RefWiz_Type_Digital",
                        value: "digital",
                      },
                      domProps: { checked: _vm._q(_vm.type, "digital") },
                      on: {
                        change: function ($event) {
                          _vm.type = "digital"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "RefWiz_Type_Digital" },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\tA digital ad (e.g., a website pop-up)\n\t\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "mb-5" },
            [
              _c("h3", [_vm._v("Publication Information")]),
              _vm._v(" "),
              _c("form-input-reference-wizard", {
                attrs: {
                  label: "Ad Sponsor",
                  "help-text":
                    "Enter the company, event, or organization that the advertisement is promoting.",
                  "is-common-use": true,
                },
                on: {
                  "generate-reference-preview": _vm.generateReferencePreview,
                },
                model: {
                  value: _vm.sponsor,
                  callback: function ($$v) {
                    _vm.sponsor = $$v
                  },
                  expression: "sponsor",
                },
              }),
              _vm._v(" "),
              _c("form-input-reference-wizard", {
                attrs: {
                  label: "Main Ad Text",
                  "help-text":
                    "If the ad includes a main heading, type the main content exactly as it appears in the work.",
                  "is-common-use": true,
                },
                on: {
                  "generate-reference-preview": _vm.generateReferencePreview,
                },
                model: {
                  value: _vm.title,
                  callback: function ($$v) {
                    _vm.title = $$v
                  },
                  expression: "title",
                },
              }),
              _vm._v(" "),
              _c("form-datepicker", {
                attrs: {
                  id: "RefWiz_PublicationDate",
                  label: "Publication Date",
                  "is-common-use": true,
                  "paper-format": 0,
                },
                on: {
                  "date-set": _vm.dateSetPublication,
                  "date-clear": _vm.dateClearPublication,
                },
              }),
              _vm._v(" "),
              _vm.type === "physical"
                ? _c("form-input-reference-wizard", {
                    attrs: {
                      label: "Location",
                      "help-text":
                        "Enter the location where the advertisement was displayed. Include the exact location, city, and state.",
                      "is-common-use": true,
                    },
                    on: {
                      "generate-reference-preview":
                        _vm.generateReferencePreview,
                    },
                    model: {
                      value: _vm.location,
                      callback: function ($$v) {
                        _vm.location = $$v
                      },
                      expression: "location",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("form-input-reference-wizard", {
                attrs: {
                  label: "Format",
                  "help-text": _vm.calcHelpTextFormat,
                  "is-common-use": true,
                },
                on: {
                  "generate-reference-preview": _vm.generateReferencePreview,
                },
                model: {
                  value: _vm.format,
                  callback: function ($$v) {
                    _vm.format = $$v
                  },
                  expression: "format",
                },
              }),
              _vm._v(" "),
              _vm.type === "digital"
                ? _c("form-input-reference-wizard", {
                    attrs: {
                      label: "URL",
                      "help-text":
                        "Type or paste the entire URL if applicable.",
                      "is-common-use": true,
                    },
                    on: {
                      "generate-reference-preview":
                        _vm.generateReferencePreview,
                    },
                    model: {
                      value: _vm.url,
                      callback: function ($$v) {
                        _vm.url = $$v
                      },
                      expression: "url",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm.$route.meta.module === _vm.$enums.Module.PAPER_EDIT &&
        _vm.referenceFormatTypeId === _vm.$enums.Format.APA7
      ? _c("div", { key: "divPaperEdit" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "mb-4" },
            [
              _c("h3", [_vm._v("Choose a common advertisement format...")]),
              _vm._v(" "),
              _vm._l(_vm.advertisementsTypes, function (referenceType) {
                return _c("reference-type-select-row", {
                  key: referenceType.id,
                  attrs: {
                    "reference-type-description": referenceType.desc,
                    "reference-type-formats": referenceType.formats,
                    "reference-type-id": referenceType.id,
                    "reference-type-slug": referenceType.slug,
                    "reference-type-title": referenceType.title,
                  },
                })
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("strong", [_vm._v("APA & MLA Users")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert alert-warning" }, [
      _c("p", [
        _vm._v(
          "APA 7 does not include a specific format for advertisements. Instead, advertisements are formatted according to their parent source (e.g., use Magazine for an ad in a magazine)."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }