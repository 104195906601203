var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", {
      attrs: { for: _vm.fullId },
      domProps: { innerHTML: _vm._s(_vm.label) },
    }),
    _vm._v(" "),
    _vm.helpText
      ? _c("div", {
          staticClass: "help-text mb-1",
          domProps: { innerHTML: _vm._s(_vm.helpText) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "debounce",
          rawName: "v-debounce:750ms",
          value: _vm.debouncedGenerateReference,
          expression: "debouncedGenerateReference",
          arg: "750ms",
        },
      ],
      ref: "focusElement",
      class: [
        "form-control",
        {
          "form-control-primary": _vm.isCommonUseShow,
        },
      ],
      attrs: {
        autocomplete: "off",
        type: "text",
        id: _vm.fullId,
        "pendo-grab-text": _vm.pendoGrabText,
        placeholder: _vm.placeholder,
        role: _vm.role,
      },
      domProps: { value: _vm.value },
      on: {
        blur: _vm.blurInput,
        input: function ($event) {
          return _vm.typeInput($event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _vm.isInsertSection
      ? _c("div", { staticClass: "d-grid" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-hover btn-hover-success ms-2",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.insertSectionSymbol.apply(null, arguments)
                },
              },
            },
            [
              _c("span", { staticClass: "svg bi-plus-lg" }),
              _vm._v(" Insert section symbol - §\n\t\t"),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }