<!--
- Reference Wizard Inputs (ex: Book > Original Issue Number)
-- 2024.02.21
-->

<template>
	<div class="form-group">
		
		<label
			:for="fullId"
			v-html="label" />

		<div class="help-text mb-1" v-if="helpText" v-html="helpText" />

		<input
			autocomplete="off"
			ref="focusElement"
			type="text"
			:class="['form-control', {
				'form-control-primary': isCommonUseShow,
				}]"
			:id="fullId"
			:pendo-grab-text="pendoGrabText"
			:placeholder="placeholder"
			:role="role"
			:value="value"
			@blur="blurInput"
			@input="typeInput($event.target.value)"
			v-debounce:750ms="debouncedGenerateReference" /> 

		<div v-if="isInsertSection" class="d-grid">
			<button type="button" class="btn btn-sm btn-hover btn-hover-success ms-2" @click.prevent="insertSectionSymbol">
				<span class="svg bi-plus-lg" /> Insert section symbol - &sect;
			</button>
		</div>
	
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import mixinId from '@/mixins/id';

export default {
	name: 'FormInput',
	mixins: [mixinId],
	props:{
		isCommonUse: {	// used for Genesis ref types (blue outline)
			default: false,
			type: Boolean
		},
		isInsertSection:{	// used for statues - https://magical-design-system.webflow.io/add-in/wizard/ref/wizard-2-statutes
			default: false,
			type: Boolean
		},
		helpText: {
			type: String,
		},
		label: {
			type: String,
		},
		placeholder:{
			default: '',
			type: String
		},
		pendoGrabText:{
			default: '',
			type: String,
		},
		role: {
			type: String,
		},
		value: {
			type: String,
		},
	},
	computed:{
		fullId(){
			let urlFriendlyLabel = this.label.replace(/\W+/g, '-').toLowerCase() || '';
			return this.safeId + '_' + urlFriendlyLabel;
		},
		isCommonUseShow(){
			// only show the common use outline if the field is blank
			if(this.value === '') {
				return this.isCommonUse;
			} else {
				return false;
			}			
		},
	},
	methods:{
		blurInput(){
			this.$emit('generate-reference-preview');
		},

		debouncedGenerateReference(value){
			this.$emit('generate-reference-preview', value);
		},//e:debouncedGenerateReference

		// for now only used in Statues.vue with a prop modifer
		insertSectionSymbol(){
			let valueWithSymbol = this.$refs.focusElement.value;
			valueWithSymbol += '§';
			this.typeInput(valueWithSymbol);
			this.$refs.focusElement.focus();
		},

		typeInput(value){
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: 'formReferenceWizard',
			});

			this.$emit('input', value);
			this.$emit('generate-reference-preview', value);
		}//e:typeInput
	},
}
</script>
