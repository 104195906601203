var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.referenceFormatTypeId === _vm.$enums.Format.MLA9
      ? _c("div", { staticClass: "alert alert-warning" }, [
          _c("p", { staticClass: "ps-3" }, [
            _vm._v(
              "\n\t\t\tThis reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).\n\t\t"
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_EnactedFed",
                  value: "enactedFed",
                },
                domProps: { checked: _vm._q(_vm.type, "enactedFed") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "enactedFed"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Radio_EnactedFed" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tAn enacted federal bill or resolution\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_UnenactedFed",
                  value: "unenactedFed",
                },
                domProps: { checked: _vm._q(_vm.type, "unenactedFed") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "unenactedFed"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Radio_UnenactedFed" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tAn unenacted federal bill or resolution\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "enactedFed",
              expression: "type === 'enactedFed'",
            },
          ],
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v("What kind of bill or resolution are you citing?"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label": "What kind of bill or resolution are you citing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.generateReferencePreview,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "house" } }, [
                _vm._v(
                  "\n\t\t\t\t\tHouse of Representatives bill or resolution\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "senate" } }, [
                _vm._v("\n\t\t\t\t\tSenate bill or resolution\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "senateJoint" } }, [
                _vm._v("\n\t\t\t\t\tJoint Senate bill or resolution\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "houseJoint" } }, [
                _vm._v("\n\t\t\t\t\tJoint House bill or resolution\n\t\t\t\t"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "unenactedFed",
              expression: "type === 'unenactedFed'",
            },
          ],
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v("What kind of bill or resolution are you citing?"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label": "What kind of bill or resolution are you citing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.generateReferencePreview,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "house" } }, [
                _vm._v(
                  "\n\t\t\t\t\tHouse of Representatives bill or resolution\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "senate" } }, [
                _vm._v("\n\t\t\t\t\tSenate bill or resolution\n\t\t\t\t"),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Bill or Resolution Title",
            "help-text":
              "Type the full name of the bill or resolution exactly as it appears in the work.",
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Shortened Title",
            "help-text":
              "If the work's title is long, include a shortened title for the in-text citation.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.shortTitle,
            callback: function ($$v) {
              _vm.shortTitle = $$v
            },
            expression: "shortTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Bill or Resolution Number",
            "help-text":
              'Only include the number of the bill or resolution. Don\'t include "S." or "H.R."',
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.billNumber,
            callback: function ($$v) {
              _vm.billNumber = $$v
            },
            expression: "billNumber",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Congressional Record Volume",
            "help-text":
              "Type the number of the Congressional Record Volume where the enacted bill/resolution can be found.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.volume,
            callback: function ($$v) {
              _vm.volume = $$v
            },
            expression: "volume",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Record Starting Page Number",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.pageNumber,
            callback: function ($$v) {
              _vm.pageNumber = $$v
            },
            expression: "pageNumber",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Congressional Session",
            "help-text":
              "Type the ordinal number for the Congressional session when this bill/resolution was considered.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.session,
            callback: function ($$v) {
              _vm.session = $$v
            },
            expression: "session",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Congressional Number Session",
            "help-text":
              "If there are multiple sessions for the meeting of Congress, type the ordinal numeral for the part of the session of Congress when the testimony or hearing occurred (e.g., 3rd).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.sessionPart,
            callback: function ($$v) {
              _vm.sessionPart = $$v
            },
            expression: "sessionPart",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Year",
            "is-common-use":
              _vm.referenceFormatTypeId == _vm.$enums.Format.MLA9
                ? true
                : false,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL (Web address)",
            "help-text": "Include the URL for the work if available.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }