<!--
- Religious Work
-- Reference Type Id: 37
-- 2024.03.15
-->

<template>
	<div>
		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes"
				header-text="Religious works usually don't include authors. Editors should be added for annotated texts." />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Title of the Work"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="bookTitle" />

			<form-input-reference-wizard
				label="Version"
				help-text="If your work is a specific version of the text, enter the full name or its abbreviation here."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="version" />

			<form-textarea-reference-wizard
				v-show="showTranslatorFields"
				label="Translated Title"
				help-text="If the original title is provide in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedTitle" />

			<form-input-reference-wizard
				label="Publication Year"
				help-text="List the year the work was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard
				label="Edition"
				help-text="For numbered editions, type the ordinal number (1st, 2nd, etc.). For named editions, type the name (e.g., 2nd ed., rev.)."
				@generate-reference-preview="generateReferencePreview"
				v-model="edition" />

			<control-publishers-v2
				field-type="reference" /> 

			<control-toggle-select
				:label="toggleSelect.doiUrl.label"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />

		</section>

		<section class="mb-5">
			<h3>Original Publication Information</h3>

			<form-input-reference-wizard
				label="Original Publication Year"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalPublicationDate" />
		</section>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeReligiousWork',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['editor', 'translator'];
			this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);

			// Religious Work by default will load without a contributor
			this.addContributorRole({
				type: 'editor',
			});
			
			this.addContributorRole({
				type: 'translator',
			});
			
			// check all contributors for a translator
			if(_has(this.referenceRules.data, 'contributors')){
				_forEach(this.referenceRules.data.contributors, (contributorBlock)=>{
					if(contributorBlock.type === 'translator'){
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					}
				});
			}
			
			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
}
</script>