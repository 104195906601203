<!--
- Videos
-- Reference Type Id: 47
-- 2024.06.20
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>
			
			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Streaming"
								value="streaming"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Streaming">
								A streaming (online) video
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="A video viewed through a video platform like YouTube, Vimeo, etc." />
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Webinar"
								value="webinar"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Webinar">
								A recorded webinar
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-html="true"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="This is for viewing a webinar that can be accessed by others on a website. If the webinar recording isn&lsquo;t available, cite it as a Personal Communication." />
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type === 'streaming'">
				<label>What kind of streaming video are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of streaming video are you referencing">
					<option value="posted">
						A video posted on a streaming site (e.g., video on YouTube)
					</option>
					<option value="hosted">
						A video on its own website (e.g., video on TED website)
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
				<h3>Publication Information</h3>

				<form-textarea-reference-wizard
					label="Video Title"
					:help-text="calcTitleHelpText"
					:is-autosize="true"
					:is-common-use="true"
					:is-suggest-title-case="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="title" />

				<form-textarea-reference-wizard
					v-show="type !== 'webinar'"
					label="Video Description"
					help-text="Briefly describe the type of video (e.g., music video, documentary)."
					:is-autosize="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="description" />

				<form-datepicker
					id="RefWiz_PublicationDate" 
					label="Publication Date"
					:is-common-use="true"
					:paper-format="0"
					@date-set="dateSetPublication"
					@date-clear="dateClearPublication" />

				<control-publishers-v2
					v-show="kind === 'posted'"
					label="Platform"
					help-text-prop="The name of the site where the video was published or posted."
					field-type="reference"
					:is-show-city-state="false"
					:is-single-only="true" /> 

				<control-publishers-v2
					v-show="kind != 'posted'"
					label="Website Name"
					help-text-prop="The name of the site where the video was published or posted."
					field-type="reference"
					:is-show-city-state="false"
					:is-single-only="true" /> 
		
				<form-input-reference-wizard
					label="URL (Web address)"
					help-text="Enter the full URL (web address) for the video."
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="url" />
				
				<form-time-split-reference-wizard
					engine-value="runtime"
					label="Runtime"
					help-text="Enter the full length of the video."
					@generate-reference-preview="generateReferencePreview" />

		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';
import FormTimeSplitReferenceWizard from '@/components/reference-rules-genesis/_TimeSplitReferenceWizard';

export default {
	name: 'RefTypeVideos',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			this.addContributorRole({
				addDefault: (this.kind === 'hosted' || this.type === 'webinar') ? true : false,
				type: 'author', //individual
			});

			this.addContributorRole({
				type: 'groupAuthor', // groupOrganization
			});

			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind
				if(this.kind === 'posted' && this.type === 'streaming') {
					this.addContributorRole({
						addDefault: true,
						type: 'userName',
					});
				}
			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)
				// Update Used Contributor Types
				this.usedContributorTypes = ['author', 'groupAuthor', 'userName'];
			}//e:if:isRefRulesEmpty

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
	mounted(){
		if(this.kind === ''){
			this.kind = 'posted';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
		if(this.type === ''){
			this.type = 'streaming';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
	components: {
		FormTimeSplitReferenceWizard
	}
}
</script>