var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Apparatus",
                  value: "apparatus",
                },
                domProps: { checked: _vm._q(_vm.type, "apparatus") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "apparatus"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Combo",
                  value: "combo",
                },
                domProps: { checked: _vm._q(_vm.type, "combo") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "combo"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
            "contributor-label": "Apparatus/Equipment Company",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Name of the Apparatus or Equipment",
            "help-text": _vm.calcTitleHelpText,
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Model Number",
            "help-text":
              "Enter the model number if it is not included in the name above.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.model,
            callback: function ($$v) {
              _vm.model = $$v
            },
            expression: "model",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Year of Release",
            "help-text":
              "Type the year the apparatus or equipment was released.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: { label: "URL (Web address)" },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Radio_Apparatus" },
      },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tAn apparatus or piece of equipment (stand alone without software)\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-html": "true",
            "data-bs-placement": "bottom",
            title:
              "Use this if the apparatus or equipment doesn&rsquo;t include its own software.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Radio_Combo" } },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tApparatus or equipment with software included\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "This reference type is for equipment that includes its own proprietary software along with the physical machine.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }