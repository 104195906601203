var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.referenceFormatTypeId === _vm.$enums.Format.MLA9
      ? _c("div", { staticClass: "alert alert-warning" }, [
          _c("p", { staticClass: "ps-3" }, [
            _vm._v(
              "\n\t\t\tThis reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).\n\t\t"
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Testimony",
                  value: "testimony",
                },
                domProps: { checked: _vm._q(_vm.type, "testimony") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "testimony"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Radio_Testimony" },
                },
                [_vm._v("\n\t\t\t\t\t\t\tFederal Testimony\n\t\t\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Hearing",
                  value: "hearing",
                },
                domProps: { checked: _vm._q(_vm.type, "hearing") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "hearing"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Radio_Hearing" },
                },
                [_vm._v("\n\t\t\t\t\t\t\tFederal Hearing\n\t\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "testimony",
              expression: "type === 'testimony'",
            },
          ],
          key: "divKindTestimony",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [_vm._v("What kind of testimony are you referencing?")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label": "What kind of testimony are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "house" } }, [
                _vm._v(
                  "\n\t\t\t\t\tTestimony for a House of Representatives Committee\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "senate" } }, [
                _vm._v(
                  "\n\t\t\t\t\tTestimony for a Senate Committee\n\t\t\t\t"
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "hearing",
              expression: "type === 'hearing'",
            },
          ],
          key: "divKindHearing",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [_vm._v("What kind of hearing are you referencing?")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label": "What kind of hearing are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "house" } }, [
                _vm._v(
                  "\n\t\t\t\t\tHearing for a House of Representatives Committee\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "senate" } }, [
                _vm._v("\n\t\t\t\t\tHearing for a Senate Committee\n\t\t\t\t"),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.type === "testimony",
            expression: "type === 'testimony'",
          },
        ],
        staticClass: "mb-5",
      },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Testimony or Hearing Title",
            "help-text":
              "Only capitalize the first word of the title and any proper nouns.",
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Shortened Title",
            "help-text":
              "If the work's title is long, include a shortened title for the in-text citation.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.shortTitle,
            callback: function ($$v) {
              _vm.shortTitle = $$v
            },
            expression: "shortTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Committee Name",
            "help-text": _vm.calcHelpTextCommitteeName,
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.committeeName,
            callback: function ($$v) {
              _vm.committeeName = $$v
            },
            expression: "committeeName",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Congressional Number",
            "help-text":
              'Type the Congressional number when the testimony or hearing occurred (e.g., "110" for the 110th Congress).',
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.session,
            callback: function ($$v) {
              _vm.session = $$v
            },
            expression: "session",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Congressional Number Session",
            "help-text":
              "If there are multiple sessions for the meeting of Congress, type the ordinal numeral for the part of the session of Congress when the testimony or hearing occurred (e.g., 3rd).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.sessionPart,
            callback: function ($$v) {
              _vm.sessionPart = $$v
            },
            expression: "sessionPart",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Date of the Hearing or Testimony",
            "help-text":
              "List the date the testimony or hearing was conducted. Include at least the year.",
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL (Web address)",
            "help-text": "Include the URL for the work if available.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }