var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Patent Name",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Patent Number",
            "help-text":
              "Type the patent number exactly as it appears in the patent.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.number,
            callback: function ($$v) {
              _vm.number = $$v
            },
            expression: "number",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            label: "Date Patent Filed",
            "help-text":
              "Type the date that the patent was filed. Enter as much information as is available.",
            "is-common-use": true,
            "paper-format": 0,
            id: "RefWiz_FiledDate",
          },
          on: {
            "date-clear": _vm.dateClearFiled,
            "date-set": _vm.dateSetFiled,
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            label: "Year Patent Issued",
            "help-text":
              "List the year the patent was issued by the U.S. Patent Office.",
            "is-common-use": true,
            "paper-format": 0,
            id: "RefWiz_PublicationDate",
          },
          on: {
            "date-clear": _vm.dateClearPublication,
            "date-set": _vm.dateSetPublication,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL",
            "help-text": "Enter a url for the patent if available.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }