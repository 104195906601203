<!--
- Website
-- Reference Type Id: 3
-- 2024.01.31
-->

<template>
	<div>
		<reference-type-switcher
			v-if="isThirdPartyLookUp"
			original-reference-type-id="3" />

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>
	
		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Page Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-bracket-help="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="pageTitle" />

			<form-textarea-reference-wizard
				label="Translated Page Title"
				help-text="Type the translated title for the web page."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedTitle" />

			<form-textarea-reference-wizard
				label="Website Name"
				help-text="Type the name of the website or organization responsible for the site exactly as it appears in the publication."
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="websiteTitle" />

			<form-datepicker
				@date-clear="dateClearPublication"
				@date-set="dateSetPublication"
				:is-common-use="true"
				:paper-format="0"
				id="RefWiz_PublicationDate" 
				label="Publication Date" />

			<form-input-reference-wizard
				label="Format"
				help-text="Only include a format if the webpage doesn&#39;t fit into another category better (a PDF of a journal article) or isn&#39;t a standard webpage."
				@generate-reference-preview="generateReferencePreview"
				v-model="format" />
		</section>

		<section class="mb-0">
			<h3>Digital Information</h3>

			<form-input-reference-wizard
				@generate-reference-preview="generateReferencePreview"
				:is-common-use="true"
				label="URL (Web address)"
				help-text="Type the full web address. Query strings (text after a '?' in a URL) may optionally be omitted."
				v-model="url" />

			<form-datepicker
				@date-clear="dateClearRetrieval"
				@date-set="dateSetRetrieval"
				:is-today-button="true"
				:paper-format="0"
				help-text="If the webpage may change in the future, enter the date you accessed the page for your research."
				id="RefWiz_RetrievalDate" 
				label="Retrieval Date" />
		</section>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';
import ReferenceTypeSwitcher from '@/components/reference-rules-genesis/ReferenceTypeSwitcher';

export default {
	name: 'RefTypeWebsite',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['author', 'editor', 'groupAuthor', 'userName', 'translator'];
			this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);
		
			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});
			
			this.addContributorRole({
				type: 'editor',
			});

			this.addContributorRole({
				type: 'groupAuthor',
			});

			this.addContributorRole({
				type: 'userName',
			});

			this.addContributorRole({
				type: 'translator',
			});
			
			// check all contributors for a translator
			if(_has(this.referenceRules.data, 'contributors')){
				_forEach(this.referenceRules.data.contributors, (contributorBlock)=>{
					if(contributorBlock.type === 'translator'){
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					}
				});
			}

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});

		},//e:calcContributors
		
		dateClearRetrieval(){
			this.retrievalDate = '';
			this.generateReferencePreview();
		},
		dateSetRetrieval(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.retrievalDate = newDate;
				this.generateReferencePreview();
			}
		},
	},
	components:{
		ReferenceTypeSwitcher,
	},
}
</script>