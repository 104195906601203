<!--
- Government Reports (Regulations)
-- Reference Type Id: 20
-- 2024.06.06
-->

<template>
	<div>
		<div v-if="referenceFormatTypeId === $enums.Format.MLA9" class="alert alert-warning">
			<p class="ps-3">
				This reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).
			</p>
		</div>

		<section class="mb-5">
			<h3>Format Options</h3>
			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Report"
								value="report"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Radio_Report">
								A federal report
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Regulation"
								value="regulation"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Radio_Regulation">
								A federal regulation
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type === 'report'" key="divKindReport">
				<label>What kind of federal report are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of federal report are you referencing?">
					<option value="senate">
						Senate report
					</option>
					<option value="house">
						House of Representatives Report
					</option>
				</select>
			</div>
			<div class="form-group mb-0 py-2" v-show="type === 'regulation'" key="divKindRegulation">
				<label>What kind of regulation are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of regulation are you referencing?">
					<option value="codified">
						A codified regulation
					</option>
					<option value="uncodified">
						Regulation hasn't been codified yet
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:is-add-another="false"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-input-reference-wizard
				v-if="type === 'report'"
				label="Title"
				help-text="Type the name of the report exactly as it appears in the work."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />
			
			<form-input-reference-wizard
				v-show="type === 'report'"
				label="Report Number"
				help-text="Type the number of the report."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="reportNumber" />

			<form-input-reference-wizard
				v-show="type === 'regulation'"
				label="Regulation Name or Number"
				help-text="Enter either the regulation's name or number exactly as it appears in the work."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />
			
			<form-input-reference-wizard
				v-show="type === 'regulation'"
				label="Shortened Title"
				help-text="If the work's title is long, include a shortened title for the in-text citation."
				@generate-reference-preview="generateReferencePreview"
				v-model="shortTitle" />

			<form-input-reference-wizard
				v-show="type === 'report'"
				label="Congressional Session"
				help-text="Type the ordinal number for the Congressional session when this bill/resolution was considered."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="session" />

			<form-input-reference-wizard
				v-show="type === 'report'"
				label="Congressional Number Session"
				help-text="If there are multiple sessions for the meeting of Congress, type the ordinal numeral for the part of the session of Congress when the testimony or hearing occurred (e.g., 3rd)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="sessionPart" />

			<form-input-reference-wizard
				v-show="kind === 'codified'"
				label="<em>Code of Federal Regulations</em> Volume Number"
				help-text="Type the number of the <em>Code of Federal Regulations</em> where the regulation can be found."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="volume" />

			<form-input-reference-wizard
				v-show="kind === 'codified'"
				label="Section Number"
				help-text="Enter the section number for the regulation. Don&#39;t include the section symbol (&sect;)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="section" />

			<form-input-reference-wizard 
				v-show="kind !== 'uncodified'"
				label="Year"
				help-text="List the year the report was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />
			
			<div v-show="kind === 'uncodified'">
				<form-input-reference-wizard
					label="Federal Register Volume"
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="proposedVolume" />

				<form-input-reference-wizard 
					label="Federal Register Page Number"
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="pageNumber" />
				
				<form-datepicker
					id="RefWiz_ProposedDate" 
					label="Proposed Date"
					:paper-format="0"
					@date-set="dateSetProposed"
					@date-clear="dateClearProposed" />
				
				<form-input-reference-wizard
					v-if="kind !== 'uncodified'"
					label="Proposed <em>Code of Federal Regulations</em> Volume Number"
					help-text="Type the number of the <em>Code of Federal Regulations</em> where the regulation can be found."
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="volume" />

				<form-input-reference-wizard
					label="Proposed Section Number"
					help-text="Enter the section number for the regulation. Don&#39;t include the section symbol (&sect;)."
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="section" />
			</div>
			
			<form-input-reference-wizard
				label="URL (Web address)"
				help-text="Include the URL for the work if available."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />
		</section>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeGovernmentReports',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind
				if(this.type === 'report'){
					// Committee Name
					this.addContributorRole({
						addDefault: true,
						type: 'groupAuthor',	// committeeName
					});
				} else {
					// Department Name
					this.addContributorRole({
						addDefault: true,
						type: 'groupAuthor',	// departmentName
					});
				}
			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)
			
				// nextTick here to let contributors load? no idea but it works
				this.$nextTick(()=>{
					if(this.type === 'report'){	
						_forEach(this.contributors, (contributorBlock)=>{
							contributorBlock.type = 'groupAuthor';
							this.usedContributorTypes.push('groupAuthor');
						});
					} else {
						_forEach(this.contributors, (contributorBlock)=>{
							contributorBlock.type = 'groupAuthor';
							this.usedContributorTypes.push('groupAuthor');
						});
					}

					// Update Used Contributor Types
					this.usedContributorTypes = ['groupAuthor'];
				});
			}//e:if:isRefRulesEmpty
			
			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
		
		dateClearProposed(){
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'proposedDate',
				value: ''
			});
			this.generateReferencePreview();
		},
		dateSetProposed(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'proposedDate',
					value: newDate
				});
				this.generateReferencePreview();
			}
		},

		typeChange(){
			// changing radio button 'type' should reselect the first option in the 'kind' dropdown
			if(this.type === 'report'){
				this.kind = 'senate';
			} else {
				this.kind = 'codified';
			}
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted() {
		if(this.kind === ''){
			this.kind = 'senate';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}

		if(this.type === ''){
			this.type = 'report';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>