<!--
- Reviews
-- Reference Type Id: 38
-- 2024.06.04
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Periodical"
								value="periodical"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Periodical">
								Review in a journal, newspaper, or magazine
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Website"
								value="website"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Website">
								Review on a website
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Blog"
								value="blog"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Blog">
								Review on a blog
							</label>
						</div>
					</div>
				</div>
				
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2">
				<label>What kind of article are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of article are you referencing?">
					<option value="book">
						A book
						</option>
					<option value="film">
						A film or movie
					</option>
					<option value="album">
						A music album
					</option>
					<option value="song">
						A song
					</option>
					<option value="tvSeries">
						A television series
					</option>
					<option value="tvEpisode">
						A television episode
					</option>
					<option value="other">
						Other
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Review Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-textarea-reference-wizard
				v-show="kind === 'other'"
				label="Type of Reviewed Work"
				:is-autosize="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="workType" />

			<form-datepicker
				id="RefWiz_PublicationDate" 
				label="Publication Date"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />
			
			<form-textarea-reference-wizard
				:label="calcReviewedTitleLabel"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="reviewedTitle" />
		
			<form-input-reference-wizard
				v-show="type == 'periodical'"
				label="Periodical Name"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="periodical" />
			
			<form-input-reference-wizard
				v-show="type == 'periodical'"
				label="Periodical Volume"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="volume" />
			
			<form-input-reference-wizard
				v-show="type == 'periodical'"
				label="Periodical Issue"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="issue" />
			
			<form-input-reference-wizard
				v-show="type == 'periodical'"
				label="Review Page Numbers"
				help-text="Give the page numbers that include the entry (ex. 45-78). Discontinuous pages are separated with comma and space. Ex. 45-78, 93."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="pageNumbers" />

			<form-input-reference-wizard
				v-show="type == 'website'"
				label="Website Name"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="website" />
			
			<form-input-reference-wizard
				v-show="type == 'blog'"
				label="Blog Name"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="blog" />
			
			<form-input-reference-wizard
				v-show="type == 'periodical'"
				label="DOI for the Review"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="doi" />

			<form-input-reference-wizard
				label="URL for the Review"
				help-text="Type or paste the entire URL if applicable."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />
		</section>

	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeReviews',
	mixins: [mixinReferenceTypeGenesis],
	computed:{
		calcReviewedTitleLabel(){
			switch(this.kind){
				case 'book':
					return 'Reviewed Book Title';
				case 'film':
					return 'Reviewed Film Title';
				case 'album':
					return 'Reviewed Album Title';
				case 'song':
					return 'Reviewed Song Title';
				case 'tvSeries':
					return 'Reviewed TV Series Title';
				case 'tvEpisode':
					return 'Reviewed TV Episode Title';
				default:
					return 'Reviewed Work Title';
			}
		}
	},
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			this.addContributorRole({
				addDefault: true,
				type: 'author',	//reviewer
			});

			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind
				if(this.kind === 'book') {
					this.addContributorRole({
						addDefault: true,
						type: 'reviewAuthor',
					});
					this.addContributorRole({
						type: 'reviewEditor',
					});
				} else if(this.kind === 'film' || this.kind === 'tvSeries' || this.kind === 'tvEpisode' ) {
					this.addContributorRole({
						addDefault: true,
						type: 'reviewDirector',
					});
					this.addContributorRole({
						type: 'reviewWriter',
					});
					this.addContributorRole({
						type: 'reviewWriterDirector',
					});
				} else if(this.kind === 'album' || this.kind === 'song') {
					this.addContributorRole({
						addDefault: true,
						type: 'reviewArtist',
					});
					this.addContributorRole({
						type: 'reviewGroupArtist',
					});
				} else if(this.kind === 'other') {
					this.addContributorRole({
						addDefault: true,
						type: 'reviewComposer',
					});
				}

			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)
				// Update Used Contributor Types
				this.usedContributorTypes = ['author','reviewAuthor','reviewEditor', 'reviewDirector', 'reviewWriter','reviewWriterDirector', 'reviewArtist', 'reviewGroupArtist', 'reviewComposer'];
			}//e:if:isRefRulesEmpty

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});

		},//e:calcContributors
	},
	mounted(){
		if(this.kind === ''){
			this.kind = 'book';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
		if(this.type === ''){
			this.type = 'periodical';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>