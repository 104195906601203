var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Published",
                  value: "published",
                },
                domProps: { checked: _vm._q(_vm.type, "published") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "published"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "radio", id: "RefWiz_Radio_Raw", value: "raw" },
                domProps: { checked: _vm._q(_vm.type, "raw") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "raw"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Title of the Dataset",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Version Information",
            "help-text":
              "If your work includes a version number or other identifying information, type it here.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.version,
            callback: function ($$v) {
              _vm.version = $$v
            },
            expression: "version",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "published",
              expression: "type === 'published'",
            },
          ],
          attrs: {
            label: "Publication Year",
            "help-text": "Enter the year the data was published or archived.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "raw",
              expression: "type === 'raw'",
            },
          ],
          attrs: {
            label: "Collection Year",
            "help-text": "Enter the year the data was collected.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "published",
              expression: "type === 'published'",
            },
          ],
          attrs: { "field-type": "reference" },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "raw",
              expression: "type === 'raw'",
            },
          ],
          attrs: {
            "button-text": "data source",
            label: "Data Source",
            "field-type": "reference",
          },
        }),
        _vm._v(" "),
        _c("control-toggle-select", {
          attrs: {
            label: _vm.toggleSelect.doiUrl.label,
            "is-common-use": _vm.toggleSelect.doiUrl.isCommonUse,
            options: _vm.toggleSelect.doiUrl.options,
            "group-name": "doiUrl",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "raw",
              expression: "type === 'raw'",
            },
          ],
          attrs: {
            "help-text":
              "If the data may be amended or changed in the future, enter the date you accessed the work.",
            id: "RefWiz_RetrievalDate",
            label: "Retrieval Date",
            "is-today-button": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetRetrieval,
            "date-clear": _vm.dateClearRetrieval,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Radio_Published" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tA published dataset\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            "data-bs-html": "true",
            title:
              "This is for data sets that have been published or archived for other&rsquo;s use.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Radio_Raw" } },
      [
        _vm._v("\n\t\t\t\t\t\t\tUnpublished raw data\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "Use this reference type for data sets that are only available directly or have yet to be formally published. This can include open-ended data sets that change over time.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }