<!--
- ReferenceTypeSwitcher

- Jun 2022 - 3plu ref need the ability to change RefTypes:
-- https://perrla.monday.com/boards/1892814374/pulses/2837254209
-- https://magical-design-system.webflow.io/add-in/refs-cits/reference-form
-- https://docs.google.com/document/d/1F89B6R9BX6aX3r16wseJHB3LZ2p5JI-wesMh_BtrErc/edit
-->

<template>
	<section class="mb-5">
		<h3>Change the reference type</h3>
		<div class="help-text mb-1">
			Switch the type of reference PERRLA will create with the below information.
		</div>

		<form-select
			:select-object="referenceTypeSelectList"
			v-model="refererenceTypeId" />

	</section>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import FormSelect from '@/components/form/Select';

export default {
	name: 'ReferenceTypeSwitcher',
	props:{
		originalReferenceTypeId:{
			required: true,
			type: String,
		}
	},
	watch: {
		refererenceTypeId(newValue, oldValue){
			if(oldValue){
				this.changeReferenceType();
			}
		}
	},

	data() {
		return {
			localReferenceRulesData: null,		// local copy that gets edited
			refererenceTypeId: null,
		}
	},
	computed:{
		referenceTypeSelectList(){
			return [
				{
					value: '2',
					name: 'Book',
				},
				{
					value: '4',
					name: 'Gray Literature',
				},
				{
					value: '1',
					name: 'Journal',
				},
				{
					value: '3',
					name: 'Website',
				},
			];
		},//e:paperFormatSelectList
	},
	methods: {
		changeReferenceType(){
			// console.log('changeReferenceType');
			
			// console.log(this.refererenceTypeId); // this is the reference type id the user is changing TO

			this.localReferenceRulesData = _cloneDeep(this.$store.state.referenceRules.rulesObjectData);
			
			// console.log('this.localReferenceRulesData.publicationDate');
			// console.log(this.localReferenceRulesData.publicationDate);
			// console.log(this.localReferenceRulesData.kind);

			switch(this.refererenceTypeId){
				case '1':	// to Journal
					console.log('to Journal');
					
					this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', 'Journal');

					if(_has(this.localReferenceRulesData, 'bookTitle') && this.localReferenceRulesData.bookTitle != ''){
						this.localReferenceRulesData.articleTitle = this.localReferenceRulesData.bookTitle;
						this.localReferenceRulesData.bookTitle = '';
					}

					switch(this.localReferenceRulesData.kind){
						case 'translated':
							this.localReferenceRulesData.kind = 'foreign';
							break;
						default:
							this.localReferenceRulesData.kind = 'article';
					}

					if(_has(this.localReferenceRulesData, 'originalBookTitle') && this.localReferenceRulesData.originalBookTitle != ''){
						this.localReferenceRulesData.originalArticleTitle = this.localReferenceRulesData.originalBookTitle;
						this.localReferenceRulesData.originalBookTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'pageTitle') && this.localReferenceRulesData.pageTitle != ''){
						this.localReferenceRulesData.articleTitle = this.localReferenceRulesData.pageTitle;
						this.localReferenceRulesData.pageTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'publishers')){
						if(this.localReferenceRulesData.publishers[0]){
							if(_has(this.localReferenceRulesData.publishers[0], 'name')){
								this.localReferenceRulesData.journalTitle = this.localReferenceRulesData.publishers[0].name;
							}
						}
					}

					this.localReferenceRulesData.type = 'journal';

					if(_has(this.localReferenceRulesData, 'translatedTitle') && this.localReferenceRulesData.translatedTitle != ''){
						this.localReferenceRulesData.translatedArticleTitle = this.localReferenceRulesData.translatedTitle;
						this.localReferenceRulesData.translatedTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'websiteTitle') && this.localReferenceRulesData.websiteTitle != ''){
						this.localReferenceRulesData.journalTitle = this.localReferenceRulesData.websiteTitle;
						this.localReferenceRulesData.websiteTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'workTitle') && this.localReferenceRulesData.workTitle != ''){
						this.localReferenceRulesData.articleTitle = this.localReferenceRulesData.workTitle;
						this.localReferenceRulesData.workTitle = '';
					}

					break;

				case '2':	// to Book
					console.log('to Book');
					
					this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', 'Book');

					if(_has(this.localReferenceRulesData, 'articleTitle') && this.localReferenceRulesData.articleTitle != ''){
						this.localReferenceRulesData.bookTitle = this.localReferenceRulesData.articleTitle;
						this.localReferenceRulesData.articleTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'description') && this.localReferenceRulesData.description != ''){
						this.localReferenceRulesData.edition = this.localReferenceRulesData.description;
						this.localReferenceRulesData.description = '';
					}

					if(_has(this.localReferenceRulesData, 'journalTitle') && this.localReferenceRulesData.journalTitle != ''){
						this.localReferenceRulesData.publishers = [];
						this.localReferenceRulesData.publishers.push({
							name: this.localReferenceRulesData.journalTitle,
							type: 'reference'
						});
						this.localReferenceRulesData.journalTitle = '';
					}

					switch(this.localReferenceRulesData.kind){
						case 'foreign':
							this.localReferenceRulesData.kind = 'translated';
							break;
						default:
							this.localReferenceRulesData.kind = 'whole';
					}

					if(_has(this.localReferenceRulesData, 'originalArticleTitle') && this.localReferenceRulesData.originalArticleTitle != ''){
						this.localReferenceRulesData.originalBookTitle = this.localReferenceRulesData.originalArticleTitle;
						this.localReferenceRulesData.originalArticleTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'originalJournalTitle') && this.localReferenceRulesData.originalJournalTitle != ''){
						this.localReferenceRulesData.publishers = [];
						this.localReferenceRulesData.publishers.push({
							name: this.localReferenceRulesData.originalJournalTitle,
							type: 'original'
						});
						this.localReferenceRulesData.originalJournalTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'pageTitle') && this.localReferenceRulesData.pageTitle != ''){
						this.localReferenceRulesData.bookTitle = this.localReferenceRulesData.pageTitle;
						this.localReferenceRulesData.pageTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'translatedArticleTitle') && this.localReferenceRulesData.translatedArticleTitle != ''){
						this.localReferenceRulesData.translatedTitle = this.localReferenceRulesData.translatedArticleTitle;
						this.localReferenceRulesData.translatedArticleTitle = '';
					}

					this.localReferenceRulesData.type = 'book';

					if(_has(this.localReferenceRulesData, 'websiteTitle') && this.localReferenceRulesData.websiteTitle != ''){
						this.localReferenceRulesData.publishers = [];
						this.localReferenceRulesData.publishers.push({
							name: this.localReferenceRulesData.websiteTitle,
							type: 'reference'
						});
						this.localReferenceRulesData.websiteTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'workTitle') && this.localReferenceRulesData.workTitle != ''){
						this.localReferenceRulesData.bookTitle = this.localReferenceRulesData.workTitle;
						this.localReferenceRulesData.workTitle = '';
					}

					break;

				case '3':	// to Website
					console.log('to Website');

					this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', 'Webpage');

					if(_has(this.localReferenceRulesData, 'articleTitle') && this.localReferenceRulesData.articleTitle != ''){
						this.localReferenceRulesData.pageTitle = this.localReferenceRulesData.articleTitle;
						this.localReferenceRulesData.articleTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'bookTitle') && this.localReferenceRulesData.bookTitle != ''){
						this.localReferenceRulesData.pageTitle = this.localReferenceRulesData.bookTitle;
						this.localReferenceRulesData.bookTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'journalTitle') && this.localReferenceRulesData.journalTitle != ''){
						this.localReferenceRulesData.websiteTitle = this.localReferenceRulesData.journalTitle;
						this.localReferenceRulesData.journalTitle = '';
					}

					this.localReferenceRulesData.kind = '';

					if(_has(this.localReferenceRulesData, 'publishers')){
						if(this.localReferenceRulesData.publishers[0]){
							if(_has(this.localReferenceRulesData.publishers[0], 'name')){
								this.localReferenceRulesData.websiteTitle = this.localReferenceRulesData.publishers[0].name;
							}
						}
					}

					this.localReferenceRulesData.type = '';

					if(_has(this.localReferenceRulesData, 'translatedArticleTitle') && this.localReferenceRulesData.translatedArticleTitle != ''){
						this.localReferenceRulesData.translatedTitle = this.localReferenceRulesData.translatedArticleTitle;
						this.localReferenceRulesData.translatedArticleTitle = '';
					}
					break;

				case '4': // to Grey Lit
					console.log('to grey lit');
					
					this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', 'Gray Literature (Reports, Briefs, Codes)');

					if(_has(this.localReferenceRulesData, 'articleTitle') && this.localReferenceRulesData.articleTitle != ''){
						this.localReferenceRulesData.workTitle = this.localReferenceRulesData.articleTitle;
						this.localReferenceRulesData.articleTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'bookTitle') && this.localReferenceRulesData.bookTitle != ''){
						this.localReferenceRulesData.workTitle = this.localReferenceRulesData.bookTitle;
						this.localReferenceRulesData.bookTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'edition') && this.localReferenceRulesData.edition != ''){
						this.localReferenceRulesData.description = this.localReferenceRulesData.edition;
						this.localReferenceRulesData.edition = '';
					}

					if(_has(this.localReferenceRulesData, 'journalTitle') && this.localReferenceRulesData.journalTitle != ''){
						this.localReferenceRulesData.publishers = [];
						this.localReferenceRulesData.publishers.push({
							name: this.localReferenceRulesData.journalTitle,
							type: 'reference'
						});
						this.localReferenceRulesData.journalTitle = '';
					}

					if(_has(this.localReferenceRulesData, 'pageTitle') && this.localReferenceRulesData.pageTitle != ''){
						this.localReferenceRulesData.workTitle = this.localReferenceRulesData.pageTitle;
						this.localReferenceRulesData.pageTitle = '';
					}

					this.localReferenceRulesData.type = 'whole';

					if(_has(this.localReferenceRulesData, 'websiteTitle') && this.localReferenceRulesData.websiteTitle != ''){
						this.localReferenceRulesData.publishers = [];
						this.localReferenceRulesData.publishers.push({
							name: this.localReferenceRulesData.websiteTitle,
							type: 'reference'
						});
						this.localReferenceRulesData.websiteTitle = '';
					}


					break;
			}//e:switch
			
			// console.log('this.localReferenceRulesData');
			// console.log(this.localReferenceRulesData);

			this.$store.commit('referenceRules/SET_RULES_OBJECT_DATA', this.localReferenceRulesData);

			this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', parseInt(this.refererenceTypeId));

		},//e:changeReferenceType

	},
	mounted() {
		this.refererenceTypeId = this.originalReferenceTypeId;
	},
	
	components: {
		FormSelect
	}
}
</script>
