<!--
- Reference Wizard Inputs (ex: Book > Title)
-->
<template>
	<div class="form-group">
		<label :class="[{'font-italic':isInvalid}]" :for="fullId">
			{{ label }}
		</label>
		
		<div class="help-text mb-1" v-if="helpText" v-html="helpText" />
		
		<textarea
			:class="[
				'form-control',
				{
					'is-invalid':isInvalid,
					'form-control-warning': isShowSuggestionTitleCase || isShowSuggestionAllCaps,
					'form-control-primary': isCommonUseShow
				}
			]"
			ref="focusElement"
			:id="fullId"
			:pendo-grab-text="pendoGrabText"
			:placeholder="placeholder"
			:rows="calcRows"
			:value="value"
			@blur="blurInput"
			@input="typeInput($event.target.value)" 
			v-debounce:750ms="debouncedGenerateReference" />
			
		<div class="invalid-feedback" v-if="isInvalid">
			{{ invalidErrorMessage }}
		</div>

		<div class="suggested-feedback" v-if="isShowSuggestionBracket" key="suggestedFeedbackBracket">
			It looks like you're typing a description for the title. Include the format of the work in your description, not in the Format field.
		</div>
		<div class="suggested-feedback" v-else-if="isShowSuggestionTitleCase" key="suggestedFeedbackTitleCase">
			Only capitalize the <strong>first word of the title &amp; subtitle</strong>, and <strong>proper nouns</strong> for titles.
		</div>
		<div class="suggested-feedback" v-else-if="isShowSuggestionAllCaps" key="suggestedFeedbackAllCaps">
			<strong>Only use all capital letters for acronyms.</strong> Words in all capital letters will be left in all capitals when formatting the Title in your reference
		</div>
	</div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import AS_ValidateForm from '@/services/app/validateForm';
import CheckSuggestedField from '@/helpers/suggest-field';
import config from '@/config';
import mixinExpandTextareaById from '@/mixins/expand-textarea-by-id';
import mixinId from '@/mixins/id';

export default {
	name: 'FormTextareaReferenceWizard',
	mixins: [mixinExpandTextareaById, mixinId],
	props:{
		formId: {
			type: String,
		},
		helpText: {
			type: String,
		},
		isAutosize:{
			default: false,
			type: Boolean
		},
		isLoadFocus: {
			default: false,
			type: Boolean
		},
		isCommonUse: {	// used for Genesis ref types (blue outline)
			default: false,
			type: Boolean
		},
		isSuggestBracketHelp: {
			default: false,
			type: Boolean
		},
		isSuggestTitleCase: {
			default: false,
			type: Boolean
		},
		label: {
			type: String,
		},
		placeholder:{
			default: '',
			type: String
		},
		pendoGrabText: {
			default: '',
			type: String,
		},
		tooltip: {
			type: String,
		},
		value: {
			type: String,
		},
	},
	data(){
		return {
			invalidErrorMessage: '',
			isInvalid: false,
			isShowSuggestionAllCaps: false,
			isShowSuggestionBracket: false,	// only shows true when a '[' is typed in a field with prop isSuggestBracketHelp: true 
			isShowSuggestionTitleCase: false,
		}
	},
	computed:{
		calcRows(){
			if(this.isAutosize){
				return "1";
			} else {
				return "3";
			}
		},
		fullId(){
			let urlFriendlyLabel = this.label.replace(/\W+/g, '-').toLowerCase() || '';
			return this.safeId + '_' + urlFriendlyLabel;
		},		
		isCommonUseShow(){
			// only show the common use outline if the field is blank
			if(this.value === '') {
				return this.isCommonUse;
			} else {
				return false;
			}			
		},
	},
	methods:{
		blurInput(){
			this.$emit('generate-reference-preview');
		},
		debouncedGenerateReference(value){
			AS_ValidateForm({
				singleFieldId: this.fullId
			}).then(()=>{
				this.$emit('generate-reference-preview', value);
			}).catch(()=>{});//e:AS_ValidateForm
		},//e:debouncedGenerateReference

		typeInput(value){
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: 'formReferenceWizard',
			});
			
			if(this.isAutosize){
				this.expandTextarea('focusElement');
			}

			if(this.isSuggestBracketHelp){
				this.isShowSuggestionBracket = CheckSuggestedField.bracketInput(value.charAt(0));
			}
			
			if(this.isSuggestTitleCase){
				this.isShowSuggestionTitleCase = CheckSuggestedField.titleCaseInput(value);
			}

			this.isShowSuggestionAllCaps = CheckSuggestedField.allCapsInput(value);
			
			this.$emit('input', value);
			this.$emit('generate-reference-preview', value);
		}//e:typeInput

	},
	created(){
		// burried under a next tick so the mixin refernece type can fully mount
		this.emitter.on('expandTextareaBus', ()=>{
			this.$nextTick(()=>{
				if(this.isAutosize){
					this.expandTextarea('focusElement');
				}
			});
		});
	},
	mounted(){
		if(this.isLoadFocus){
			this.$refs.focusElement.focus();
		}
		
		if(this.isAutosize){
			this.$nextTick(()=>{
				this.expandTextarea();
			});
		}
	},
	destroyed(){
		this.emitter.off('expandTextareaBus');
	},
}
</script>
