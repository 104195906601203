var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Description",
            "is-common-use":
              _vm.referenceFormatTypeId == _vm.$enums.Format.MLA9 ||
              _vm.referenceFormatTypeId == _vm.$enums.Format.Turabian9
                ? true
                : false,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.description,
            callback: function ($$v) {
              _vm.description = $$v
            },
            expression: "description",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Communication Date",
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert alert-warning" }, [
      _c("p", [
        _vm._v(
          "Personal Communications do not appear on the Reference Page. They are only used to create citations."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }