<!--
- Genesis Reference Types
-- 2023.07.07
-->

<template>
	<div>
		<!-- Use componets for each main reference type -->
		<journal
			v-if="referenceTypeId === 1" key="refTypeJournal"
			:is-third-party-look-up="isThirdPartyLookUp"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<book
			v-else-if="referenceTypeId === 2" key="refTypeBook"
			:is-third-party-look-up="isThirdPartyLookUp"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<website
			v-else-if="referenceTypeId === 3" key="refTypeWebsite"
			:is-third-party-look-up="isThirdPartyLookUp"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<gray-literature
			v-else-if="referenceTypeId === 4" key="refTypeGrayLiterature"
			:is-third-party-look-up="isThirdPartyLookUp"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />
		
		<advertisement
			v-else-if="referenceTypeId === 5" key="refTypeAdvertisement"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<ancient-classic-work
			v-else-if="referenceTypeId === 6" key="refTypeAncientClassicWork"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<anthology
			v-else-if="referenceTypeId === 7" key="refTypeAnthology"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<apparatus
			v-else-if="referenceTypeId === 8" key="refTypeApparatus"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<artwork
			v-else-if="referenceTypeId === 9" key="refTypeArtwork"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<bills-resolutions
			v-else-if="referenceTypeId === 10" key="refTypeBillsResolutions"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<blog
			v-else-if="referenceTypeId === 11" key="refTypeBlog"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<charts-maps
			v-else-if="referenceTypeId === 12" key="refTypeChartsMaps"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<conference-materials
			v-else-if="referenceTypeId === 13" key="refTypeConferenceMaterials"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<constitutions
			v-else-if="referenceTypeId === 14" key="refTypeConstitutions"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<datasets
			v-else-if="referenceTypeId === 15" key="refTypeDatasets"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<dissertations
			v-else-if="referenceTypeId === 16" key="refTypeDissertations"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<executive-orders
			v-else-if="referenceTypeId === 17" key="refTypeExecutiveOrders"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<federal-testimony
			v-else-if="referenceTypeId === 18" key="refTypeFederalTestimony"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<films
			v-else-if="referenceTypeId === 19" key="refTypeFilms"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<government-reports
			v-else-if="referenceTypeId === 20" key="refTypeGovernmentReports"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<grant
			v-else-if="referenceTypeId === 21" key="refTypeGrant"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<informal-works
			v-else-if="referenceTypeId === 22" key="refTypeInformalWorks"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<interview
			v-else-if="referenceTypeId === 23" key="refTypeInterview"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<international-conventions
			v-else-if="referenceTypeId === 24" key="refTypeInternationalConventions"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<lectures
			v-else-if="referenceTypeId === 25" key="refTypeLectures"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<magazine
			v-else-if="referenceTypeId === 26" key="refTypeMagazine"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<manuscript
			v-else-if="referenceTypeId === 27" key="refTypeManuscript"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<music
			v-else-if="referenceTypeId === 28" key="refTypeMusic"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<newspaper
			v-else-if="referenceTypeId === 29" key="refTypeNewspaper"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<online-comment
			v-else-if="referenceTypeId === 30" key="refTypeOnlineComment"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<pamphlets-brochures
			v-else-if="referenceTypeId === 31" key="refTypePamphletsBrochures"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<patent
			v-else-if="referenceTypeId === 32" key="refTypePatent"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<personal-communication
			v-else-if="referenceTypeId === 33" key="refTypePersonalCommunication"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<podcasts-radio
			v-else-if="referenceTypeId === 34" key="refTypePodcastsRadio"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<press-release
			v-else-if="referenceTypeId === 35" key="refTypePressRelease"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<reference-work
			v-else-if="referenceTypeId === 36" key="refTypeReferenceWork"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<religious-work
			v-else-if="referenceTypeId === 37" key="refTypeReligiousWork"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<reviews
			v-else-if="referenceTypeId === 38" key="refTypeReviews"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<social-media
			v-else-if="referenceTypeId === 39" key="refTypeSocialMedia"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<software
			v-else-if="referenceTypeId === 40" key="refTypeSoftware"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<speech
			v-else-if="referenceTypeId === 41" key="refTypeSpeech"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<statutes
			v-else-if="referenceTypeId === 42" key="refTypeStatutes"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<television
			v-else-if="referenceTypeId === 43" key="refTypeTelevision"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<tests-scales
			v-else-if="referenceTypeId === 44" key="refTypeTestsScales"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<us-federal-court
			v-else-if="referenceTypeId === 45" key="refTypeUsFederalCourt"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<us-state-court
			v-else-if="referenceTypeId === 46" key="refTypeUsStateCourt"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

		<videos-ref-type
			v-else-if="referenceTypeId === 47" key="refTypeVideos"
			:reference-rules-string="referenceRulesString"
			:reference-rules-prop="referenceRulesProp" />

	</div>
</template>

<script>
import _has from 'lodash/has';
import config from '@/config';

import Advertisement from '@/components/reference-rules-genesis/types/Advertisement';
import AncientClassicWork from '@/components/reference-rules-genesis/types/AncientClassicWork';
import Anthology from '@/components/reference-rules-genesis/types/Anthology';
import Apparatus from '@/components/reference-rules-genesis/types/Apparatus';
import Artwork from '@/components/reference-rules-genesis/types/Artwork';
import BillsResolutions from '@/components/reference-rules-genesis/types/BillsResolutions';
import Book from '@/components/reference-rules-genesis/types/Book';
import Blog from '@/components/reference-rules-genesis/types/Blog';
import ChartsMaps from '@/components/reference-rules-genesis/types/ChartsMaps';
import ConferenceMaterials from '@/components/reference-rules-genesis/types/ConferenceMaterials';
import Constitutions from '@/components/reference-rules-genesis/types/Constitutions';
import Datasets from '@/components/reference-rules-genesis/types/Datasets';
import Dissertations from '@/components/reference-rules-genesis/types/Dissertations';
import ExecutiveOrders from '@/components/reference-rules-genesis/types/ExecutiveOrders';
import FederalTestimony from '@/components/reference-rules-genesis/types/FederalTestimony';
import Films from '@/components/reference-rules-genesis/types/Films';
import GovernmentReports from '@/components/reference-rules-genesis/types/GovernmentReports';
import Grant from '@/components/reference-rules-genesis/types/Grant';
import GrayLiterature from '@/components/reference-rules-genesis/types/GrayLiterature';
import InformalWorks from '@/components/reference-rules-genesis/types/InformalWorks';
import Interview from '@/components/reference-rules-genesis/types/Interview';
import InternationalConventions from '@/components/reference-rules-genesis/types/InternationalConventions';
import Journal from '@/components/reference-rules-genesis/types/Journal';
import Lectures from '@/components/reference-rules-genesis/types/Lectures';
import Magazine from '@/components/reference-rules-genesis/types/Magazine';
import Manuscript from '@/components/reference-rules-genesis/types/Manuscript';
import Music from '@/components/reference-rules-genesis/types/Music';
import Newspaper from '@/components/reference-rules-genesis/types/Newspaper';
import OnlineComment from '@/components/reference-rules-genesis/types/OnlineComment';
import PamphletsBrochures from '@/components/reference-rules-genesis/types/PamphletsBrochures';
import Patent from '@/components/reference-rules-genesis/types/Patent';
import PersonalCommunication from '@/components/reference-rules-genesis/types/PersonalCommunication';
import PodcastsRadio from '@/components/reference-rules-genesis/types/PodcastsRadio';
import PressRelease from '@/components/reference-rules-genesis/types/PressRelease';
import ReferenceWork from '@/components/reference-rules-genesis/types/ReferenceWork';
import ReligiousWork from '@/components/reference-rules-genesis/types/ReligiousWork';
import Reviews from '@/components/reference-rules-genesis/types/Reviews';
import SocialMedia from '@/components/reference-rules-genesis/types/SocialMedia';
import Software from '@/components/reference-rules-genesis/types/Software';
import Speech from '@/components/reference-rules-genesis/types/Speech';
import Statutes from '@/components/reference-rules-genesis/types/Statutes';
import Television from '@/components/reference-rules-genesis/types/Television';
import TestsScales from '@/components/reference-rules-genesis/types/TestsScales';
import UsFederalCourt from '@/components/reference-rules-genesis/types/UsFederalCourt';
import UsStateCourt from '@/components/reference-rules-genesis/types/UsStateCourt';
import VideosRefType from '@/components/reference-rules-genesis/types/Videos';
import Website from '@/components/reference-rules-genesis/types/Website';

import ReferenceTypes from '@/services/reference/types';

export default {
	name: 'TypesComponent',
	props:{
		isThirdPartyLookUp:{
			default: false,
			type: Boolean,
		},

		// passed from Convert Ref
		referenceRulesProp: {
			default: () => [],
			type: [Array,Object],
		},
		
		// passed from Edit Ref
		referenceRulesString:{
			type: String,
		},

		// passed on all
		referenceTypeId: {
			required: true,
			type: Number
		},
	},
	watch: {
		$route(to, from) {
			let findRefType;
			
			if(config.platformId === config.enums.Platform.ONLINE){
				switch (to.name){
					case 'EditorCreateReferenceStep2':
					case 'PaperTabResearchCreateReferenceStep2':
					case 'ReferenceListCreateReferenceStep2':
						// if cross clicking - user is already on create step 2 so just update the ui?
						findRefType = ReferenceTypes.refTypes.find((refType)=>{
							return refType.slug === to.params.referenceTypeSlug;
						});
						break;
					case 'EditorEditReference':
						findRefType = ReferenceTypes.refTypes.find((refType)=>{
							return refType.id === this.$store.state.paperEdit.editReference.referenceTypeID;
						});
						break;
				}//e:switch

				if(findRefType){
					this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', findRefType.id);
					this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', findRefType.title);
				}
				
			} else {
				// if cross clicking - user is already on create step 2 so just update the ui?
				let findRefType = ReferenceTypes.refTypes.find((refType)=>{
					return refType.slug === to.params.referenceTypeSlug;
				});
				// console.log('findRefType');
				// console.log(findRefType);
				this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', findRefType.id);
				this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', findRefType.title);

			}
		}
	},
	mounted(){
		// look up this reference type id based on the route param slug
		this.$nextTick(()=>{
			this.$store.commit('forms/ADD_FORM_TO_LIST',{
				formId: 'formReferenceWizard'
			});

			let findRefType;
			
			if(config.platformId === config.enums.Platform.ONLINE){
				// weird case where i need to look for a reference type (from user searching 3plu for 'book' for example)
				switch (this.$route.name){
					case 'EditorCreateReferenceStep2':
					case 'OutlineCreateReferenceStep2':
					case 'PaperTabResearchCreateReferenceStep2':
					case 'ReferenceListCreateReferenceStep2':
						// Lookup by $route slug
						findRefType = ReferenceTypes.refTypes.find((refType)=>{
							return refType.slug === this.$route.params.referenceTypeSlug;
						});
						this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', findRefType.id);
						this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', findRefType.title);
						break;
					case 'ReferenceTabDetails':
						this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', this.$store.state.referenceEdit.openObject.referenceTypeID);
						break;
				}

			} else if(config.platformId === config.enums.Platform.CHROME_EXT){
				if(this.$route.name === 'CreateReferenceStep2'){
					// Lookup by $route slug
					let findRefType = ReferenceTypes.refTypes.find((refType)=>{
						return refType.slug === this.$route.params.referenceTypeSlug;
					});
					
					if(findRefType){
						chrome.storage.local.set({
							'referenceTypeId': findRefType.id,
							'referenceTypeName': findRefType.title
						}, () => {
							this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', findRefType.id);
							this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', findRefType.title);	
						});//set
					}
					
				} else if(this.$route.name == 'CreateStep3ThirdParty'){
					// 3plu - i need to look up the Reference type id here
					chrome.storage.local.get(['referenceTypeId', 'referenceTypeName'], (result) => {
						if(_has(result, 'referenceTypeId')){
							this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', result.referenceTypeId);
						}
						if(_has(result, 'referenceTypeName')){

							this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', result.referenceTypeName);
						}
					});

				}

			} else if(config.platformId === config.enums.Platform.ADD_IN){
				if(this.$route.name == 'CreateGenesisReferenceStep2'){
					// Lookup by $route slug
					let findRefType = ReferenceTypes.refTypes.find((refType)=>{
						return refType.slug === this.$route.params.referenceTypeSlug;
					});
					
					this.$store.commit('referenceRules/SET_REFERENCE_TYPE_ID', findRefType.id);
					this.$store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', findRefType.title);
				}
			}
		});
	},
	components: {
		Advertisement,
		AncientClassicWork,
		Anthology,
		Apparatus,
		Artwork,
		BillsResolutions,
		Blog,
		Book,
		ChartsMaps,
		ConferenceMaterials,
		Constitutions,
		Datasets,
		Dissertations,
		ExecutiveOrders,
		FederalTestimony,
		Films,
		GovernmentReports,
		Grant,
		GrayLiterature,
		InformalWorks,
		InternationalConventions,
		Interview,
		Journal,
		Lectures,
		OnlineComment,
		PamphletsBrochures,
		Patent,
		PersonalCommunication,
		PodcastsRadio,
		PressRelease,
		Magazine,
		Manuscript,
		Music,
		Newspaper,
		ReferenceWork,
		ReligiousWork,
		Reviews,
		SocialMedia,
		Software,
		Speech,
		Statutes,
		Television,
		TestsScales,
		UsFederalCourt,
		UsStateCourt,
		VideosRefType,
		Website
	},
	destroyed(){
		this.$store.commit('forms/REMOVE_FORM_BY_ID',{
			formId: 'formReferenceWizard'
		});
	},
}
</script>
