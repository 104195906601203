<!--
- Toggle Select
-- Used for URL/DOI where the user selects one option that changes the input 
-- is also used for Pages/Article so keep that in mind if you modify this 
-- 2024.04.04
-->

<template>
	<div class="form-group">
		<label>{{ label }}</label>

		<div class="help-text mb-1" v-if="selectedOptionHelpText" v-html="selectedOptionHelpText" />

		<div class="input-group">
			<span class="input-group-text">
				<select v-model="selectedOption" class="form-select" :aria-label="label">
					<option
						v-for="(option, optionKey) in calcOptions"
						:key="optionKey"
						:value="optionKey">
							{{ option.display }}
					</option>
				</select>
			</span>
		
			<input
				autocomplete="off"
				type="text"
				:aria-label="label"
				:class="['form-control', {'form-control-primary': isCommonUseShow}]"
				@blur="blurInput"
				@input="typeInput($event.target.value)"
				v-model="selectedOptionValue"
				v-debounce:750ms="debouncedGenerateReference" />
		</div>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';

export default {
	name: 'ToggleSelect',
	props:{
		defaultOption: {
			type: String,
		},
		isCommonUse: {	// used for APA7 ref types (blue outline)
			default: false,
			type: Boolean
		},
		isForceUrl:{
			default: false,
			type: Boolean
		},
		groupName: {
			type: String,
		},
		label:{
			type: String,
		},
		options:{
			type: Object,
		},
	},
	data() {
		return {
			selectedValue: '',
		}
	},
	watch:{
		isForceUrl(){
			this.updateToggleSelectUi();
		}
	},
	computed:{
		calcOptions(){
			if(this.isForceUrl){
				return {
					url: {
						display: 'URL',
						helpText: "Type or paste the entire URL",
						value: '',
					}
				}
			}
			return this.options;
		},
		selectedOption:{
			get(){
				return this.$store.state.referenceRules.formData.toggleSelect[this.groupName].selectedOption;
			},
			set(selectedOption){
				this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_SELECTED_OPTION', {
					groupName: this.groupName,
					selectedOption,
				});
			}
		},
		selectedOptionHelpText(){
			if(this.selectedOption){
				if(this.selectedOption.toLowerCase().includes('doi')){
					return "Type only the DOI number (e.g., 10.12345). Don't include the URL prefix (https://doi.org/).";

				} else if(this.selectedOption.toLowerCase().includes('url')){
					let modKey = 'Ctrl';
					let isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
					if (isMacLike) {
						modKey = 'Cmd';
					} 
					return 'Type or paste the entire URL. Paste with the keyboard shortcut (' + modKey + ' + V)';

				} else if(this.selectedOption.toLowerCase().includes('pages')){
					return "Enter the page numbers for the reference text (e.g., 45-78). Discontinuous pages are separated with a comma and a space (e.g., 45-78, 93).";
				}
			}
			return '';
		},
		selectedOptionValue:{
			get(){
				return this.$store.state.referenceRules.formData.toggleSelect[this.groupName].options[this.selectedOption].value;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_AT_GROUP', {
					groupName: this.groupName,
					value,
				});
			}
		},
		isCommonUseShow(){
			// only show the common use outline if the field is blank
			if(this.selectedValue === '') {
				return this.isCommonUse;
			} else {
				return false;
			}			
		},
	},
	methods: {
		blurInput(){
			this.$store.dispatch('referenceRules/generateReferencePreview');
		},
		debouncedGenerateReference(){
			_forEach(this.options, (optionData, optionKey)=>{
				if(optionKey === this.selectedOption) {
					// console.log('match');
					// console.log(optionData);
				} else {
					// unselected option - blank out it's value so the engine wont have to figure it out
					this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_CLEAR_UNSELECTED_OPTION', {
						groupName: this.groupName,
						unselectedOptionName: optionKey
					});
				}
			});

			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: 'formReferenceWizard',
			});
			
			this.$store.dispatch('referenceRules/generateReferencePreview');
		},

		typeInput(value){
			// only adding this so right click paste will work 
			this.$store.dispatch('referenceRules/generateReferencePreview');
		},//e:typeInput

		updateToggleSelectUi(){
			// this is called when force url is set to true, currently it's only used in Reference Work > Wikipedia
			this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_SELECTED_OPTION', {
				groupName: this.groupName,
				selectedOption: 'url',
			});
		},//e:updateToggleSelectUi
	},
	mounted(){
		if(this.defaultOption){
			this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_SELECTED_OPTION', {
				groupName: this.groupName,
				selectedOption: this.defaultOption,
			});
		}
	}
}
</script>
