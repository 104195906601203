<!--
- Online Comment
-- Reference Type Id: 30
-- 2024.06.14
-->
<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Book"
								value="forum"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Radio_Book">
								Comment on an online forum
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="Use this type for online forums like Reddit." />
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_BookChapter"
								value="comment"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Radio_BookChapter">
								Comment on an article
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="This is for citing a comment for an article, blog, or webpage." />
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type === 'comment'">
				<label>Is the comment on an article or webpage?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="Is the comment on an article or webpage?">
					<option value="article">
						Comment on an article
						</option>
					<option value="webpage">
						Comment on a webpage
						</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>
			
			<form-textarea-reference-wizard
				label="Title of the Forum Post"
				help-text="Capitalize the first word of the title, subtitle, and all proper nouns."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-textarea-reference-wizard
				label="Post Content"
				help-text="Enter the comment's full content here. Type it exactly as it appears in the post."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="content" />
			
			<form-textarea-reference-wizard
				v-show="type === 'comment'"
				label="Article or Page Title"
				help-text="Type the title of the article where you found the comment. Only capitalize the first word of the title and subtitle, and any proper nouns."
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-textarea-reference-wizard
				v-show="type === 'comment'"
				label="Source Title"
				help-text="Enter the name of the website or organization responsible for the page. Type the name exactly as it appears in the work."
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="source" />
			
			<form-input-reference-wizard
				v-show="type === 'forum'"
				label="Platform Name"
				help-text="Type the name of the social media platform or online forum."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="platform" />
		
			<form-datepicker
				id="RefWiz_PublicationDate" 
				label="Date of Comment"
				:is-all-common-use="true"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />
		
			<form-input-reference-wizard
				label="URL"
				help-text="Enter the full URL for the post or page you're citing. You can use a url shortener for very long URL's (i.e., Bit.ly)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />
		</section>
	</div>
</template>

<script>
import config from '@/config';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeOnlineComment',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['userName', 'groupAuthor', 'originalAuthor'];

			this.addContributorRole({
				addDefault: true,
				type: 'userName',		// Individual Commenter
			});

			this.addContributorRole({
				type: 'groupAuthor',	// Organizational Commenter
			});
			
			this.addContributorRole({
				addDefault: (this.referenceFormatTypeId === config.enums.Format.Turabian9) ? true : false,
				type: 'originalAuthor',	// Author of the Original Work
			});
			
			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors

		typeChange(){
			// changing radio button 'type' should reselect the first option in the 'kind' dropdown
			if(this.type === 'forum'){
				this.kind = '';
			} else {
				this.kind = 'article';
			}
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted() {
		if(this.type === ''){
			this.type = 'forum'
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>