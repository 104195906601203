<!--
- Personal Communication
-- Reference Type Id: 33
-- 2024.06.20
-->

<template>
	<div>
		<div class="alert alert-warning">
			<p>Personal Communications do not appear on the Reference Page. They are only used to create citations.</p>
		</div>
		
		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>
	
		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-input-reference-wizard
				label="Description"
				:is-common-use="(referenceFormatTypeId == $enums.Format.MLA9 || referenceFormatTypeId == $enums.Format.Turabian9) ? true : false"
				@generate-reference-preview="generateReferencePreview"
				v-model="description" />

			<form-datepicker
				id="RefWiz_PublicationDate" 
				label="Communication Date"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />
	
		</section>

	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypePersonalCommunication',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['author'];

			this.addContributorRole({
				addDefault: true,
				type: 'author',	//display as Personal Source
			});

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
}
</script>