<!--
- Ancient Classic Work
-- Reference Type Id: 6
-- 2023.10.11
-->

<template>
	<div>
		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>
	
		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Title of the Work"
				help-text="Sacred titles can be written in Title Case. Subtitles that aren't sacred should be in sentence case."
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="bookTitle" />

			<form-textarea-reference-wizard
				label="Translated Title"
				help-text="If the original title is provide in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedTitle" />

			<form-input-reference-wizard
				label="Publication Year"
				help-text="List the year this work was copyrighted/published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard
				label="Edition"
				help-text="For numbered editions, type the ordinal number (1st, 2nd, etc.). For named editions, type the name (e.g., 2nd ed., rev.)."
				@generate-reference-preview="generateReferencePreview"
				v-model="edition" />

			<control-publishers-v2
				field-type="reference" />
			
			<control-toggle-select
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />
		</section>

		<section class="mb-5">
			<h3>Original Publication Information</h3>
			<form-input-reference-wizard
				label="Original Publication Year"
				help-text="Add the abbreviation 'B.C.E.' after the year for ancient works (not 'B.C.'). If the date is approximate, place 'ca.' before the year (for circa)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalPublicationDate" />
		</section>
		
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeAncientClassicWork',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['author', 'editor', 'groupAuthor','translator'];
			this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);
			
			if(this.isRefRulesEmpty) {
				this.addContributorRole({
					addDefault: true,
					type: 'author',
				});
			}

			// check all contributors for a translator
			if(_has(this.referenceRules.data, 'contributors')){
				_forEach(this.referenceRules.data.contributors, (contributorBlock)=>{
					if(contributorBlock.type === 'translator'){
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					}
				});
			}

			this.sortContributorTypes();
		},//e:calcContributors
	},
}
</script>

