var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isThirdPartyLookUp
        ? _c("reference-type-switcher", {
            attrs: { "original-reference-type-id": "3" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "mb-5" },
        [
          _c("control-contributor", {
            attrs: {
              "contributors-prop": _vm.contributors,
              "used-contributor-types": _vm.usedContributorTypes,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "mb-5" },
        [
          _c("h3", [_vm._v("Publication Information")]),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            attrs: {
              label: "Page Title",
              "help-text": _vm.calcTitleHelpText,
              "is-autosize": true,
              "is-common-use": true,
              "is-suggest-bracket-help": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.pageTitle,
              callback: function ($$v) {
                _vm.pageTitle = $$v
              },
              expression: "pageTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            attrs: {
              label: "Translated Page Title",
              "help-text": "Type the translated title for the web page.",
              "is-autosize": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.translatedTitle,
              callback: function ($$v) {
                _vm.translatedTitle = $$v
              },
              expression: "translatedTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            attrs: {
              label: "Website Name",
              "help-text":
                "Type the name of the website or organization responsible for the site exactly as it appears in the publication.",
              "is-autosize": true,
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.websiteTitle,
              callback: function ($$v) {
                _vm.websiteTitle = $$v
              },
              expression: "websiteTitle",
            },
          }),
          _vm._v(" "),
          _c("form-datepicker", {
            attrs: {
              "is-common-use": true,
              "paper-format": 0,
              id: "RefWiz_PublicationDate",
              label: "Publication Date",
            },
            on: {
              "date-clear": _vm.dateClearPublication,
              "date-set": _vm.dateSetPublication,
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            attrs: {
              label: "Format",
              "help-text":
                "Only include a format if the webpage doesn't fit into another category better (a PDF of a journal article) or isn't a standard webpage.",
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.format,
              callback: function ($$v) {
                _vm.format = $$v
              },
              expression: "format",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "mb-0" },
        [
          _c("h3", [_vm._v("Digital Information")]),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            attrs: {
              "is-common-use": true,
              label: "URL (Web address)",
              "help-text":
                "Type the full web address. Query strings (text after a '?' in a URL) may optionally be omitted.",
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.url,
              callback: function ($$v) {
                _vm.url = $$v
              },
              expression: "url",
            },
          }),
          _vm._v(" "),
          _c("form-datepicker", {
            attrs: {
              "is-today-button": true,
              "paper-format": 0,
              "help-text":
                "If the webpage may change in the future, enter the date you accessed the page for your research.",
              id: "RefWiz_RetrievalDate",
              label: "Retrieval Date",
            },
            on: {
              "date-clear": _vm.dateClearRetrieval,
              "date-set": _vm.dateSetRetrieval,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }