var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Newspaper",
                  value: "newspaper",
                },
                domProps: { checked: _vm._q(_vm.type, "newspaper") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "newspaper"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Reprinted",
                  value: "reprinted",
                },
                domProps: { checked: _vm._q(_vm.type, "reprinted") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "reprinted"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Special",
                  value: "special",
                },
                domProps: { checked: _vm._q(_vm.type, "special") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "special"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(2),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "newspaper",
              expression: "type == 'newspaper'",
            },
          ],
          key: "divKindNewspaper",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v("What kind of newspaper article are you referencing?"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What kind of newspaper article are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "article" } }, [
                _vm._v("\n\t\t\t\t\tA single published article\n\t\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "foreign" } }, [
                _vm._v(
                  "\n\t\t\t\t\tForeign language or translated article\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "editorial" } }, [
                _vm._v("\n\t\t\t\t\tAn editorial article\n\t\t\t\t\t"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "reprinted",
              expression: "type == 'reprinted'",
            },
          ],
          key: "divKindReprinted",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v("What kind of reprinted article are you referencing?"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What kind of reprinted article are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "article" } }, [
                _vm._v("\n\t\t\t\t\tA single reprinted article\n\t\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "foreign" } }, [
                _vm._v(
                  "\n\t\t\t\t\tForeign language or translated article\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "editorial" } }, [
                _vm._v("\n\t\t\t\t\tA reprinted editorial\n\t\t\t\t\t"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "special",
              expression: "type == 'special'",
            },
          ],
          key: "divKindSpecial",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v("What part or kind of special issue are you referencing?"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What part or kind of special issue are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "issue" } }, [
                _vm._v("\n\t\t\t\t\tEntire special issue\n\t\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "section" } }, [
                _vm._v("\n\t\t\t\t\tSpecial section\n\t\t\t\t"),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type !== "special",
              expression: "type !== 'special'",
            },
          ],
          attrs: {
            label: "Article Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.articleTitle,
            callback: function ($$v) {
              _vm.articleTitle = $$v
            },
            expression: "articleTitle",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTranslatorFields,
              expression: "showTranslatorFields",
            },
          ],
          attrs: {
            label: "Translated Article Title",
            "help-text":
              "If the original article title is provide in another language, type the translated title.",
            "is-autosize": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.translatedArticleTitle,
            callback: function ($$v) {
              _vm.translatedArticleTitle = $$v
            },
            expression: "translatedArticleTitle",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "special",
              expression: "type === 'special'",
            },
          ],
          attrs: {
            label: "Issue/Section Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.issueTitle,
            callback: function ($$v) {
              _vm.issueTitle = $$v
            },
            expression: "issueTitle",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "special",
              expression: "type === 'special'",
            },
          ],
          attrs: {
            label: "Translated Issue/Section Title",
            "help-text":
              "If the original title is provide in another language, type the translated title.",
            "is-autosize": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.translatedIssueTitle,
            callback: function ($$v) {
              _vm.translatedIssueTitle = $$v
            },
            expression: "translatedIssueTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Newspaper Name",
            "help-text":
              "Type the title of the newspaper exactly as it appears in the publication. If it includes an ambiguous city name, put the state or country in parentheses after the city, e.g., Anytown (TN) Reporter.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.newspaperTitle,
            callback: function ($$v) {
              _vm.newspaperTitle = $$v
            },
            expression: "newspaperTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTranslatorFields,
              expression: "showTranslatorFields",
            },
          ],
          attrs: {
            label: "Translated Newspaper Name",
            "help-text":
              "If the original newspaper name is provide in another language, type the translated title.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.translatedNewspaperTitle,
            callback: function ($$v) {
              _vm.translatedNewspaperTitle = $$v
            },
            expression: "translatedNewspaperTitle",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Publication Date",
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.kind !== "advance" &&
                _vm.kind !== "inPress" &&
                _vm.kind != "issue",
              expression:
                "kind !== 'advance' && kind !== 'inPress' && kind != 'issue'",
            },
          ],
          attrs: {
            label: "Page Numbers",
            "help-text":
              "Give the page numbers that include the work (e.g., C1-C2). Discontinuous pages are separated with a comma and a space (e.g., A1, A4-A5).",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.referencePages,
            callback: function ($$v) {
              _vm.referencePages = $$v
            },
            expression: "referencePages",
          },
        }),
        _vm._v(" "),
        _c("control-toggle-select", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type !== "special",
              expression: "type !== 'special'",
            },
          ],
          attrs: {
            label: _vm.toggleSelect.doiUrl.label,
            "is-common-use": _vm.toggleSelect.doiUrl.isCommonUse,
            options: _vm.toggleSelect.doiUrl.options,
            "group-name": "doiUrl",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind !== "advance" && _vm.type !== "special",
              expression: "kind !== 'advance' && type !== 'special'",
            },
          ],
          attrs: {
            "help-text":
              "Only include if your article is in a changing database like UpToDate.",
            id: "RefWiz_RetrievalDate",
            label: "Retrieval Date",
            "is-today-button": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetRetrieval,
            "date-clear": _vm.dateClearRetrieval,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.type == "reprinted",
            expression: "type == 'reprinted'",
          },
        ],
        staticClass: "mb-5",
      },
      [
        _c("h3", [_vm._v("Original Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Original Article Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.originalArticleTitle,
            callback: function ($$v) {
              _vm.originalArticleTitle = $$v
            },
            expression: "originalArticleTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Original Newspaper Name",
            "help-text":
              "Type the full title of the newspaper, exactly as it appears in the publication.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.originalNewspaperTitle,
            callback: function ($$v) {
              _vm.originalNewspaperTitle = $$v
            },
            expression: "originalNewspaperTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Original Publication Year",
            "help-text": "List the year the work was originally published.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.originalPublicationDate,
            callback: function ($$v) {
              _vm.originalPublicationDate = $$v
            },
            expression: "originalPublicationDate",
          },
        }),
        _vm._v(" "),
        _c("control-toggle-select", {
          attrs: {
            label: _vm.toggleSelect.originalDoiUrl.label,
            "is-common-use": _vm.toggleSelect.originalDoiUrl.isCommonUse,
            options: _vm.toggleSelect.originalDoiUrl.options,
            "group-name": "originalDoiUrl",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Newspaper" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tNewspaper article\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "This reference is for citing a single newspaper article. This includes interviews.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Reprinted" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tReprinted article\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "Use this reference type for magazine articles that have been published more than once.  It includes both the original date of publication and the date of the version you are citing.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Special" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tSpecial issue or section\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "Use this reference type if you are citing an entire section or issue of a magazine.  If you are only citing one article in a special issue, select Magazine Article instead.",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }