<!--
- Magazine
-- Reference Type Id: 26
-- 2024.04.09
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Magazine"
								value="magazine"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Magazine">
								Magazine article
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip" 
									data-bs-placement="bottom" 
									role="tooltip"
									title="This reference is for citing a single magazine article." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Reprinted"
								value="reprinted"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Reprinted">
								Reprinted magazine article
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="Use this reference type for magazine articles that have been published more than once.  It includes both the original date of publication and the date of the version you are citing." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Special"
								value="special"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Special">
								Special issue or section
								<span
									class="svg bi-info-circle has-tooltip" 
									data-bs-toggle="tooltip" 
									data-bs-placement="bottom" 
									role="tooltip"
									title="Use this reference type if you are citing an entire section or issue of a magazine.  If you are only citing one article in a special issue, select Magazine Article instead." />
							</label>
						</div>
					</div>
				</div>
			</div>
		
			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type == 'magazine'" key="divKindMagazine">
				<label>What kind of article are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of article are you referencing?">
					<option value="article">
						A single published article
					</option>
					<option value="foreign">
						Foreign language or translated article
					</option>
					<option value="advance">
						Advance online publication
					</option>
					<option value="inPress">
						In-press article
					</option>
					<option value="editorial">
						Editorial
					</option>
					<option value="retracted">
						A retracted article
					</option>
				</select>
			</div>
			<div class="form-group mb-0 py-2" v-show="type == 'reprinted'" key="divKindReprinted">
				<label>What kind of reprinted article are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of reprinted article are you referencing?">
					<option value="article">
						A single article
					</option>
					<option value="foreign">
						Foreign language or translated article
					</option>
				</select>
			</div>
			<div class="form-group mb-0 py-2" v-show="type == 'special'" key="divKindSpecial">
				<label>What part or kind of special issue are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What part or kind of special issue are you referencing?">
					<option value="issue">
						Entire special issue
					</option>
					<option value="section">
						Special section
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				v-show="type !== 'special'"
				label="Article Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="articleTitle" />

			<form-textarea-reference-wizard
				v-show="showTranslatorFields"
				label="Translated Article Title"
				help-text="If the original article title is provide in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedArticleTitle" />
			
			<form-textarea-reference-wizard
				v-show="type === 'special' && kind === 'issue'"
				label="Issue Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="issueTitle" />

			<form-textarea-reference-wizard
				v-show="type === 'special' && kind === 'section'"
				label="Section Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="sectionTitle" />

			<form-textarea-reference-wizard
				v-show="type === 'special'"
				label="Translated Issue/Section Title"
				help-text="If the original title is provide in another language, type the translated title."
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedIssueTitle" />
			
			<form-textarea-reference-wizard 
				label="Magazine Name"
				help-text="Type the full title of the magazine, exactly as it appears in the publication."
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="magazineTitle" />

			<form-datepicker
				id="RefWiz_PublicationDate" 
				label="Publication Date"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />

			<form-input-reference-wizard
				v-show="kind !== 'advance' && kind !== 'inPress'"
				label="Volume Number"
				help-text="Use numbers for the volume number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="volume" />

			<form-input-reference-wizard
				v-show="kind !== 'advance' && kind !== 'inPress'"
				label="Issue Number"
				help-text="Use numbers for the issue number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="issue" />

			<control-toggle-select
				v-show="kind !== 'advance' && kind !== 'inPress' && kind != 'issue'"
				:label="toggleSelect.pagesArticle.label"
				:is-common-use="toggleSelect.pagesArticle.isCommonUse"
				:options="toggleSelect.pagesArticle.options"
				group-name="pagesArticle" />
			
			<control-toggle-select
				v-show="type !== 'special'" 
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />

			<form-datepicker
				v-show="kind !== 'advance' && type !== 'special'" 
				help-text="Only include if your article is in a changing database like UpToDate."
				id="RefWiz_RetrievalDate" 
				label="Retrieval Date"
				:is-today-button="true"
				:paper-format="0"
				@date-set="dateSetRetrieval"
				@date-clear="dateClearRetrieval" />
		</section>

		<section class="mb-5" v-show="kind == 'retracted'">
			<h3>Retraction Information</h3>
			
			<form-input-reference-wizard
				label="Retraction Volume Number"
				help-text="Use numbers for the volume number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="retractionVolume" />

			<form-input-reference-wizard
				label="Retraction Issue Number"
				help-text="Use numbers for the issue number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="retractionIssue" />

			<form-datepicker 
				id="RefWiz_RetractionDate" 
				label="Retraction Date"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetRetractionDate"
				@date-clear="dateClearRetractionDate" />
			
			<form-input-reference-wizard 
				label="Retraction Page Numbers"
				help-text="Give the page numbers that include the work (e.g., C1-C2). Discontinuous pages are separated with a comma and a space (e.g., A1, A4-A5)."
				@generate-reference-preview="generateReferencePreview"
				v-model="retractionPages" />

			<control-toggle-select
				:label="toggleSelect.retractionDoiUrl.label"
				:is-common-use="toggleSelect.retractionDoiUrl.isCommonUse"
				:options="toggleSelect.retractionDoiUrl.options"
				group-name="retractionDoiUrl" />
		</section>
		
		<section class="mb-5" v-show="type == 'reprinted'">
			<h3>Original Publication Information</h3>
			
			<form-textarea-reference-wizard
				label="Original Article Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				v-model="originalArticleTitle"
				@generate-reference-preview="generateReferencePreview" />

			<form-textarea-reference-wizard 
				label="Original Magazine Name"
				help-text="Type the full title of the magazine, exactly as it appears in the publication."
				:is-autosize="true"
				:is-common-use="true"
				v-model="originalMagazineTitle"
				@generate-reference-preview="generateReferencePreview" />
						
			<form-datepicker 
				id="RefWiz_OriginalPublicationDate" 
				label="Original Publication Date"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetOriginalPublication"
				@date-clear="dateClearOriginalPublication" />

			<form-input-reference-wizard 
				label="Original Volume Number"
				help-text="Use numbers for the volume number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalVolume" />

			<form-input-reference-wizard 
				label="Original Issue Number"
				help-text="Use numbers for the issue number."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalIssue" />

			<control-toggle-select
				:label="toggleSelect.originalPagesArticle.label"
				:is-common-use="toggleSelect.originalPagesArticle.isCommonUse"
				:options="toggleSelect.originalPagesArticle.options"
				group-name="originalPagesArticle" />

			<control-toggle-select
				:label="toggleSelect.originalDoiUrl.label"
				:is-common-use="toggleSelect.originalDoiUrl.isCommonUse"
				:options="toggleSelect.originalDoiUrl.options"
				group-name="originalDoiUrl" />
		</section>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeMagazine',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];
			this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);
			
			if(this.type != 'special') {
				this.addContributorRole({
					addDefault: true,
					type: 'author',
				});

				this.addContributorRole({
					type: 'groupAuthor',
				});
			}

			this.addContributorRole({
				addDefault: (this.type === 'special') ? true : false,
				type: 'editor',
			});

			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind
				
				// Translator
				if(this.kind == 'foreign') {
					this.addContributorRole({
						addDefault: true,
						type: 'translator',
					});
					this.usedContributorTypes.push('translator');
					this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
				} else if(this.kind === 'article' || this.kind === 'editorial' || this.type === 'special') {
					this.addContributorRole({
						addDefault: false,
						type: 'translator',
					});
				}

			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)
				
				// Update Used Contributor Types
				this.usedContributorTypes = ['author','groupAuthor','editor', 'translator'];

				this.$nextTick(()=>{
					if(this.kind === 'foreign') {
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					} else {
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);
					}
				});
			}//e:if:isRefRulesEmpty
		
			// check all contributors for a translator
			if(_has(this.referenceRules.data, 'contributors')){
				_forEach(this.referenceRules.data.contributors, (contributorBlock)=>{
					if(contributorBlock.type === 'translator'){
						this.$store.commit('referenceRules/SET_IS_TRANSLATOR', true);
					}
				});
			}

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
		
		dateClearOriginalPublication(){
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'originalPublicationDate',
				value: ''
			});
			this.generateReferencePreview();
		},
		dateClearRetractionDate(){
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'retractionDate',
				value: ''
			});
			this.generateReferencePreview();
		},
		dateClearRetrieval(){
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'retrievalDate',
				value: ''
			});
			this.generateReferencePreview();
		},
		dateSetOriginalPublication(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'originalPublicationDate',
					value: newDate
				});
				this.generateReferencePreview();
			}
		},
		dateSetRetractionDate(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'retractionDate',
					value: newDate
				});
				this.generateReferencePreview();
			}
		},
		dateSetRetrieval(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'retrievalDate',
					value: newDate
				});
				this.generateReferencePreview();
			}
		},

		typeChange(){
			// changing radio button 'type' should reselect the first option in the 'kind' dropdown
			if(this.type === 'special'){
				this.kind = 'issue';
			} else {
				this.kind = 'article';
			}
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},

	},
	mounted(){
		if(this.kind === ''){
			this.kind = 'article'
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
		if(this.type === ''){
			this.type = 'magazine'
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>