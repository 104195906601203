<!--
- Conference & Symposium Materials
-- Reference Type Id: 13
-- 2024.03.29
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Conference"
								value="conference"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Conference">
								Conference Session or Presentation
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Symposium"
								value="symposium"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Symposium">
								Symposium Contribution
							</label>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Contribution Title"
				help-text="Type the title of the contribribution. Only capitalize the first word of the title and subtitle, and any proper nouns."
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-textarea-reference-wizard
				v-show="type === 'symposium'"
				label="Symposium Title"
				help-text="Enter the name of the symposium in sentence-case, capitalizing only the first word of the title & subtitle and any proper nouns."
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="symposiumTitle" />

			<form-input-reference-wizard
				v-show="type === 'conference'"
				label="Type of Contribution"
				help-text="Enter the type of contribution that was made (e.g., Conference session, Paper presentation, etc.). Only capitalize the first word."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="description" />

			<form-input-reference-wizard
				label="Conference Name"
				help-text="Type the name of the conference or symposium."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="conference" />

			<form-input-reference-wizard
				label="Location - City"
				help-text="Type the name of the city where the conference or symposium took place."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="city" />

			<form-input-reference-wizard
				label="Location - State"
				help-text="Enter the two letter abbreviation for the state where the conference or symposium happened."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="state" />

			<form-input-reference-wizard
				label="Location - Country"
				help-text="Enter the name of the country where the conference or symposium took place. List a country even if it's your own."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="country" />

			<form-datepicker
				id="RefWiz_PublicationDate" 
				label="Conference Start Date"
				help-text="Enter the date of the conference or symposium. If the event happened over multiple days, add an ending date."
				:is-add-end-date="true"
				:is-all-common-use="true"
				:is-common-use="true"
				:paper-format="0"
				@end-date-add="endDateSet(true)"
				@date-set="dateSetStart"
				@date-clear="dateClearStart" />
			
			<form-datepicker
				v-show="isEndDate"
				id="RefWiz_EndDate" 
				label="Conference End Date"
				:is-error="!isDateValid"
				:is-remove-end-date="true"
				:is-all-common-use="true"
				:is-common-use="true"
				:paper-format="0"
				@end-date-remove="endDateSet(false)"
				@date-set="dateSetEnd"
				@date-clear="dateClearEnd" />

			<control-toggle-select
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />

		</section>

	</div>
</template>

<script>
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeConferenceMaterials',
	mixins: [mixinReferenceTypeGenesis],
	data() {
		return {
			isDateValid: true,		// local check - end date must be after start date
			isEndDate: false,
		}
	},
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			// Author
			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});

			// Chair
			if(this.type === 'symposium'){
				this.addContributorRole({
					type: 'chair',
				});
			}

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors

		endDateSet(isShown){
			if(!isShown){
				this.dateClearEnd();
			}
			this.isEndDate = isShown;
		},//e:endDateSet

		dateClearStart(){
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'publicationDate',
				value: ''
			});
			// this.validateStartEndDates();
			this.generateReferencePreview();
		},

		dateClearEnd(){
			// this.endDate = '';
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'endDate',
				value: ''
			});
			// this.validateStartEndDates();
			this.generateReferencePreview();
		},

		dateSetStart(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'publicationDate',
					value: newDate
				});
				// this.validateStartEndDates();
				this.generateReferencePreview();
			}
		},

		dateSetEnd(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				// this.endDate = newDate;
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'endDate',
					value: newDate
				});
				// this.validateStartEndDates();
				this.generateReferencePreview();
			}
		},

		// quick validate start and end dates
		validateStartEndDates(){
			if(this.endDate === '') {
				// no end date is automatically valid
				this.isDateValid = true;
			} else {
				if ((Date.parse(this.publicationDate) >= Date.parse(this.endDate))) {
					this.isDateValid = false;
				} else {
					this.isDateValid = true;
				}
			}
		},//e:validateStartEndDates
	},
	mounted() {
		if(this.type === ''){
			this.type = 'conference';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>