<!--
- US State Court
-- Reference Type Id: 46
-- 2024.06.05
-->

<template>
	<div>
		<div v-if="referenceFormatTypeId === $enums.Format.MLA9" class="alert alert-warning">
			<p class="ps-3">
				This reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).
			</p>
		</div>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-input-reference-wizard
				label="Case Name"
				help-text="Type the full name of the case as it appears. It should be formatted as &quot;Name v. Name&quot;"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<div class="form-group">
				<label>
					Case Location
				</label>

				<div class="help-text mb-1" v-html="calcHelpTextCaseLocation" />

				<citation-block
					v-for="(citationData, index) in $store.state.referenceRules.formData.citation"
					:key="citationData.id"
					:citation-index="index" />
				
				<div class="d-grid">
					<button class="btn btn-hover btn-hover-success" @click.prevent="addParallelCitation" type="button">
						<span class="svg bi-plus-lg" /> Add Parallel Citation
					</button>
				</div>
			</div><!--case location-->


			<form-input-reference-wizard
				label="Deciding Court"
				help-text="If the case was not heard by the state&#39;s&nbsp;highest court (Supreme Court), list the court that heard the case here. Use the appropriate abbreviations as suggested by &quot;The Bluebook.&quot;"
				@generate-reference-preview="generateReferencePreview"
				v-model="court" />

			<form-input-reference-wizard
				label="Year"
				help-text="List the year the decision was passed."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard
				label="URL (Web address)"
				help-text="Include the URL for the work if available."
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />
		</section>
	</div>
</template>

<script>
import CitationBlock from '@/components/reference-rules-genesis/_CitationBlock';
import config from '@/config';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';
import { v4 as uuidv4 } from 'uuid';

export default {
	name: 'RefTypeUsFederalCourt',
	mixins: [mixinReferenceTypeGenesis],
	computed:{
		calcHelpTextCaseLocation(){
			if(this.referenceFormatTypeId === config.enums.Format.MLA9 || this.referenceFormatTypeId === config.enums.Format.Turabian9){
				return "Type the volume number, series name, and starting page number for where the case was reported. Type the series name exactly as it appears.";
			} else {
				return 'Type the volume number, series abbreviation, and starting page number for where the case was reported. Use the appropriate abbreviations as suggested by "The Bluebook"';
			}
		}
	},
	methods: {
		addParallelCitation(){
			this.$store.commit('referenceRules/PUSH_TO_CITATION', {
				id: uuidv4().toUpperCase(),
				pageNumber : '',
				series : '',
				volume : '',
			});

			this.$nextTick(()=>{
				this.emitter.emit('focusFirstCitationField');
			});
		},
		calcContributors(){
			// this function left intentionally blank
		},
	},
	components:{
		CitationBlock
	},
}
</script>