var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Title of the Speech",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", { attrs: { "field-type": "reference" } }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Date of the Speech",
            "is-all-common-use": true,
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Copyright Year",
            "help-text":
              "This is the year the album was copyrighted and/or released.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.copyrightYear,
            callback: function ($$v) {
              _vm.copyrightYear = $$v
            },
            expression: "copyrightYear",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: { label: "URL (Web address)", "is-common-use": true },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }