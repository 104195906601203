var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isThirdPartyLookUp
        ? _c("reference-type-switcher", {
            attrs: { "original-reference-type-id": "4" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "mb-5" }, [
        _c("h3", [_vm._v("Format Options")]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group mb-0 py-2" }, [
          _c("label", [_vm._v("Choose a type")]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.type,
                      expression: "type",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "radio",
                    id: "RefWiz_Type_Whole",
                    value: "whole",
                  },
                  domProps: { checked: _vm._q(_vm.type, "whole") },
                  on: {
                    change: [
                      function ($event) {
                        _vm.type = "whole"
                      },
                      _vm.calcContributors,
                    ],
                  },
                }),
                _vm._v(" "),
                _vm._m(0),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.type,
                      expression: "type",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "radio",
                    id: "RefWiz_Type_Series",
                    value: "series",
                  },
                  domProps: { checked: _vm._q(_vm.type, "series") },
                  on: {
                    change: [
                      function ($event) {
                        _vm.type = "series"
                      },
                      _vm.calcContributors,
                    ],
                  },
                }),
                _vm._v(" "),
                _vm._m(1),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "mb-5" },
        [
          _c("control-contributor", {
            attrs: {
              "contributors-prop": _vm.contributors,
              "used-contributor-types": _vm.usedContributorTypes,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "mb-5" },
        [
          _c("h3", [_vm._v("Publication Information")]),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            attrs: {
              label: "Title of the Work",
              "help-text": _vm.calcTitleHelpText,
              "is-autosize": true,
              "is-common-use": true,
              "is-suggest-bracket-help": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.workTitle,
              callback: function ($$v) {
                _vm.workTitle = $$v
              },
              expression: "workTitle",
            },
          }),
          _vm._v(" "),
          _c("form-textarea-reference-wizard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "series",
                expression: "type == 'series'",
              },
            ],
            attrs: {
              label: "Title of the Series",
              "help-text": _vm.calcTitleHelpText,
              "is-autosize": true,
              "is-suggest-title-case": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.seriesTitle,
              callback: function ($$v) {
                _vm.seriesTitle = $$v
              },
              expression: "seriesTitle",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            attrs: {
              label: "Identifying Information",
              "help-text":
                "Include any additional information needed to clearly identify the work (e.g., Project No. 53421, Effective April 1, or Volume 4).",
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.description,
              callback: function ($$v) {
                _vm.description = $$v
              },
              expression: "description",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            attrs: {
              label: "Format or Type",
              "help-text":
                "Include a brief description for works that aren't easily identified by their Title or Identifying Information (e.g., Report, Policy Brief).",
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.format,
              callback: function ($$v) {
                _vm.format = $$v
              },
              expression: "format",
            },
          }),
          _vm._v(" "),
          _c("form-input-reference-wizard", {
            attrs: {
              label: "Publication Year",
              "help-text":
                "Type the copyright date for this version of the text. Add the date the work was produced in Original Publication Year.",
              "is-common-use": true,
            },
            on: { "generate-reference-preview": _vm.generateReferencePreview },
            model: {
              value: _vm.publicationDate,
              callback: function ($$v) {
                _vm.publicationDate = $$v
              },
              expression: "publicationDate",
            },
          }),
          _vm._v(" "),
          _c("control-publishers-v2", { attrs: { "field-type": "reference" } }),
          _vm._v(" "),
          _c("control-toggle-select", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type !== "special",
                expression: "type !== 'special'",
              },
            ],
            attrs: {
              label: _vm.toggleSelect.doiUrl.label,
              "is-common-use": _vm.toggleSelect.doiUrl.isCommonUse,
              options: _vm.toggleSelect.doiUrl.options,
              "default-option": "url",
              "group-name": "doiUrl",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_Whole" } },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tA standalone work: report, brief, codes, etc.\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "Use this type of reference if your work was created or published by itself.  This is more common than a work in a series.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_Series" } },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tA work that is part of a series\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "Use this reference type for citing a work that was published as part of a greater series of works.",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }