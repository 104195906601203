<!--
- Publishers V2
-- Expand Multi Text Control to allow for more properties, along with Sorting
-- 2024.06.21
-->

<template>
	<div :class="['form-group', {'mb-4': isSingleOnly}]">
		<label v-html="label" />

		<div class="help-text mb-1" v-if="calcHelpText" v-html="calcHelpText" />

		<publishers-v2-block
			v-for="(publisherData, index) in $store.state.referenceRules.formData.publishersV2[fieldType]"
			:field-type="fieldType" 
			:is-show-city-state="isShowCityState"
			:is-single-only="isSingleOnly"
			:key="publisherData.id"
			:publisher-index="index" />
	
		<div v-if="!isSingleOnly" class="d-grid">
			<button class="btn btn-hover btn-hover-success" @click.prevent="addPublisherBlock" type="button">
				<span class="svg bi-plus-lg" /> Add {{ buttonText }}
			</button>
		</div>
	</div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import config from '@/config';
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';
import PublishersV2Block from '@/components/reference-rules-genesis/_PublishersV2Block';
import { v4 as uuidv4 } from 'uuid';

export default {
	name: 'PublishersV2',
	props:{
		buttonText:{
			default: 'publisher',
			type: String
		},	
		helpTextProp:{
			type: String
		},
		isShowCityState: {
			default: true,
			type: Boolean
		},
		isSingleOnly: {	// case when mutil text has only a single value
			default: false,
			type: Boolean
		},
		label:{
			default: 'Publishers',
			type: String
		},
		fieldType: {
			type: String,	// could be 'reference' or 'original' - the key used from the publishers data object
		},	
		showHelpText:{
			default: true,
			type: Boolean
		}
	},
	computed:{
		calcHelpText(){
			if(this.showHelpText){
				if(this.helpTextProp){
					return this.helpTextProp;
				} else {
					switch(this.referenceFormatTypeId){
						case config.enums.Format.APA7:
						case config.enums.Format.MLA9:
							return `Don't include business titles (e.g., Inc., Co, Ltd.). For non-US publishers, enter the country instead of a state (e.g., London, UK). City and State are not required for APA & MLA.`;
						case config.enums.Format.Turabian9:
							return `Don't include business titles (e.g., Inc., Co, Ltd.). For non-US publishers, enter the country instead of a state (e.g., London, UK).`;
					}
				}
			}
			return '';
		},
		referenceFormatTypeId(){
			return HelperGetReferenceFormatId();
		},
	},
	methods:{
		addPublisherBlock(){
			this.$store.commit('referenceRules/PUSH_TO_PUBLISHERS', {
				city:'',
				fieldType: this.fieldType,
				id: uuidv4().toUpperCase(),
				name:'',
				state:''
			});

			this.$nextTick(()=>{
				this.emitter.emit('focusFirstPublisherField');
			});
		},//e:addPublisherBlock
	},
	components:{
		PublishersV2Block,
	},
	
}
</script>
