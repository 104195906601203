<!--
- Grants
-- Reference Type Id: 21
-- 2024.05.28
-->

<template>
	<div>
		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>
	
		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard 
				label="Grant Title/Name"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard 
				label="Grant Description"
				help-text="If the grant has a volume, project number, etc., type the full description here."
				@generate-reference-preview="generateReferencePreview"
				v-model="description" />

			<form-input-reference-wizard 
				label="Grant Duration (Years)"
				help-text="Type the years that the grant was active (e.g. 2015-2018)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<control-publishers-v2
				button-text="institution"
				label="Funding Agency"
				help-text-prop="Don't include business titles (e.g., Inc., Co, Ltd.). For non-US agencies, enter the country instead of a state (e.g., London, UK)."
				field-type="reference" /> 

			<control-toggle-select 
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />
		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeGrant',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind
				this.addContributorRole({
					addDefault: true,
					type: 'author',		// principalInvestigator
				});

				this.addContributorRole({
					type: 'groupAuthor',	// groupInvestigator
				});

			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)
				
				// Update Used Contributor Types
				this.usedContributorTypes = ['author','groupAuthor'];

			}//e:if:isRefRulesEmpty

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
}
</script>