<!--
- Contributor Block
-- Block of fields added or removed for one single Contributor
-- 2024.08.05
-->

<template>
	<div class="mb-3">
		<div
			v-if="
				$store.state.referenceRules.referenceTypeId === 23 &&
				$store.state.referenceRules.formData.kind === 'anonymous'
			"
			class="form-group"
			key="divInterviewAnonymous">
			
			<div class="form-group">
				<label>
					Description
				</label>
				<input
					autocomplete="off"
					type="text"
					class="form-control form-control-primary"
					@blur="blurAnonymousInput"
					@input="typeAnonymousInput($event.target.value)" /> 
			</div>
		</div>
		<div v-else key="divShowFields">
			<div class="d-flex">
				<div v-if="showPrimaryContributor" class="w-100 mb-1" key="divPrimary">
					<label class="text-brand-500 mb-0">Primary Contributor Name</label>
					<p class="help-text mb-1">
						Primary contributors are listed at the beginning of a reference.
					</p>
				</div>
				<div v-else key="divElse" class="w-100 mb-2">
					<label class="mb-0">{{ contributorLabel }}</label>
				</div>

				<div class="icon-row flex-shrink-1" v-if="isAddAnother">
					<div class="d-flex flex-row-reverse">
						<a href="#" @click.prevent="sortContributorBlock(false)" v-if="contributorIndex != ($store.state.referenceRules.formData.contributors.length - 1)" title="Move Contributor Down">
							<span class="svg svg-sort bi-arrow-down" />
						</a>
						<a href="#" @click.prevent="sortContributorBlock(true)" v-if="contributorIndex !== 0" title="Move Contributor Up">
							<span class="svg svg-sort bi-arrow-up" />
						</a>
						<a href="#" @click.prevent="removeContributorBlock" title="Remove Contributor">
							<span class="svg svg-delete bi-x-lg" />
						</a>
					</div>
				</div>
			</div>
			
			<p class="help-text">
				{{ calcHelpText }}
			</p>

			<!-- Role: All -->
			<div
				v-if="
				$store.state.referenceRules.referenceTypeId !== 23 || 
				$store.state.referenceRules.formData.type !== 'personal' ||
				$store.state.referenceRules.formData.kind !== 'named'
				"
				class="form-group form-group-split mb-2">
				<div class="flex-shrink-1">
					<label class="col-form-label">Role</label>
				</div>
				<div class="w-100">
					<select class="form-select" @change="typeChange" v-model="contributor_type" aria-label="Role">
						<option v-for="(option,index) in displayedContributorTypes" :key="index" :value="option.value">
							{{ option.display }}
						</option>
					</select>
				</div>
			</div>

			<!-- Custom Role -->
			<form-input-contributor
				v-show="contributor_type === 'custom' || contributor_type === 'reviewComposer'"
				label="Role"
				:is-focus-on-add="true"
				:is-common-use="true"
				v-model="contributor_name" />

			<!-- Name: Username  -->
			<form-input-contributor
				v-show="contributor_type === 'userName' || contributor_type === 'individualCommentor'"
				label="Name"
				:is-focus-on-add="true"
				:is-common-use="true"
				v-model="contributor_name" />

			<!-- Name: GroupAuthor -->
			<form-input-contributor
				v-show="isGroupType"
				label="Name"
				:is-focus-on-add="true"
				:is-contributor-block-error-state="contributor_is3pluErrorState"
				:is-common-use="true"
				:is-suggest-group-author="true"
				v-model="contributor_groupName" />
		
			<!-- Abbreviation: GroupAuthor  -->
			<form-input-contributor
				v-show="isGroupType"
				label="Abbrev."
				v-model="contributor_abbreviation" />

			<!-- First Name: Author/Editor/OriginalEditor/Translator/Username/Narrator/Performer  -->
			<form-input-contributor
				v-show="isFullNameFields"
				label="First"
				:is-focus-on-add="true"
				:is-common-use="true"
				v-model="contributor_firstName" />

			<!-- Middle Name: Author/Editor/OriginalEditor/Translator/Username/Narrator -->
			<form-input-contributor
				v-show="isFullNameFields"
				label="Middle"
				v-model="contributor_middleName" />
			
			<!-- Last Name: Author/Editor/OriginalEditor/Translator/Username/Narrator -->
			<form-input-contributor
				v-show="isFullNameFields"
				label="Last"
				:is-common-use="true"
				v-model="contributor_lastName" />

			<!-- Suffix: Author/Editor/OriginalEditor/Translator/Username/Narrator  -->
			<form-input-contributor
				v-show="isFullNameFields"
				label="Suffix"
				v-model="contributor_suffix" />
		</div><!--divShowFields-->
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import config from '@/config';
import FormInputContributor from '@/components/reference-rules-genesis/_InputContributor';

export default {
	name: 'ContributorBlock',
	props:{
		allContributorTypes: {
			type: Array
		},
		contributorLabel:{
			required: true,
			type: String,
		},
		contributorIndex: {
			type: Number
		},
		isAddAnother:{
			default: true,
			type: Boolean,			
		},
		isBookChapter:{
			default: false,
			type: Boolean,
		},
		usedContributorTypes: {
			type: Array
		},
	},
	computed:{
		calcHelpText(){
			// loop through all contriburor Types
			let findContributor = this.displayedContributorTypes.find((contributorData)=>{
				return contributorData.value === this.contributor_type;
			});
			if(findContributor && _has(findContributor, 'helpText')){
				return findContributor.helpText;
			}

			// Generic (author, etc.)
			return `If the contributor is known by a single name, type it in the Last name field. Do not include credentials or degrees.`;
		},
		contributor_abbreviation:{
			get(){
				return this.$store.state.referenceRules.formData.contributors[this.contributorIndex].abbreviation;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_CONTRIBUTOR_AT_INDEX', {
					contributorIndex: this.contributorIndex,
					key: 'abbreviation',
					value,
				});
			}
		},
		contributor_firstName:{
			get(){
				return this.$store.state.referenceRules.formData.contributors[this.contributorIndex].firstName;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_CONTRIBUTOR_AT_INDEX', {
					contributorIndex: this.contributorIndex,
					key: 'firstName',
					value,
				});
			}
		},
		contributor_groupName:{
			get(){
				return this.$store.state.referenceRules.formData.contributors[this.contributorIndex].groupName;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_CONTRIBUTOR_AT_INDEX', {
					contributorIndex: this.contributorIndex,
					key: 'groupName',
					value,
				});
			}
		},
		
		contributor_is3pluErrorState(){
			return this.$store.state.referenceRules.formData.contributors[this.contributorIndex].is3pluErrorState;
		},
		contributor_lastName:{
			get(){
				return this.$store.state.referenceRules.formData.contributors[this.contributorIndex].lastName;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_CONTRIBUTOR_AT_INDEX', {
					contributorIndex: this.contributorIndex,
					key: 'lastName',
					value,
				});
			}
		},
		contributor_middleName:{
			get(){
				return this.$store.state.referenceRules.formData.contributors[this.contributorIndex].middleName;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_CONTRIBUTOR_AT_INDEX', {
					contributorIndex: this.contributorIndex,
					key: 'middleName',
					value,
				});
			}
		},
		contributor_name:{
			get(){
				return this.$store.state.referenceRules.formData.contributors[this.contributorIndex].name;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_CONTRIBUTOR_AT_INDEX', {
					contributorIndex: this.contributorIndex,
					key: 'name',
					value,
				});
			}
		},
		contributor_suffix:{
			get(){
				return this.$store.state.referenceRules.formData.contributors[this.contributorIndex].suffix;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_CONTRIBUTOR_AT_INDEX', {
					contributorIndex: this.contributorIndex,
					key: 'suffix',
					value,
				});
			}
		},
		contributor_type:{
			get(){
				return this.$store.state.referenceRules.formData.contributors[this.contributorIndex].type;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_CONTRIBUTOR_AT_INDEX', {
					contributorIndex: this.contributorIndex,
					key: 'type',
					value,
				});
			}
		},
		displayedContributorTypes(){
			let returnTypes = [];
			this.allContributorTypes.forEach((type)=>{
				if(this.usedContributorTypes.includes(type.value)){
					returnTypes.push(type);
				}
			});
			return returnTypes;
		},
		isFullNameFields(){
			if( this.contributor_type === 'artist' ||
				this.contributor_type === 'author' ||
				this.contributor_type === 'chair' ||
				this.contributor_type === 'chapterAuthor' ||
				this.contributor_type === 'chapterTranslator' ||
				this.contributor_type === 'custom' ||
				this.contributor_type === 'director' ||
				this.contributor_type === 'editor' ||
				this.contributor_type === 'executiveDirector' ||
				this.contributor_type === 'executiveDirectorProducer' ||
				this.contributor_type === 'executiveProducer' ||
				this.contributor_type === 'host' ||
				this.contributor_type === 'illustrator' ||
				this.contributor_type === 'interview' ||
				this.contributor_type === 'individual' ||
				this.contributor_type === 'individualCommentor' ||
				this.contributor_type === 'narrator' ||
				this.contributor_type === 'originalAuthor' ||
				this.contributor_type === 'originalEditor' ||
				this.contributor_type === 'performer' ||
				this.contributor_type === 'personalSource' ||
				this.contributor_type === 'president' ||
				this.contributor_type === 'reviewer' ||
				this.contributor_type === 'reviewAuthor' ||
				this.contributor_type === 'reviewEditor' ||
				this.contributor_type === 'reviewDirector' ||
				this.contributor_type === 'reviewComposer' ||
				this.contributor_type === 'reviewWriter' ||
				this.contributor_type === 'reviewWriterDirector' ||
				this.contributor_type === 'reviewArtist' ||
				this.contributor_type === 'principalInvestigator' ||
				this.contributor_type === 'speaker' ||
				this.contributor_type === 'testimony' ||
				this.contributor_type === 'translator' ||
				this.contributor_type === 'userName' ||
				this.contributor_type === 'writer' || 
				this.contributor_type === 'writerDirector'){
				return true;
			}
			return false;
		},
		isGroupType(){
			if( this.contributor_type === 'company' ||
				this.contributor_type === 'committeeName' ||
				this.contributor_type === 'departmentName' ||
				this.contributor_type === 'groupArtist' ||
				this.contributor_type === 'reviewGroupArtist' ||
				this.contributor_type === 'groupAuthor' ||
				this.contributor_type === 'groupCommentor' ||
				this.contributor_type === 'groupInvestigator' ||
				this.contributor_type === 'groupOrganization' ||
				this.contributor_type === 'groupPerformer'){
				return true;
			}

			return false;
		},
		referenceTypeId(){
			return this.$store.state.referenceRules.referenceTypeId;
		},
		showPrimaryContributor(){
			if(this.contributorIndex === 0 && this.referenceFormatTypeId === config.enums.Format.MLA9){
				return true;	
			}
			return false;
		},
	},
	methods: {
		blurAnonymousInput(){
			this.$store.dispatch('referenceRules/generateReferencePreview');
		},
		removeContributorBlock(){
			this.$emit('remove-contributor-block', this.contributorIndex);
		},
		sortContributorBlock(isMoveUp){
			let oldIndex = (isMoveUp) ? (this.contributorIndex - 1) : (this.contributorIndex + 1);
			this.$store.commit('referenceRules/SORT_CONTRIBUTORS',{
				contributorIndex: this.contributorIndex,
				oldIndex,
			});
			this.$store.dispatch('referenceRules/generateReferencePreview');
		},
		typeAnonymousInput(value){
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: 'formReferenceWizard',
			});
			
			this.$store.commit('referenceRules/UPDATE_CONTRIBUTOR_AT_INDEX', {
				contributorIndex: this.contributorIndex,
				key: 'name',
				value,
			});

			// this.$emit('input', value);
			this.$store.dispatch('referenceRules/generateReferencePreview');
		},
		typeChange(){
			this.$store.dispatch('referenceRules/roleDropdownChange', {
				selectedRole: this.contributor_type,	
			});
		},
	},
	components:{
		FormInputContributor
	},
}
</script>
