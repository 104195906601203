<!--
- Reference Works
-- Reference Type Id: 36
-- 2024.04.04
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Entry"
								value="entry"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Radio_Entry">
								Entry in a reference work
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="Use this for citing a single entry in a reference work." />
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Whole"
								value="whole"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Radio_Whole">
								Whole reference work
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="This reference type is for citing entire works, not just a single reference." />
							</label>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group mb-0 py-2" v-show="type === 'entry'" key="divKindBook">
				<label>What kind of entry are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of entry are you referencing?">
					<option value="encyclopedia">
						Entry in an encyclopedia
						</option>
					<option value="wikipedia">
						Entry in Wikipedia
						</option>
					<option value="dictionary">
						Entry in a dictionary or thesaurus
						</option>
				</select>
			</div>

			<div class="form-group mb-0 py-2" v-show="type === 'whole'" key="divKindBookChapter">
				<label>What kind of Reference Work are you using?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of Reference Work are you using?">
					<option value="encyclopedia">
						An encyclopedia
						</option>
					<option value="dictionary">
						A dictionary or thesaurus
						</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>
			
			<form-textarea-reference-wizard
				v-show="type === 'entry'"
				label="Entry Title"
				help-text="Only capitalize the first word of the title & subtitle, and any proper nouns. Leave empty if the work is not arranged by entries"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="entryTitle" />
	
			<form-textarea-reference-wizard
				v-show="kind !== 'wikipedia'"
				label="Reference Work Name"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="referenceTitle" />

			<control-publishers-v2
				v-show="kind != 'wikipedia'"
				field-type="reference" /> 

			<form-input-reference-wizard
				v-show="kind != 'wikipedia'"
				label="Publication Year"
				help-text="List the year the work was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-datepicker
				v-show="kind == 'wikipedia'" 
				help-text="Enter the date the page was last modified when you accessed it (e.g., April 1, 2023)."
				id="RefWiz_PublicationDate" 
				label="Last Modified Date"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />

			<form-input-reference-wizard
				v-if="kind != 'wikipedia'"
				label="Edition"
				help-text="For numbered editions, type the ordinal number (1st, 2nd, etc.). For named editions, type the name (e.g., 2nd ed., rev.)."
				@generate-reference-preview="generateReferencePreview"
				v-model="edition" />
			
			<form-input-reference-wizard
				label="Format"
				help-text="If the work you're referencing is in a non-print format (e.g., CD-ROM or iOS application), enter the format and version information here."
				@generate-reference-preview="generateReferencePreview"
				v-model="format" />

			<form-input-reference-wizard
				v-if="type === 'entry' && (kind === 'dictionary' || kind === 'encyclopedia')"
				label="Page Number"
				help-text="Enter the page or pages where the entry is found in the work, eg., 12-13."
				@generate-reference-preview="generateReferencePreview"
				v-model="pageNumber" />

			<control-toggle-select
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:is-force-url="kind == 'wikipedia'"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />
			
			<form-datepicker
				:help-text="calcRetievalDateHelpText"
				id="RefWiz_RetrievalDate" 
				label="Retrieval Date"
				:is-today-button="true"
				:paper-format="0"
				@date-set="dateSetRetrieval"
				@date-clear="dateClearRetrieval" />
		</section>
	</div>
</template>

<script>
import config from '@/config';
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeReferenceWorks',
	mixins: [mixinReferenceTypeGenesis],
	computed:{
		calcRetievalDateHelpText(){
			if(this.referenceFormatTypeId === config.enums.Format.Turabian9){
				return "Include if the entry may change in the future or if there isn't a date of publication listed. Don't include for archived works.";
			}
			return "Only include if the entry may change in the future.  Don't include for archived works.";
		},
	},
	methods: {
		calcContributors(){
			this.usedContributorTypes = ['author', 'groupAuthor', 'editor', 'userName'];

			// set default values
			this.addContributorRole({
				type: 'author',
			});

			this.addContributorRole({
				addDefault: (this.referenceFormatTypeId === config.enums.Format.Turabian9) ? false : true,
				type: 'groupAuthor',
			});

			this.addContributorRole({
				type: 'editor',
			});

			this.addContributorRole({
				type: 'userName',
			});

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		
			
		},//e:calcContributors
		
		dateClearRetrieval(){
			this.retrievalDate = '';
			this.generateReferencePreview();
		},
		dateSetRetrieval(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.retrievalDate = newDate;
				this.generateReferencePreview();
			}
		},

		typeChange(){
			// changing radio button 'type' should reselect the first option in the 'kind' dropdown
			this.kind = 'encyclopedia';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted() {
		if(this.kind === ''){
			this.kind = 'encyclopedia';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
		if(this.type === ''){
			this.type = 'entry';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>