var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Title of the Work",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-bracket-help": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.workTitle,
            callback: function ($$v) {
              _vm.workTitle = $$v
            },
            expression: "workTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Date Completed",
            "help-text":
              "Enter the date or year the work was completed or created.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Description or Format",
            "help-text":
              "Include a description/format for works that include document numbers or other identifying information.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.description,
            callback: function ($$v) {
              _vm.description = $$v
            },
            expression: "description",
          },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", {
          attrs: {
            "button-text": "another",
            "help-text-prop": "List the location where the work is stored.",
            label: "Archive, Repository, or Database Name",
            "field-type": "reference",
          },
        }),
        _vm._v(" "),
        _c("control-toggle-select", {
          attrs: {
            label: _vm.toggleSelect.doiUrl.label,
            "is-common-use": _vm.toggleSelect.doiUrl.isCommonUse,
            options: _vm.toggleSelect.doiUrl.options,
            "group-name": "doiUrl",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }