var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group form-group-split mb-2" }, [
    _c("div", { staticClass: "flex-shrink-1" }, [
      _c("label", {
        attrs: { for: _vm.fullId },
        domProps: { innerHTML: _vm._s(_vm.calcLabel) },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "w-100" }, [
      _c("input", {
        directives: [
          {
            name: "debounce",
            rawName: "v-debounce:750ms",
            value: _vm.debouncedGenerateReference,
            expression: "debouncedGenerateReference",
            arg: "750ms",
          },
        ],
        ref: "focusElement",
        class: [
          "form-control",
          {
            "focus-first": _vm.isFocusOnAdd,
            "form-control-primary": _vm.showIsCommonUse,
            "is-suggested": _vm.isShowSuggestion,
          },
        ],
        attrs: {
          autocomplete: "off",
          type: "text",
          id: _vm.fullId,
          "pendo-grab-text": _vm.pendoGrabText,
        },
        domProps: { value: _vm.value },
        on: {
          blur: _vm.blurInput,
          input: function ($event) {
            return _vm.typeInput($event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _vm.isShowSuggestionGroupAuthor
        ? _c("div", { staticClass: "suggested-feedback" }, [
            _vm._v(
              "\n\t\t\tTip: Group Authors are for organizational or corporate authors - not a group of individual authors.\n\t\t"
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isContributorBlockErrorState
        ? _c("div", { staticClass: "invalid-feedback d-block mt-1" }, [
            _vm._v(
              "\n\t\t\tOur search didn't return a page author, but most sites have a group author. We suggest entering the name of the organization responsible for the page (e.g., Sasquatch Watcher's Association).\n\t\t"
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }