<!--
- Statutes
-- Reference Type Id: 42
-- 2024.06.20
-->

<template>
	<div>
		<div v-if="referenceFormatTypeId === $enums.Format.MLA9" class="alert alert-warning">
			<p class="ps-3">
				This reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).
			</p>
		</div>

		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Federal"
								value="federal"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Federal">
								A federal statute
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-html="true"
									data-bs-placement="bottom"
									data-bs-toggle="tooltip"
									role="tooltip"
									title="This is for citing codified statutes reported in the &ldquo;United States Code&rdquo; or recorded with a Public Law number." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_State"
								value="state"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_State">
								A state statute
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="Use this to cite codified statutes at the state level." />
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type === 'federal'">
				<label>What kind of federal statute are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of federal statute are you referencing?">
					<option value="usc">
						Statute codified in the United States Code
					</option>
					<option value="pl">
						Statute cited from Public Law
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>
			
			<form-textarea-reference-wizard
				label="Name of the Statute or Act"
				help-text="Type the full name of the statute exactly as it appears in the work."
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />
						
			<form-input-reference-wizard
				label="Shortened Title"
				help-text="If the work's title is long, include a shortened title for the in-text citation."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="shortTitle" />
			
			<form-input-reference-wizard
				v-show="kind == 'usc'"
				label="<em>United States Code</em> Title Number"
				help-text="Only include the number of title for the <em>United States Code</em> text. Don't include the &quot;U.S.C.&quot; abbreviation."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="volume" />
			
			<form-input-reference-wizard
				v-show="kind == 'usc'"
				label="<em>United States Code</em> Section Numbers"
				help-text="Enter all the sections included for the statute. Don't include the section symbol (§)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="section" />

			<form-input-reference-wizard
				v-show="kind == 'pl'"
				label="Public Law Number"
				help-text="Only include the number for the Public Law reference."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="number" />

			<form-input-reference-wizard
				v-show="kind == 'pl'"
				label="<em>United States Statutes at Large</em> Volume Numbers"
				help-text="Only include the number of the volume. Don&#39;t include the &quot;Stat.&quot; abbreviation."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="volume" />

			<form-input-reference-wizard
				v-show="kind == 'pl'"
				label="Statute Starting Page Number"
				help-text="Enter the starting page number for the statute in the <em>United States Statutes at Large</em>."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="pageNumber" />
			
			<form-textarea-reference-wizard
				v-show="type === 'state'"
				label="Statute Source Title"
				:help-text="calcHelpTextStatuteSourceTitle"
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="sourceTitle" />

			<form-input-reference-wizard
				v-show="type === 'state'"
				label="Statute Source Location"
				help-text="Type the article, section, or page information needed to identify the statute in the source."
				:is-common-use="true"
				:is-insert-section="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="sourceLocation" />

			<form-input-reference-wizard
				v-if="type === 'state'"
				label="State Name"
				help-text="Type the full name of the state."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="state" />

			<form-input-reference-wizard
				label="Original Year"
				help-text="Enter the year the statute was published in the source you're using."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard
				label="Revised Year"
				help-text="If the statute was revised, enter the year the revised statute was published."
				@generate-reference-preview="generateReferencePreview"
				v-model="revisedDate" />

			<form-input-reference-wizard
				label="URL (Web address)"
				help-text="Include the URL for the work if available."
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />
		</section>
	</div>
</template>

<script>
import config from '@/config';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeStatutes',
	mixins: [mixinReferenceTypeGenesis],
	computed:{
		calcHelpTextStatuteSourceTitle(){
			let helpText = "Enter the full title information for where you found the state statute.";
			if(this.referenceFormatTypeId != config.enums.Format.MLA9){
				helpText += " Use abbreviations for the title where appropriate.";
			}
			return helpText;
		},
	},
	methods: {
		calcContributors(){
			this.$nextTick(()=>{
				this.generateReferencePreview();
			});
		},//e:calcContributors

		typeChange(){
			if(this.type === 'federal'){
				this.kind = 'usc';
			} else {
				this.kind = '';
			}
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},//e:typeChange
	},
	mounted(){
		if(this.kind === ''){
			this.kind = 'usc';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
		if(this.type === ''){
			this.type = 'federal';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>