<!--
- International Conventions & Treaties
-- Reference Type Id: 24
-- 2024.06.13
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Publication Information</h3>
			
			<form-textarea-reference-wizard
				label="Convention or Treaty Title"
				help-text="Enter the full name of the convention or treaty exactly as it appears."
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard
				label="Parties / Countries Subject to the Treaty"
				help-text="Unless the treaty title includes the subjected parties, list the parties or countries subject to the treaty below, separated by a hyphen (e.g., Denmark-Italy-France). List the countries in the order they appear in the treaty."
				@generate-reference-preview="generateReferencePreview"
				v-model="parties" />

			<form-datepicker 
				id="RefWiz_PublicationDate" 
				label="Signing or Approval Date"
				help-text="Enter the date the convention or treaty was signed or approved."
				:is-all-common-use="true"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />
				
			<form-input-reference-wizard 
				label="Published Location Source"
				help-text='Type the abbreviation for the published source of the treaty (e.g., "UNTS" for the United Nations Treaty Series).'
				@generate-reference-preview="generateReferencePreview"
				v-model="source" />

			<form-input-reference-wizard 
				label="Published Location Numbers"
				help-text='If applicable, enter the numbers for the volume, parts, or section of the publication where the treaty is published (e.g., "14, pt. 2").'
				@generate-reference-preview="generateReferencePreview"
				v-model="location" />

			<form-input-reference-wizard 
				label="URL"
				help-text="Type the full url, if applicable."
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />
		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeInternationalConventions',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},
	},
}
</script>