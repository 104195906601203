var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Manual",
                  value: "manual",
                },
                domProps: { checked: _vm._q(_vm.type, "manual") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "manual"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Direct",
                  value: "direct",
                },
                domProps: { checked: _vm._q(_vm.type, "direct") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "direct"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Record",
                  value: "record",
                },
                domProps: { checked: _vm._q(_vm.type, "record") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "record"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(2),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type != "record",
              expression: "type != 'record'",
            },
          ],
          attrs: {
            label: "Title of the Test, Scale, or Inventory",
            "help-text":
              "Enter the title using Title Case capitalization.  Include any abbreviations in parentheses as needed.",
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "record",
              expression: "type == 'record'",
            },
          ],
          attrs: {
            label: "Database Record Name",
            "help-text":
              "Enter the title of the database record using Title Case capitalization. Include any database abbreviations in parentheses as needed.",
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "record",
              expression: "type == 'record'",
            },
          ],
          attrs: {
            label: "Database Record Number",
            "help-text":
              "Type the number for the database record without parentheses.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.number,
            callback: function ($$v) {
              _vm.number = $$v
            },
            expression: "number",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Publication Year",
            "help-text": "List the year the work was published.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type != "record",
              expression: "type != 'record'",
            },
          ],
          attrs: { "field-type": "reference" },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "record",
              expression: "type == 'record'",
            },
          ],
          attrs: {
            "button-text": "database",
            label: "Database Name",
            "help-text": "Enter the name of the database.",
            "field-type": "reference",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL",
            "help-text":
              "Type or paste the entire URL. You can use a URL shortener if it is too long (e.g., bit.ly).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_Manual" } },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tManual for a test, scale, or inventory\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "Use this if a manual is available for the test, scale, or inventory. The manual can be a physical or digital copy.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_Direct" } },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tA test, scale, or inventory directly (if manual isn't available)\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "If you only have access to the test, scale, or inventory directly, use this type.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_Record" } },
      [
        _vm._v("\n\t\t\t\t\t\t\tA record in a test database\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "Use this type if you need to cite a single record within a database or inventory.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }