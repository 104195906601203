<!--
- Federal Testimony
-- Reference Type Id: 18
-- 2024.05.24
-->

<template>
	<div>
		<div v-if="referenceFormatTypeId === $enums.Format.MLA9" class="alert alert-warning">
			<p class="ps-3">
				This reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).
			</p>
		</div>

		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Testimony"
								value="testimony"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Testimony">
								Federal Testimony
							</label>
						</div>
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Radio_Hearing"
								value="hearing"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Radio_Hearing">
								Federal Hearing
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type === 'testimony'" key="divKindTestimony">
				<label>What kind of testimony are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of testimony are you referencing?">
					<option value="house">
						Testimony for a House of Representatives Committee
					</option>
					<option value="senate">
						Testimony for a Senate Committee
					</option>
				</select>
			</div>
			<div class="form-group mb-0 py-2" v-show="type === 'hearing'" key="divKindHearing">
				<label>What kind of hearing are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of hearing are you referencing?">
					<option value="house">
						Hearing for a House of Representatives Committee
					</option>
					<option value="senate">
						Hearing for a Senate Committee
					</option>
				</select>
			</div>
		</section>
		
		<section class="mb-5" v-show="type === 'testimony'">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard 
				label="Testimony or Hearing Title"
				help-text="Only capitalize the first word of the title and any proper nouns."
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />
			
			<form-input-reference-wizard
				label="Shortened Title"
				help-text="If the work's title is long, include a shortened title for the in-text citation."
				@generate-reference-preview="generateReferencePreview"
				v-model="shortTitle" />

			<form-input-reference-wizard 
				label="Committee Name"
				:help-text="calcHelpTextCommitteeName"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="committeeName" />

			<form-input-reference-wizard 
				label="Congressional Number"
				help-text='Type the Congressional number when the testimony or hearing occurred (e.g., "110" for the 110th Congress).'
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="session" />

			<form-input-reference-wizard 
				label="Congressional Number Session"
				help-text="If there are multiple sessions for the meeting of Congress, type the ordinal numeral for the part of the session of Congress when the testimony or hearing occurred (e.g., 3rd)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="sessionPart" />

			<form-datepicker 
				id="RefWiz_PublicationDate" 
				label="Date of the Hearing or Testimony"
				help-text="List the date the testimony or hearing was conducted. Include at least the year."
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />

			<form-input-reference-wizard 
				label="URL (Web address)"
				help-text="Include the URL for the work if available."
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

		</section>
	</div>
</template>

<script>
import config from '@/config';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeFederalTestimony',
	mixins: [mixinReferenceTypeGenesis],
	computed:{
		calcHelpTextCommitteeName(){
			let helpText = 'Type the name of the committee or sub-committee responsible exactly as it appears.';

			if(this.referenceFormatTypeId !== config.enums.Format.MLA9){
				helpText += ' Include the full name (e.g., U.S. Senate Committee on Health).'
			}
			return helpText;
		},
	},
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			if(this.isRefRulesEmpty) {
				if(this.type === 'testimony'){
					this.addContributorRole({
						addDefault: true,
						type: 'testimony',	// display as Witness
					});
				}
			} else {
				this.usedContributorTypes.push('testimony');
			}
					
			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
	mounted(){
		if(this.kind === ''){
			this.kind = 'house';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
		if(this.type === ''){
			this.type = 'testimony';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>