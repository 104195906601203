var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.referenceFormatTypeId === _vm.$enums.Format.MLA9
      ? _c("div", { staticClass: "alert alert-warning" }, [
          _c("p", { staticClass: "ps-3" }, [
            _vm._v(
              "\n\t\t\tThis reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).\n\t\t"
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Report",
                  value: "report",
                },
                domProps: { checked: _vm._q(_vm.type, "report") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "report"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Radio_Report" },
                },
                [_vm._v("\n\t\t\t\t\t\t\tA federal report\n\t\t\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Regulation",
                  value: "regulation",
                },
                domProps: { checked: _vm._q(_vm.type, "regulation") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "regulation"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Radio_Regulation" },
                },
                [_vm._v("\n\t\t\t\t\t\t\tA federal regulation\n\t\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "report",
              expression: "type === 'report'",
            },
          ],
          key: "divKindReport",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v("What kind of federal report are you referencing?"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What kind of federal report are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "senate" } }, [
                _vm._v("\n\t\t\t\t\tSenate report\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "house" } }, [
                _vm._v("\n\t\t\t\t\tHouse of Representatives Report\n\t\t\t\t"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "regulation",
              expression: "type === 'regulation'",
            },
          ],
          key: "divKindRegulation",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [_vm._v("What kind of regulation are you referencing?")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label": "What kind of regulation are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "codified" } }, [
                _vm._v("\n\t\t\t\t\tA codified regulation\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "uncodified" } }, [
                _vm._v(
                  "\n\t\t\t\t\tRegulation hasn't been codified yet\n\t\t\t\t"
                ),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "is-add-another": false,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _vm.type === "report"
          ? _c("form-input-reference-wizard", {
              attrs: {
                label: "Title",
                "help-text":
                  "Type the name of the report exactly as it appears in the work.",
                "is-common-use": true,
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "report",
              expression: "type === 'report'",
            },
          ],
          attrs: {
            label: "Report Number",
            "help-text": "Type the number of the report.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.reportNumber,
            callback: function ($$v) {
              _vm.reportNumber = $$v
            },
            expression: "reportNumber",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "regulation",
              expression: "type === 'regulation'",
            },
          ],
          attrs: {
            label: "Regulation Name or Number",
            "help-text":
              "Enter either the regulation's name or number exactly as it appears in the work.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "regulation",
              expression: "type === 'regulation'",
            },
          ],
          attrs: {
            label: "Shortened Title",
            "help-text":
              "If the work's title is long, include a shortened title for the in-text citation.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.shortTitle,
            callback: function ($$v) {
              _vm.shortTitle = $$v
            },
            expression: "shortTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "report",
              expression: "type === 'report'",
            },
          ],
          attrs: {
            label: "Congressional Session",
            "help-text":
              "Type the ordinal number for the Congressional session when this bill/resolution was considered.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.session,
            callback: function ($$v) {
              _vm.session = $$v
            },
            expression: "session",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "report",
              expression: "type === 'report'",
            },
          ],
          attrs: {
            label: "Congressional Number Session",
            "help-text":
              "If there are multiple sessions for the meeting of Congress, type the ordinal numeral for the part of the session of Congress when the testimony or hearing occurred (e.g., 3rd).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.sessionPart,
            callback: function ($$v) {
              _vm.sessionPart = $$v
            },
            expression: "sessionPart",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind === "codified",
              expression: "kind === 'codified'",
            },
          ],
          attrs: {
            label: "<em>Code of Federal Regulations</em> Volume Number",
            "help-text":
              "Type the number of the <em>Code of Federal Regulations</em> where the regulation can be found.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.volume,
            callback: function ($$v) {
              _vm.volume = $$v
            },
            expression: "volume",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind === "codified",
              expression: "kind === 'codified'",
            },
          ],
          attrs: {
            label: "Section Number",
            "help-text":
              "Enter the section number for the regulation. Don't include the section symbol (&sect;).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.section,
            callback: function ($$v) {
              _vm.section = $$v
            },
            expression: "section",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind !== "uncodified",
              expression: "kind !== 'uncodified'",
            },
          ],
          attrs: {
            label: "Year",
            "help-text": "List the year the report was published.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.kind === "uncodified",
                expression: "kind === 'uncodified'",
              },
            ],
          },
          [
            _c("form-input-reference-wizard", {
              attrs: {
                label: "Federal Register Volume",
                "is-common-use": true,
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
              model: {
                value: _vm.proposedVolume,
                callback: function ($$v) {
                  _vm.proposedVolume = $$v
                },
                expression: "proposedVolume",
              },
            }),
            _vm._v(" "),
            _c("form-input-reference-wizard", {
              attrs: {
                label: "Federal Register Page Number",
                "is-common-use": true,
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
              model: {
                value: _vm.pageNumber,
                callback: function ($$v) {
                  _vm.pageNumber = $$v
                },
                expression: "pageNumber",
              },
            }),
            _vm._v(" "),
            _c("form-datepicker", {
              attrs: {
                id: "RefWiz_ProposedDate",
                label: "Proposed Date",
                "paper-format": 0,
              },
              on: {
                "date-set": _vm.dateSetProposed,
                "date-clear": _vm.dateClearProposed,
              },
            }),
            _vm._v(" "),
            _vm.kind !== "uncodified"
              ? _c("form-input-reference-wizard", {
                  attrs: {
                    label:
                      "Proposed <em>Code of Federal Regulations</em> Volume Number",
                    "help-text":
                      "Type the number of the <em>Code of Federal Regulations</em> where the regulation can be found.",
                    "is-common-use": true,
                  },
                  on: {
                    "generate-reference-preview": _vm.generateReferencePreview,
                  },
                  model: {
                    value: _vm.volume,
                    callback: function ($$v) {
                      _vm.volume = $$v
                    },
                    expression: "volume",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("form-input-reference-wizard", {
              attrs: {
                label: "Proposed Section Number",
                "help-text":
                  "Enter the section number for the regulation. Don't include the section symbol (&sect;).",
                "is-common-use": true,
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
              model: {
                value: _vm.section,
                callback: function ($$v) {
                  _vm.section = $$v
                },
                expression: "section",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL (Web address)",
            "help-text": "Include the URL for the work if available.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }