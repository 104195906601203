var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Entry",
                  value: "entry",
                },
                domProps: { checked: _vm._q(_vm.type, "entry") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "entry"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Whole",
                  value: "whole",
                },
                domProps: { checked: _vm._q(_vm.type, "whole") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "whole"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "entry",
              expression: "type === 'entry'",
            },
          ],
          key: "divKindBook",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [_vm._v("What kind of entry are you referencing?")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label": "What kind of entry are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "encyclopedia" } }, [
                _vm._v("\n\t\t\t\t\tEntry in an encyclopedia\n\t\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "wikipedia" } }, [
                _vm._v("\n\t\t\t\t\tEntry in Wikipedia\n\t\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "dictionary" } }, [
                _vm._v(
                  "\n\t\t\t\t\tEntry in a dictionary or thesaurus\n\t\t\t\t\t"
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "whole",
              expression: "type === 'whole'",
            },
          ],
          key: "divKindBookChapter",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [_vm._v("What kind of Reference Work are you using?")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label": "What kind of Reference Work are you using?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "encyclopedia" } }, [
                _vm._v("\n\t\t\t\t\tAn encyclopedia\n\t\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "dictionary" } }, [
                _vm._v("\n\t\t\t\t\tA dictionary or thesaurus\n\t\t\t\t\t"),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "entry",
              expression: "type === 'entry'",
            },
          ],
          attrs: {
            label: "Entry Title",
            "help-text":
              "Only capitalize the first word of the title & subtitle, and any proper nouns. Leave empty if the work is not arranged by entries",
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.entryTitle,
            callback: function ($$v) {
              _vm.entryTitle = $$v
            },
            expression: "entryTitle",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind !== "wikipedia",
              expression: "kind !== 'wikipedia'",
            },
          ],
          attrs: {
            label: "Reference Work Name",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.referenceTitle,
            callback: function ($$v) {
              _vm.referenceTitle = $$v
            },
            expression: "referenceTitle",
          },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind != "wikipedia",
              expression: "kind != 'wikipedia'",
            },
          ],
          attrs: { "field-type": "reference" },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind != "wikipedia",
              expression: "kind != 'wikipedia'",
            },
          ],
          attrs: {
            label: "Publication Year",
            "help-text": "List the year the work was published.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.kind == "wikipedia",
              expression: "kind == 'wikipedia'",
            },
          ],
          attrs: {
            "help-text":
              "Enter the date the page was last modified when you accessed it (e.g., April 1, 2023).",
            id: "RefWiz_PublicationDate",
            label: "Last Modified Date",
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _vm.kind != "wikipedia"
          ? _c("form-input-reference-wizard", {
              attrs: {
                label: "Edition",
                "help-text":
                  "For numbered editions, type the ordinal number (1st, 2nd, etc.). For named editions, type the name (e.g., 2nd ed., rev.).",
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
              model: {
                value: _vm.edition,
                callback: function ($$v) {
                  _vm.edition = $$v
                },
                expression: "edition",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Format",
            "help-text":
              "If the work you're referencing is in a non-print format (e.g., CD-ROM or iOS application), enter the format and version information here.",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.format,
            callback: function ($$v) {
              _vm.format = $$v
            },
            expression: "format",
          },
        }),
        _vm._v(" "),
        _vm.type === "entry" &&
        (_vm.kind === "dictionary" || _vm.kind === "encyclopedia")
          ? _c("form-input-reference-wizard", {
              attrs: {
                label: "Page Number",
                "help-text":
                  "Enter the page or pages where the entry is found in the work, eg., 12-13.",
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
              model: {
                value: _vm.pageNumber,
                callback: function ($$v) {
                  _vm.pageNumber = $$v
                },
                expression: "pageNumber",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("control-toggle-select", {
          attrs: {
            label: _vm.toggleSelect.doiUrl.label,
            "is-common-use": _vm.toggleSelect.doiUrl.isCommonUse,
            "is-force-url": _vm.kind == "wikipedia",
            options: _vm.toggleSelect.doiUrl.options,
            "group-name": "doiUrl",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            "help-text": _vm.calcRetievalDateHelpText,
            id: "RefWiz_RetrievalDate",
            label: "Retrieval Date",
            "is-today-button": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetRetrieval,
            "date-clear": _vm.dateClearRetrieval,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Radio_Entry" } },
      [
        _vm._v("\n\t\t\t\t\t\t\tEntry in a reference work\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title: "Use this for citing a single entry in a reference work.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Radio_Whole" } },
      [
        _vm._v("\n\t\t\t\t\t\t\tWhole reference work\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "This reference type is for citing entire works, not just a single reference.",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }