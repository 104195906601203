<!--
- Constitutions & Charters
-- Reference Type Id: 14
-- 2024.06.13
-->

<template>
	<div>
		<div v-if="referenceFormatTypeId === $enums.Format.MLA9" class="alert alert-warning">
			<p class="ps-3">
				This reference type is for citing the formal, canonical version of a legal work. If you are citing a non-canonical version, create your reference using the format where you found the work (e.g., website).
			</p>
		</div>

		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Us"
								value="us"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Us">
								The U.S. Constitution
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_State"
								value="state"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_State">
								A state Constitution
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Charter"
								value="charter"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Charter">
								An organizational charter
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type == 'us'" key="divKindUs">
				<label>What part of the U.S. Constitution are you citing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What part of the U.S. Constitution are you citing?">
					<option value="article">
						Article of the U.S. Constitution
					</option>
					<option value="amendment">
						Amendment to the U.S. Constitution
					</option>
					<option value="repealed">
						Repealed amendment to the U.S. Constitution
					</option>
				</select>
			</div>
			<div class="form-group mb-0 py-2" v-show="type == 'state'" key="divKindNonUs">
				<label>What part of a state Constitution are you citing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What part of a state Constitution are you citing?">
					<option value="article">
						Article of a state constitution
					</option>
					<option value="amendment">
						Amendment to a state constitution
					</option>
					<option value="repealed">
						Repealed Amendment to a state constitutioin
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-input-reference-wizard
				v-show="kind == 'article'"
				label="Article Number(s)"
				help-text="Type the number of the article(s) using Roman numerals."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="article" />

			<form-input-reference-wizard
				label="Section Number(s)"
				help-text="Enter the section number(s) your are referencing in Arabic numerals (2-4)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="section" />

			<form-input-reference-wizard
				v-show="kind == 'amendment' || kind == 'repealed'"
				label="Amendment Number(s)"
				help-text="Type the number of the amendment(s) in Roman numerals."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="amendment" />

			<form-input-reference-wizard
				v-show="kind == 'repealed'"
				label="Year of the Amendment's repeal"
				help-text="Enter the year the Amendment was repealed."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="repealDate" />

			<form-input-reference-wizard 
				v-show="type == 'state'"
				label="State's Abbreviation"
				help-text="Type the legal abbreviation for the state (e.g., Tenn.)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="state" />

			<form-textarea-reference-wizard
				v-show="type == 'charter'"
				label="Charter Title"
				help-text="Type the title of the organization's charter. You can use appropriate abbreviations."
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />
			
			<form-input-reference-wizard 
				v-show="type == 'charter'"
				label="Article Number(s)"
				help-text="Enter the article number(s) you are referencing in Arabic numerals."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="charterArticle" />

			<form-input-reference-wizard 
				v-show="type == 'charter'"
				label="Paragraph Number(s)"
				help-text="Type the paragraph number(s) you are referencing in Arabic numerals. Omit if you are citing an entire article."
				@generate-reference-preview="generateReferencePreview"
				v-model="paragraph" />

		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeConstitutions',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			this.$nextTick(()=>{
				this.generateReferencePreview();
			});
		},//e:calcContributors{

		typeChange(){
			if(this.type === 'charter'){
				this.kind = '';
			} else {
				this.kind = 'article';
			}
			
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted(){
		if(this.kind === ''){
			this.kind = 'article';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
		if(this.type === ''){
			this.type = 'us';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>