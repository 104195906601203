var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Anthology",
                  value: "anthology",
                },
                domProps: { checked: _vm._q(_vm.type, "anthology") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "anthology"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Entry",
                  value: "entry",
                },
                domProps: { checked: _vm._q(_vm.type, "entry") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "entry"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "anthology",
              expression: "type === 'anthology'",
            },
          ],
          key: "divKindAnthology",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v(
              "What kind of book, e-book, or audiobook are you referencing?"
            ),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What kind of book, e-book, or audiobook are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.calcContributors()
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "whole" } }, [
                _vm._v("\n\t\t\t\t\tAll or part of an anthology\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "foreign" } }, [
                _vm._v(
                  "\n\t\t\t\t\tForeign language or translated anthology\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "republished" } }, [
                _vm._v("\n\t\t\t\t\tRepublished anthology\n\t\t\t\t"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type !== "anthology",
              expression: "type !== 'anthology'",
            },
          ],
          key: "divKindEntry",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [_vm._v("What kind of chapter are you referencing?")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label": "What kind of chapter are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.calcContributors()
                  },
                ],
              },
            },
            [
              _c("option", { attrs: { value: "entry" } }, [
                _vm._v("\n\t\t\t\t\tAn entry in an anthology\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "foreign" } }, [
                _vm._v(
                  "\n\t\t\t\t\tAn entry in a foreign language or translated anthology\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "republished" } }, [
                _vm._v(
                  "\n\t\t\t\t\tAn entry in a republished anthology\n\t\t\t\t"
                ),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "entry",
              expression: "type === 'entry'",
            },
          ],
          attrs: {
            label: "Entry Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.entryTitle,
            callback: function ($$v) {
              _vm.entryTitle = $$v
            },
            expression: "entryTitle",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTranslatorFields && _vm.type === "entry",
              expression: "showTranslatorFields && type === 'entry'",
            },
          ],
          attrs: {
            label: "Translated Entry Title",
            "help-text":
              "If the original book title is provide in another language, type the translated title.",
            "is-autosize": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.translatedEntryTitle,
            callback: function ($$v) {
              _vm.translatedEntryTitle = $$v
            },
            expression: "translatedEntryTitle",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Anthology Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.anthologyTitle,
            callback: function ($$v) {
              _vm.anthologyTitle = $$v
            },
            expression: "anthologyTitle",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTranslatorFields,
              expression: "showTranslatorFields",
            },
          ],
          attrs: {
            label: "Translated Anthology Title",
            "help-text":
              "If the original book title is provide in another language, type the translated title.",
            "is-autosize": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.translatedAnthologyTitle,
            callback: function ($$v) {
              _vm.translatedAnthologyTitle = $$v
            },
            expression: "translatedAnthologyTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "entry",
              expression: "type === 'entry'",
            },
          ],
          attrs: {
            label: "Entry Page Numbers",
            "help-text":
              "Give the page numbers that include the entry (ex. 45-78). Discontinuous pages are separated with comma and space. Ex. 45-78, 93.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.pageNumbers,
            callback: function ($$v) {
              _vm.pageNumbers = $$v
            },
            expression: "pageNumbers",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Publication Year",
            "help-text": "List the year the work was published.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Edition",
            "help-text":
              "For numbered editions, type the ordinal number (1st, 2nd, etc.). For named editions, type the name (e.g., 2nd ed., rev.).",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.edition,
            callback: function ($$v) {
              _vm.edition = $$v
            },
            expression: "edition",
          },
        }),
        _vm._v(" "),
        _c("control-publishers-v2", { attrs: { "field-type": "reference" } }),
        _vm._v(" "),
        _c("control-toggle-select", {
          attrs: {
            label: _vm.toggleSelect.doiUrl.label,
            "is-common-use": _vm.toggleSelect.doiUrl.isCommonUse,
            options: _vm.toggleSelect.doiUrl.options,
            "group-name": "doiUrl",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.kind === "republished" ||
              _vm.kind == "foreign" ||
              _vm.kind == "entry",
            expression:
              "kind === 'republished' || kind == 'foreign' || kind == 'entry'",
          },
        ],
        staticClass: "mb-5",
      },
      [
        _c("h3", [_vm._v("\n\t\t\tOriginal Publication Information\n\t\t")]),
        _vm._v(" "),
        _vm.type === "entry"
          ? _c("form-input-reference-wizard", {
              attrs: {
                label: "Entry's Original Publication Year",
                "help-text":
                  "List the year the entry in the anthology was originally published, if provided.",
                "is-common-use": true,
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
              model: {
                value: _vm.originalPublicationDate,
                callback: function ($$v) {
                  _vm.originalPublicationDate = $$v
                },
                expression: "originalPublicationDate",
              },
            })
          : _c("form-input-reference-wizard", {
              attrs: {
                label: "Original Publication Year",
                "help-text": "List the year the original work was published.",
                "is-common-use": true,
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
              model: {
                value: _vm.originalPublicationDate,
                callback: function ($$v) {
                  _vm.originalPublicationDate = $$v
                },
                expression: "originalPublicationDate",
              },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Radio_Anthology" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tAn anthology\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "Use this reference type if you are citing an entire anthology.  There is no distinction between print and digital versions.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Radio_Entry" } },
      [
        _vm._v("\n\t\t\t\t\t\t\tAn entry in an anthology\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "Use this type if you are only citing a single entry or chapter in an anthology.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }