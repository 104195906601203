<!--
- Advertisement
-- Reference Type Id: 5
-- 2024.06.20
-->

<template>
	<div>
		<div v-if="
				(
					$platformId === $enums.Platform.ADD_IN &&
					(
						referenceFormatTypeId === $enums.Format.MLA9 ||
						referenceFormatTypeId === $enums.Format.Turabian9
					)
				) || 
				(
					$platformId === $enums.Platform.ONLINE &&
					$route.meta.module === $enums.Module.REFERENCE_LIST || 
					($route.meta.module === $enums.Module.PAPER_EDIT && referenceFormatTypeId === $enums.Format.MLA9) ||
					($route.meta.module === $enums.Module.PAPER_EDIT && referenceFormatTypeId === $enums.Format.Turabian9)
				)
			" key="divReferenceList">
						
			<div class="alert alert-danger" v-if="$route.meta.module === $enums.Module.REFERENCE_LIST" key="divAlertReferenceList">
				<p><strong>APA & MLA Users</strong></p><br />
				<p>APA 7 does not include a specific format for advertisements. Instead, advertisements are formatted according to their parent source (e.g., use Magazine for an ad in a magazine).</p><br />
				<p>In MLA 9, advertisements should be formatted as part of their parent medium whenever possible (e.g., use Magazine for an ad in a magazine). This format is ONLY for advertisements that stand on their own.</p>
			</div><!--alert-->
			
			<div class="alert alert-warning" v-else-if="$route.meta.module === $enums.Module.PAPER_EDIT && referenceFormatTypeId === $enums.Format.MLA9" key="divAlertPaperEditMLA9">
				<p>In MLA 9, advertisements should be formatted as part of their parent medium whenever possible (e.g., use Magazine for an ad in a magazine). This format is ONLY for advertisements that stand on their own.</p>
			</div><!--alert-->


			<section class="mb-5">
				<h3>Format Options</h3>

				<!-- Type -->
				<div class="form-group mb-0 py-2">
					<label>Choose a type</label>
					<div class="row">
						<div class="col">
							<div class="form-check">
								<input
									class="form-check-input"
									type="radio"
									id="RefWiz_Type_Physical"
									value="physical"
									v-model="type" /> 
								<label class="form-check-label" for="RefWiz_Type_Physical">
									A physical ad (e.g., a billboard, poster)
								</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<div class="form-check">
								<input
									class="form-check-input"
									type="radio"
									id="RefWiz_Type_Digital"
									value="digital"
									v-model="type" /> 
								<label class="form-check-label" for="RefWiz_Type_Digital">
									A digital ad (e.g., a website pop-up)
								</label>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="mb-5">
				<h3>Publication Information</h3>

				<form-input-reference-wizard
					label="Ad Sponsor"
					help-text="Enter the company, event, or organization that the advertisement is promoting."
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="sponsor" />

				<form-input-reference-wizard
					label="Main Ad Text"
					help-text="If the ad includes a main heading, type the main content exactly as it appears in the work."
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="title" />

				<form-datepicker
					id="RefWiz_PublicationDate" 
					label="Publication Date"
					:is-common-use="true"
					:paper-format="0"
					@date-set="dateSetPublication"
					@date-clear="dateClearPublication" />

				<form-input-reference-wizard
					v-if="type === 'physical'"
					label="Location"
					help-text="Enter the location where the advertisement was displayed. Include the exact location, city, and state."
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="location" />

				<form-input-reference-wizard
					label="Format"
					:help-text="calcHelpTextFormat"
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="format" />

				<form-input-reference-wizard
					v-if="type === 'digital'"
					label="URL"
					help-text="Type or paste the entire URL if applicable."
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="url" />
			</section>
	

		</div><!--divReferenceList-->

		<div v-else-if="
			$route.meta.module === $enums.Module.PAPER_EDIT &&
			referenceFormatTypeId === $enums.Format.APA7
		" key="divPaperEdit">
		
			<div class="alert alert-warning">
				<p>APA 7 does not include a specific format for advertisements. Instead, advertisements are formatted according to their parent source (e.g., use Magazine for an ad in a magazine).</p>
			</div>

			<section class="mb-4">
				<h3>Choose a common advertisement format...</h3>
				<reference-type-select-row
					v-for="referenceType in advertisementsTypes"
					:key="referenceType.id"
					:reference-type-description="referenceType.desc"
					:reference-type-formats="referenceType.formats"
					:reference-type-id="referenceType.id"
					:reference-type-slug="referenceType.slug"
					:reference-type-title="referenceType.title" />
			</section>
		</div><!--divPaperEdit-->

	</div>
</template>

<script>
import _find from 'lodash/find';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';
import ReferenceTypeSelectRow from '@/modules/CreateReferenceStep1/ReferenceTypeSelectRow';
import ReferenceTypes from '@/services/reference/types';

export default {
	name: 'RefTypeAdvertisement',
	mixins: [mixinReferenceTypeGenesis],
	computed:{
		advertisementsTypes(){
			let getReferenceTypeIds = [
				3,	// Website
				26,	// Magazine
				29, // Newspaper
				43,	// Television
				1,	// Journal
				34,	// PodcastsRadio
			];

			let returnTypes = [];

			// loop through each defined id, adding the matching reference type
			getReferenceTypeIds.forEach((typeId) =>{
				let foundRefType = _find(ReferenceTypes.refTypes, {'id':typeId});
				if(foundRefType) {
					returnTypes.push(foundRefType);
				}
			});
			return returnTypes;
		},
		calcHelpTextFormat(){
			if(this.type === 'physical'){
				return 'Type the format for the advertisement (e.g., poster, billboard, flyer, etc.).';	
			} else if(this.type === 'digital'){
				return 'Type the format for the advertisement (e.g., pop-up, banner, etc.)';	
			}
			return '';
		}
	},
	methods:{
		calcContributors(){
			// this function left intentionally blank
		},
	},
	mounted(){
		if(this.type === ''){
			this.type = 'physical';
		}
	},
	components: {
		ReferenceTypeSelectRow
	}
}
</script>
