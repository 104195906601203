var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-3" },
    [
      _c("div", { staticClass: "form-group mb-1" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c("div", { staticClass: "w-100 mb-2" }, [
            _vm._v("\n\t\t\t\t\t \n\t\t\t\t"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "icon-row flex-shrink-1" }, [
            _c("div", { staticClass: "d-flex flex-row-reverse" }, [
              _vm.citationIndex !=
              _vm.$store.state.referenceRules.formData.citation.length - 1
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: "#",
                        title: "Move Parallel Citation Down",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.sortCitationBlock(false)
                        },
                      },
                    },
                    [_c("span", { staticClass: "svg svg-sort bi-arrow-down" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.citationIndex !== 0
                ? _c(
                    "a",
                    {
                      attrs: { href: "#", title: "Move Parallel Citation Up" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.sortCitationBlock(true)
                        },
                      },
                    },
                    [_c("span", { staticClass: "svg svg-sort bi-arrow-up" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#", title: "Remove Parallel Citation" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.removeCitationBlock.apply(null, arguments)
                    },
                  },
                },
                [_c("span", { staticClass: "svg svg-delete bi-x-lg" })]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("citation-input", {
        attrs: { label: "Volume", "is-focus-on-add": true },
        model: {
          value: _vm.citation_volume,
          callback: function ($$v) {
            _vm.citation_volume = $$v
          },
          expression: "citation_volume",
        },
      }),
      _vm._v(" "),
      _c("citation-input", {
        attrs: { label: "Series" },
        model: {
          value: _vm.citation_series,
          callback: function ($$v) {
            _vm.citation_series = $$v
          },
          expression: "citation_series",
        },
      }),
      _vm._v(" "),
      _c("citation-input", {
        attrs: { label: "Page" },
        model: {
          value: _vm.citation_pageNumber,
          callback: function ($$v) {
            _vm.citation_pageNumber = $$v
          },
          expression: "citation_pageNumber",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }